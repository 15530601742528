import React from 'react'
import { Content } from './components/Content'
import './styles.css'
import { useIntegration } from '../Integrations/context/integrationContext'

export const EmailMktIntegration = ({ self }) => {
  const { integrationToEdit } = useIntegration()
  const { position } = integrationToEdit ?? {}
  const modalTexts = self.options.locale.modalIntegration
  const { close, insertIntegration } = self.options.locale

  return (
    <Content
      self={self}
      integrationPosition={position}
      modalTexts={{
        ...modalTexts,
        close,
        insertIntegration,
      }}
    />
  )
}
