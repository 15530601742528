import CSS_CLASS from '../constants/cssClass';
import ACTION_TYPE from '../constants/actionType';
import closeSidebar from '../sidebar/closeSidebar';
import showSnippetSidebarLeft from '../sidebarLeft/showSnippetSidebarLeft';
import { removeShowedElements, clearBtnFocus } from '../../keditor/helpers/helpers';
import { IN_FOCUS } from '../../constants';

export default function () {
  let self = this;
  let contentAreasWrapper = self.contentAreasWrapper;

  contentAreasWrapper.on('click', `.${CSS_CLASS.MF_ADD_CONTENT}`, function (e) {
    e.preventDefault();

    let btn = $(this);
    let container = btn.closest(`.${CSS_CLASS.CONTAINER}`);
    let isNested = container.closest('[data-type="container-content"]').length > 0;

    let containerContent = container.find(`.${CSS_CLASS.CONTAINER_CONTENT}`);
    let containerContentInner = btn.closest(`.${CSS_CLASS.COMPONENT}`);
    setTimeout(() => {
      closeSidebar.call(self);
    }, 1);
    setTimeout(() => {
      showSnippetSidebarLeft.call(self, container, ACTION_TYPE.AFTER, true, !isNested, containerContent);
    }, 200);

    removeShowedElements(contentAreasWrapper)
    clearBtnFocus(contentAreasWrapper)
    btn.addClass(IN_FOCUS);
  });
}
