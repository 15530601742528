import renderSnippetFilter from './renderSnippetFilter'
import CSS_CLASS from '../constants/cssClass'
//import log from '../utils/log';

export default function () {
  let self = this
  let options = self.options
  let sidebarLeft = self.sidebarLeft
  //let [categoriesOptions, filterWrapper] = renderSnippetFilter.call(self);

  let categoriesOptionsArray = renderSnippetFilter.call(self)
  let filterWrappers = sidebarLeft.find(`.${CSS_CLASS.SNIPPETS_FILTER_WRAPPER}`)

  let txtSearchArr = []
  let cbbFilterArr = []

  filterWrappers.each(function (index) {
    $(this).html(/*html*/ `
    <div class="d-flex align-items-center mb-3">
        <span 
            class="${CSS_CLASS.UI} ${CSS_CLASS.SNIPPETS_FILTER_LABEL} pr-2"
        >${options.locale.snippetCategoryLabel}:</span>
        <select class="${CSS_CLASS.SNIPPETS_FILTER} form-control">
            ${categoriesOptionsArray[index]}
        </select>
    </div>
    <input 
        type="text" 
        class="${CSS_CLASS.UI} ${CSS_CLASS.SNIPPETS_SEARCH}" 
        value="" 
        placeholder="${options.locale.snippetCategorySearch}"
    />
    `)

    txtSearchArr.push($(this).find(`.${CSS_CLASS.SNIPPETS_SEARCH}`))
    cbbFilterArr.push($(this).find(`.${CSS_CLASS.SNIPPETS_FILTER}`))
  })

  let doFilter = function (fornav) {
    let selectedCategory = (cbbFilterArr[fornav].val() || '').toLowerCase()
    //log('selectedCategory', selectedCategory);
    let searchText = (txtSearchArr[fornav].val() || '').toLowerCase()
    let curSnippets = sidebarLeft.find(`#${options.sidebarLeft.nav.items[fornav].id} .${CSS_CLASS.SNIPPET}`)

    curSnippets.filter(`.${CSS_CLASS.STATE_SELECTED}`).removeClass(CSS_CLASS.STATE_SELECTED)

    if (selectedCategory || searchText) {
      curSnippets.each(function () {
        let snippet = $(this)
        let dataCategoriesString = snippet.attr('data-keditor-categories').toLowerCase()
        //let dataCategories = dataCategoriesString.split(options.snippetsCategoriesSeparator);
        let error = 0

        if (selectedCategory) {
          // if ($.inArray(selectedCategory, dataCategoriesString) === -1) {
          //     error++;
          // }
          if (selectedCategory != dataCategoriesString) {
            error++
          }
        }

        if (searchText) {
          let title = snippet.attr('data-keditor-title').toLowerCase()
          if (title.indexOf(searchText) === -1 && dataCategoriesString.indexOf(searchText) === -1) {
            error++
          }
        }

        snippet[error === 0 ? 'removeClass' : 'addClass'](CSS_CLASS.STATE_NOT_MATCHED)
      })
    } else {
      curSnippets.removeClass(CSS_CLASS.STATE_NOT_MATCHED)
    }
  }

  cbbFilterArr.forEach(function (element, index) {
    $(element).on('change', function () {
      doFilter(index)
    })
  })

  let timerArr = []
  txtSearchArr.forEach(function (element, index) {
    $(element).on('keydown', function () {
      clearTimeout(timerArr[index])
      timerArr[index] = setTimeout(function () {
        doFilter(index)
      }, 230)
    })
  })
}
