export const MODAL_EXTERNAL_URL = /*html*/ `
<div class="keditor-modal fade" tabindex="-1" role="dialog" id="modal-img-url">
  <div class="modal-dialog small">
    <div class="modal-content small">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Insira a URL da imagem</h5>
        <button type="button" id="close-modal" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body py-5">
        <label>URL da imagem</label>
        <input 
          type="text" 
          class="form-control" 
          id="image-url"
          placeholder="http://www.google.com/images..."
          value=""
        />
      </div>
      <div class="modal-footer">
        <div className="buttons">
          <button
            type="button"
            id="btn-modal-url"
            class="btn cancel mr-3"
            data-dismiss="modal" 
            aria-label="Close"
          >
            Cancelar
          </button>
          <button
            type="button"
            id="btn-add-url"
            class="btn save"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`
