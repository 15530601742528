import ColorPicker from '../../components/helpers/colorPicker.js'
// import CSS_CLASS from '../constants/cssClass.js'
import useGlobalStyle from './globalStyleModal/methods.js'
import { TEMPLATE_BG_COLOR, TEMPLATE_BG_IMAGE } from './globalStyleModal/template/index.js'
import { navBar } from './globalStyleModal/template/navBar.js'
import { ModalBase } from './template/modalBase.js'
const colorPicker = new ColorPicker()
export default function () {
  let self = this
  const iframeBody = self.iframeBody

  let modalFooter = /*html*/ `
    <button type="button" class="btn save" id="global-style-save">salvar Estilo</button>
  `
  const tabs = [
    {
      id: 'page-background-color',
      tabName: 'Sólido',
      tabContent: TEMPLATE_BG_COLOR,
      isActive: true,
    },
    {
      id: 'page-background-image',
      tabName: 'Imagem',
      tabContent: TEMPLATE_BG_IMAGE,
      isActive: false,
    },
  ]

  let modal = $(
    ModalBase({
      id: 'modal-global-css',
      title: self.options.locale.globalSettings.title,
      footer: modalFooter,
      tabs: navBar({ customTabs: tabs }),
      info: 'Texto de informação',
    })
  )

  const config = useGlobalStyle({ form: modal, keditor: self })

  config.edit()
  config.load()
  config.save()

  modal.find('input, select').on('change', function () {
    $('.unsaved').show()
  })

  colorPicker.init(
    modal,
    (color) => {
      iframeBody.css('backgroundColor', color)
    },
    '#global-color'
  )

  let result = modal.appendTo(self.wrapper)

  return result
}
