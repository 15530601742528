import CSS_CLASS from "../constants/cssClass";
import getComponentType from "../component/getComponentType";
import SETTING_CATEGORY from "../constants/settingCategory";
import showSettingForm from "./showSettingForm";
import globalSettings from './globalSettingForm';

export default function (target) {
	//log('openSidebar', target);
	let self = this;
	let options = self.options;

	function globalInit(callback) {
		return function(form, keditor) {
			let self = this;
			
			globalSettings.initGlobal.call(self, form, keditor)
			typeof callback === "function" && callback.call(self, form, keditor);
		}
	}

	function globalShow(callback) {
		return function(form, component, keditor) {
			let self = this;

			globalSettings.showGlobal.call(self, form, component, keditor)
			typeof callback === "function" && callback.call(self, form, component, keditor);
		}
	}

	if (target.is(`.${CSS_CLASS.COMPONENT}`)) {
		let componentType = getComponentType.call(self, target);
		let componentData = KEditor.components[componentType];
		showSettingForm.call(
			self,
			target,
			componentType,
			SETTING_CATEGORY.COMPONENT,
			componentData.settingTitle,
			globalInit(componentData.initSettingForm),
			globalShow(componentData.showSettingForm),
			componentData
		);
	} else if (target.is(`.${CSS_CLASS.CONTAINER}`)) {
		showSettingForm.call(
			self,
			target,
			null,
			SETTING_CATEGORY.CONTAINER,
			options.locale.containerSetting,
			globalInit(options.containerSettingInitFunction),
			globalShow(options.containerSettingShowFunction),
			self
		);
	} else {
		let extraKey = target.attr("data-extra-setting");
		let extraSetting = options.extraSettings[extraKey];
		showSettingForm.call(
			self,
			target,
			extraKey,
			SETTING_CATEGORY.EXTRA,
			extraSetting.title,
			globalInit(extraSetting.settingInitFunction),
			globalShow(extraSetting.settingShowFunction),
			extraSetting
		);
	}
}
