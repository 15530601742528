import React, { useMemo } from 'react'
import { INTEGRATIONS_TYPE } from '../../../../constants'
import { useGetActionWithResource } from '../../hooks'
import { getMytIntegrations } from '../../../../services/integrations'
import { Platforms } from '../Plataforms'
import { Actions } from '../Actions/Actions'
import { ResourcesSearch } from '../ResourcesSearch/ResourcesSearch'
import { useIntegration } from '../../../Integrations/context/integrationContext'
import { INTEGRATIONS_PANEL_VIEW } from '../../../Integrations/constants'
import { removeIntegrationUndefined, useAsync } from '../../../utils'

export const Content = ({ self, modalTexts, integrationPosition }) => {
  const isInitialRender = React.useRef(true)
  const initializeValues = React.useRef(true)
  const emailIntegration = self?.options?.integrations?.data?.find(
    (integration) => integration.type === INTEGRATIONS_TYPE.EMAIL
  )
  const [formState, setFormState] = React.useState({
    platform: {
      selectValue: '',
      data: {},
    },
    actionSelect: '',
  })
  const {
    resourceList,
    status: resourceListStatus,
    setResourceList,
  } = useGetActionWithResource(
    formState.platform.selectValue,
    formState.actionSelect
  )
  const { setPanelView, addIntegration, editIntegration } = useIntegration()
  const isEditMode =
    integrationPosition !== undefined && integrationPosition !== null
  const {
    data: platformsResponse,
    run,
    status: platformStatus,
  } = useAsync({ status: 'pending' })
  const platforms = platformsResponse && platformsResponse.data.data
  const resourceListWithSelectedOptions = useMemo(() => {
    const { resources } = emailIntegration ?? {}

    if (resources && resourceList.length && initializeValues.current) {
      let newResourceList = [...resourceList]
      initializeValues.current = false

      newResourceList = newResourceList.map((current) => {
        const element = { ...current }
        const findResourceIndex = resources.findIndex(
          (resource) => resource.name === element.name
        )

        if (findResourceIndex !== -1) {
          element.optionSelected = resources[findResourceIndex].value
          element.resource_name =
            resources[findResourceIndex].resource_name || null
        }

        return element
      })
      return newResourceList
    }
    return resourceList
  }, [resourceList])

  const handleCancel = () => {
    setPanelView(INTEGRATIONS_PANEL_VIEW.LIST)
  }

  const handleChangePlatform = (service, platform) => {
    setFormState({
      ...formState,
      platform: {
        selectValue: service,
        data: platform,
      },
      actionSelect: '',
    })
  }

  const handleChangeAction = (value) => {
    setFormState({
      ...formState,
      actionSelect: value,
    })
  }

  const handleSetResourceOptionSelected = (index, { value, name }) => {
    const resourceListCopy = [...resourceList]
    resourceListCopy[index].optionSelected = value
    resourceListCopy[index].resource_name = name
    setResourceList(resourceListCopy)
  }

  const validationForm = () => {
    const values = Object.values(formState).reduce((acc, current) => {
      if (typeof current === 'object') {
        Object.values(current).filter(
          (el) => typeof el !== 'object' && acc.push(el)
        )
        return acc
      }
      acc.push(current)
      return acc
    }, [])
    const validationFormState = !Object.values(values).some(
      (x) => x === null || x === ''
    )

    const resourceValues = resourceList.map((resource) => {
      return resource.optionSelected
    })
    const validationResourceValues = !Object.values(resourceValues).some(
      (x) => x === null || x === undefined || x === ''
    )

    return validationFormState && validationResourceValues
  }

  const handleSave = () => {
    if (validationForm()) {
      const action = isEditMode ? editIntegration : addIntegration
      const parseResource = resourceList.map((resource) => {
        return {
          name: resource.name,
          value: resource.optionSelected,
          resource_name: resource.resource_name,
        }
      })
      const integrationEmailMkt = {
        type: 'email_marketing',
        action: formState.actionSelect,
        service: formState.platform.selectValue,
        resources: parseResource,
      }

      action({
        integrationPosition,
        integration: integrationEmailMkt,
      })

      removeIntegrationUndefined(self)
      setPanelView(INTEGRATIONS_PANEL_VIEW.LIST)
    } else {
      const message = `<span><b>Ops!</b> Algum campo não foi preenchido. Por favor, verificar e tentar novamente</span>`
      $('.save-content')
        .fadeIn(500)
        .removeClass('alert-success')
        .addClass('alert-danger')
        .html(message)
        .fadeOut(10000)
    }
  }

  const filterDependences = React.useCallback((current, resources) => {
    if (!current.requires.length) return null
    const listOfDependencies = []

    resources.forEach((resource) => {
      if (current.requires.includes(resource.name)) {
        listOfDependencies.push({
          name: resource.name,
          value: resource.optionSelected,
        })
      }
    })

    return listOfDependencies
  }, [])

  const initializeServiceAndAction = () => {
    const { service, action } = emailIntegration ?? {}
    const state = {}

    if (platforms && service) {
      const platformSelected =
        platforms.filter((item) => item.service === service)[0] || null
      state.platform = {
        selectValue: service,
        data: platformSelected,
      }

      if (action) {
        state.actionSelect = action
      }

      setFormState((prevState) => ({
        ...prevState,
        ...state,
      }))
    }
  }

  React.useEffect(() => {
    run(getMytIntegrations())
  }, [])

  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    initializeServiceAndAction()
  }, [platforms])

  return (
    <React.Fragment>
      <div className="modal-body modal-body-fixed-height">
        {platformStatus === 'pending' && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              backgroundColor: '#363636',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <form id="formRef">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label
                    htmlFor="mkt-service"
                    className="col-form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {modalTexts.service.label}{' '}
                  </label>
                  {/* <select id="mkt-service" className="form-control"></select> */}
                  <Platforms
                    platforms={platforms}
                    handleChange={handleChangePlatform}
                    itemSelected={formState.platform.selectValue}
                  />
                  <div className="m-0 pb-2">
                    <span className="text-muted" style={{ fontSize: '12px' }}>
                      {' '}
                      {modalTexts.service.helper}{' '}
                      <a
                        href={`${process.env.MAKEFUNNELS_APP_URL}/integration`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {modalTexts.service.cta}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label
                    htmlFor="mkt-action"
                    className="col-form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {modalTexts.action.label}{' '}
                  </label>
                  {/* <select id="mkt-action" className="form-control"></select> */}
                  <Actions
                    disabled={!formState.platform.selectValue}
                    platform={formState.platform.data}
                    handleChange={handleChangeAction}
                    itemSelected={formState.actionSelect}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="form-group d-flex row row-cols-2 mb-0">
              {resourceListStatus === 'pending' && 'Carregando...'}
              {resourceListStatus === 'resolved' &&
                resourceListWithSelectedOptions.map(
                  (resourceContent, index) => {
                    return (
                      <div key={`key_${index}`} className="col">
                        <div>
                          <label
                            className="col-form-label"
                            style={{ fontWeight: 'bold' }}
                          >
                            {' '}
                            {resourceContent.label}{' '}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <ResourcesSearch
                          initialSelectedValue={{
                            value: resourceContent.optionSelected,
                            resource_name: resourceContent.resource_name,
                          }}
                          dependences={filterDependences(
                            resourceContent,
                            resourceListWithSelectedOptions
                          )}
                          setOptionSelected={({ value, name }) =>
                            handleSetResourceOptionSelected(index, {
                              value,
                              name,
                            })
                          }
                          service={formState.platform.selectValue}
                          resourceContent={resourceContent}
                        />
                      </div>
                    )
                  }
                )}
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer">
        <div className="buttons">
          <button type="button" className="btn cancel" onClick={handleCancel}>
            Cancelar
          </button>
          <button
            type="button"
            className="btn save"
            id="save-button"
            onClick={handleSave}
          >
            Salvar
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
