import TOOLBAR_TYPE from '../constants/toolbarType'
import CSS_CLASS from '../constants/cssClass'
import ICON from '../constants/icon'

export default function (type, isConfigurable, isUnique) {
  let self = this
  let options = self.options

  switch (type) {
    case TOOLBAR_TYPE.CONTAINER:
    case TOOLBAR_TYPE.SUB_CONTAINER:
      const bottomToolbar = /*html*/ `
      <div 
        class="${CSS_CLASS.UI} ${CSS_CLASS.TOOLBAR_CONTAINER_CONTENT} ${
        type === TOOLBAR_TYPE.SUB_CONTAINER_CONTENT ? CSS_CLASS.TOOLBAR_SUB_CONTAINER_CONTENT : ''
      }">
      <a 
        href="javascript:void(0)" 
        class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} ${CSS_CLASS.ADD_CONTENT} mf-add-content" 
        title="${options.locale.addContent}">${ICON.ADD_CONTENT}</a>
      <a 
        href="javascript:void(0)" 
        class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} mf-copy-content" 
        title="${options.locale.pasteContent}">${ICON.PASTE_CONTENT}</a>
      </div>`

      return /*html*/ `<div 
        class="
        ${CSS_CLASS.UI} ${CSS_CLASS.TOOLBAR} 
        ${CSS_CLASS.TOOLBAR_CONTAINER} 
        ${type === TOOLBAR_TYPE.SUB_CONTAINER ? CSS_CLASS.TOOLBAR_SUB_CONTAINER : ''}"
      >        

        <a
          href="javascript:void(0);"
          class="${CSS_CLASS.UI} ${CSS_CLASS.CONTAINER_COPY}" 
          title="${options.locale.copy}"
        >${ICON.COPY_CONTAINER}</a>
        <a 
          href="javascript:void(0);" 
          class="${CSS_CLASS.UI} ${CSS_CLASS.CONTAINER_CANCEL}" 
          title="Cancelar Ação"
          hidden
        >${ICON.CANCEL_CONTAINER}</a>
        ${
          type === TOOLBAR_TYPE.SUB_CONTAINER
            ? ''
            : `<a 
                href="javascript:void(0);" 
                class="${CSS_CLASS.UI} ${CSS_CLASS.CONTAINER_MOVE}" 
                title="${options.locale.move}"
              >${ICON.MOVE_CONTAINER}</a>`
        }
        <a
          href="javascript:void(0);"
          class="${CSS_CLASS.UI} ${CSS_CLASS.CONTAINER_DELETE}" 
          title="${options.locale.delete}"
        >${ICON.DELETE_CONTAINER}</a>
      </div>
      ${bottomToolbar}
      `

    case TOOLBAR_TYPE.COMPONENT:
      const copyConfig = /*html*/ `<a 
			href="javascript:void(0);" 
			class="${CSS_CLASS.UI} ${CSS_CLASS.COMPONENT_COPY}" 
			title="${options.locale.copy}"
		>${ICON.COPY_COMPONENT}</a>
		<a 
			href="javascript:void(0);" 
			class="${CSS_CLASS.UI} ${CSS_CLASS.COMPONENT_CANCEL}" 
			title="Cancelar Ação"
			hidden
		>${ICON.CANCEL_COMPONENT}</a>`

      return /*html*/ `
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOOLBAR} ${CSS_CLASS.TOOLBAR_COMPONENT}" >
        <a 
          href="javascript:void(0);" 
          class="${CSS_CLASS.UI} ${CSS_CLASS.COMPONENT_DELETE}" 
          title="${options.locale.delete}"
        >${ICON.DELETE_COMPONENT}</a>
        ${!isUnique ? copyConfig : ''}
        <a 
          href="javascript:void(0);" 
          class="${CSS_CLASS.UI} ${CSS_CLASS.COMPONENT_MOVE}" 
          title="${options.locale.move}"
        >${ICON.MOVE_COMPONENT}</a>
      </div>
      `

    case TOOLBAR_TYPE.CONTENT_AREA:
      return /*html*/ `
		<div class="${CSS_CLASS.UI} ${CSS_CLASS.TOOLBAR_CONTENT_AREA}">
		  <a
		    href="javascript:void(0)"
		    class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} ${CSS_CLASS.ADD_CONTENT}"
		    title="${options.locale.addContent}"
		  >${ICON.ADD_CONTENT}</a>
		  <a
		    href="javascript:void(0)"
		    class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} ${CSS_CLASS.PASTE_CONTENT}"
		    title="${options.locale.pasteContent}"
		  >${ICON.PASTE_CONTENT}</a>
		</div>
		`

    case TOOLBAR_TYPE.CONTAINER_CONTENT:
    case TOOLBAR_TYPE.COMPONENT:
    case TOOLBAR_TYPE.SUB_CONTAINER_CONTENT:
      return /*html*/ `
		  <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOOLBAR_CONTAINER_CONTENT} ${
        type === TOOLBAR_TYPE.SUB_CONTAINER_CONTENT ? CSS_CLASS.TOOLBAR_SUB_CONTAINER_CONTENT : ''
      }">
		  <a
		    href="javascript:void(0)"
		    class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} ${CSS_CLASS.ADD_CONTENT}"
		    title="${options.locale.addContent}"
		  >${ICON.ADD_CONTENT}</a>
		  <a
		    href="javascript:void(0)"
		    class="${CSS_CLASS.UI} ${CSS_CLASS.BTN} ${CSS_CLASS.BTN_DEFAULT} ${CSS_CLASS.PASTE_CONTENT}"
		    title="${options.locale.pasteContent}"
		  >${ICON.PASTE_CONTENT}</a>
		  </div>
		`
    default:
    // Do nothing
  }
}
