import CSS_CLASS from "../constants/cssClass";
import closeSidebar from "../sidebar/closeSidebar";
import showSidebarLeft from "./showSidebarLeft";

export default function (
	target,
	actionType,
	showComponent,
	showContainer,
	targetComponent
) {
	let self = this;
	let sidebarLeft = self.sidebarLeft;

	closeSidebar.call(self);

	self.sidebarLeftTarget = target;
	self.sidebarLeftTargetAction = actionType;
	showComponent && sidebarLeft.addClass(CSS_CLASS.MODAL_COMPONENT);
	showContainer && sidebarLeft.addClass(CSS_CLASS.MODAL_CONTAINER);

	sidebarLeft.addClass(CSS_CLASS.STATE_OPENED);
	self.iframeBody.addClass(CSS_CLASS.STATE_SIDEBAR_SHOWED);
}
