import CSS_CLASS from "../constants/cssClass";
import SETTING_CATEGORY from "../constants/settingCategory";
import { clearBtnFocus } from '../../keditor/helpers/helpers';

export default function () {
  // log("hideSidebarLeft");

  let self = this;
  let options = self.options;
  let sidebarLeft = self.sidebarLeft;
  let contentAreasWrapper = self.contentAreasWrapper;
  let activeForm = self.sidebarLeftBody.children(`.${CSS_CLASS.STATE_ACTIVE}`);

  if (activeForm.length > 0) {
    switch (activeForm.attr("[data-setting-category]")) {
      case SETTING_CATEGORY.CONTAINER:
        if (typeof options.containerSettingHideFunction === "function") {
          options.containerSettingHideFunction.call(self, activeForm, self);
        }
        break;

      case SETTING_CATEGORY.COMPONENT:
        let activeType = activeForm.attr("data-type");
        let componentData = KEditor.components[activeType];

        if (typeof componentData.hideSettingForm === "function") {
          componentData.hideSettingForm.call(componentData, activeForm, self);
        }
        break;

      case SETTING_CATEGORY.EXTRA:
        // TODO: Will add method when hiding setting for Extra setting
        break;

      default:
      // Do nothing
    }

    activeForm.removeClass(CSS_CLASS.STATE_ACTIVE);
  }

  // if (!modal.hasClass(CSS_CLASS.STATE_SHOWED)) {
  //     modal.css('display', 'none');
  //     $(document.body).removeClass(CSS_CLASS.STATE_MODAL_OPENED);
  // }
  // modal.removeClass(CSS_CLASS.STATE_SHOWED);
  // self.settingComponent = null;
  // self.settingContainer = null;
  clearBtnFocus(contentAreasWrapper)
  sidebarLeft.removeClass(CSS_CLASS.STATE_OPENED);
  self.iframeBody.removeClass(CSS_CLASS.STATE_SIDEBAR_SHOWED);
}
