export const ELEMENT_ID = 'popup-editor'
export const MODAL_ID = 'makefunnels-1' // POPUP id code
export const EDITOR_MODAL_ID = 'mf-popup-editor'
export const POPUP_TYPE = {
    CATCH: 'catch',
    NOTICE: 'notice'
}
export const BUTTON_ACTION = {
    CLOSE_MODAL: 'close-modal',
    REDIRECT: 'redirect',
    SUBMIT: 'submit'
}

export const ELEMENTS = {
    TITLE: 'title',
    BODY: 'body',
    BUTTON_TEXT: 'button-text'
}