import FormComponent from './form/index.js'
import { MODAL_BODY, MODAL_FOOTER } from './form/templates/index.js'
import { ModalBase } from './template/modalBase.js'

const formComponent = new FormComponent()
export default function ({ page, integration, keditor, formData, onSave }) {
  let self = page

  const customForm = formData || []

  let modal = $(
    ModalBase({
      id: 'modal-form',
      title: 'Configure seu Formulário',
      footer: MODAL_FOOTER,
      body: MODAL_BODY(customForm),
    })
  )

  const result = modal.appendTo(self.wrapper)

  formComponent.data = customForm
  formComponent.edit()
  formComponent.show()

  $('.save-form-modal').off('click')
  $('.save-form-modal').on('click', function () {
    onSave(formComponent.object)
  })

  return result
}
