import * as React from 'react'
import { POPUP_TYPE } from '../shared/constants'

const PopupContext = React.createContext()
// // initiate for Popup Notice
// const initialStateNotice = {
//   bodyText: 'Algum texto aqui',
//   title: 'Algum título aqui', 
//   ctaText: 'Inscreva-se', 
//   ctaBackground: 'green'
// }
// // initiate for Popup Catch
// const initialStateCatch = {
//   bodyText: 'Algum texto aqui',
//   title: 'Algum título aqui', 
//   ctaText: 'Inscreva-se', 
//   ctaBackground: 'green',
// }

function PopupProvider({ children, initialState}) {
  const [state, setContent] = React.useState(initialState)
  const [type, setType] = React.useState(POPUP_TYPE.NOTICE)

  const value = {
    state, 
    type, 
    setContent,
    setType
  }
  
  return (
    <PopupContext.Provider value={value}>
        {children}
    </PopupContext.Provider>
  )
}

function usePopup() {
  const context = React.useContext(PopupContext)
  if (!context) {
    throw new Error('usePopup must be used within a PopupType')
  }
  return context
}

export {PopupProvider, usePopup}