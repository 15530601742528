import React from 'react'
import CSS_CLASS from '../../../../keditor/constants/cssClass'
import { usePopup } from '../../context/popupContext'
import { EDITOR_MODAL_ID } from '../../shared/constants'
import { Modal } from '../Modal'
import { Preview } from '../PreviewBase'
import './styles.css'
import { General, Styles, Texts } from '../Tabs'

export const PopupConfig = (props) => {
  const { state: { modalPopup, contentPopup, defaultsRef }, setContent } = usePopup()
  const previewRef = React.useRef(null)
  const modalRef = React.useRef(null)
  const { contentScripts } = props

  function handleConfirmation() {
    const modalHTML = previewRef.current.outerHTML

    defaultsRef.contentPopup = {
      ...contentPopup,
      html: modalHTML
    }
    defaultsRef.locale.modalPopup = modalPopup
    
    $(`#${EDITOR_MODAL_ID}`).modal('hide')
  }

  return (
    <Modal handleConfirmation={handleConfirmation} ref={modalRef}>
      <div className="row mr-0 ml-0 wrapper">
        <div className="col-4 p-0 left">
          <div className="tabs">
            <ul
              className={`${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS} ${CSS_CLASS.BOOTSTRAP_NAV_FILL}`}
              role="tablist"
            >
              <li className={`${CSS_CLASS.BOOTSTRAP_NAV_ITEM}`}>
                <a
                  className={`${CSS_CLASS.BOOTSTRAP_NAV_LINK} active`}
                  id="general-tab"
                  data-toggle="tab"
                  href="#general"
                  role="tab"
                  aria-controls="general-tab"
                  aria-selected="true"
                >
                  {modalPopup.tabs.general.tabName}
                </a>
              </li>
              <li className={`${CSS_CLASS.BOOTSTRAP_NAV_ITEM}`}>
                <a
                  className={`${CSS_CLASS.BOOTSTRAP_NAV_LINK}`}
                  id="texts-tab"
                  data-toggle="tab"
                  href="#texts"
                  role="tab"
                  aria-controls="texts-tab"
                  aria-selected="true"
                >
                  {modalPopup.tabs.texts.tabName}
                </a>
              </li>
              <li className={`${CSS_CLASS.BOOTSTRAP_NAV_ITEM}`}>
                <a
                  className={`${CSS_CLASS.BOOTSTRAP_NAV_LINK}`}
                  id="styles-tab"
                  data-toggle="tab"
                  href="#styles"
                  role="tab"
                  aria-controls="styles-tab"
                  aria-selected="true"
                >
                  {modalPopup.tabs.styles.tabName}
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="general"
              role="tabpanel"
              aria-labelledby="general-tab-content"
            >
              <General />
            </div>
            <div
              className="tab-pane fade"
              id="texts"
              role="tabpanel"
              aria-labelledby="texts-tab-content"
            >
              <div className="mt-3">
                <Texts />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="styles"
              role="tabpanel"
              aria-labelledby="styles-tab-content"
            >
              <div className="mt-3">
                <Styles />
              </div>
            </div>
          </div>
        </div>
        <div className="col p-0 right">
          {/* //TODO: Replace by locale key */}
          <div className="py-2 pl-2 text-center position-absolute w-100 preview_title">
            <p className="h6">Preview</p>
          </div>
          <Preview ref={previewRef} />
        </div>
      </div>
    </Modal>
  )
}
