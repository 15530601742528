import React from 'react'
import { useIntegration } from '../../context/integrationContext'

export const RedirectURL = () => {
  const { redirectURL, setRedirectURL } = useIntegration()
  const [inputValue, setInputValue] = React.useState(redirectURL)
  const isInitialRender = React.useRef(true)

  const handleChangeRedirectURL = (e) => {
    setInputValue(e?.target?.value)
  }

  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      setRedirectURL(inputValue)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [inputValue, 500])

  return (
    <div className="mb-4">
      <div>
        <label
          htmlFor="mkt-redirectURL"
          className="col-form-label pb-0"
          style={{ fontWeight: 'bold' }}
        >
          {' '}
          Para onde redirecionar após o envio do formulário?
        </label>
      </div>
      <div className="mb-2">
        <span className="text-left text-muted ">
          Para incluir uma nova integração, é necessário primeiro definir o link
          de redirecionamento.
        </span>
      </div>
      <input
        id="mkt-redirectURL"
        type="text"
        className="form-control"
        placeholder="URL Destino"
        value={inputValue}
        onChange={handleChangeRedirectURL}
      />
    </div>
  )
}
