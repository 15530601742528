const openGalleryButton = (hidelabel = false, smallheight = false, datastate = {}, initWithImage = '') => { 
  
  const btnTpl = $(`<div>
    <div class="galeryBtnWrap col-sm-12 mt-2">
      <div 
        class="upload-button"
        id="photo-galery"
        data-toggle="modal" 
        data-target="#modal-image-gallery"
        data-type="photo"
        data-state='${JSON.stringify(datastate)}'
      >
        <i class="fa fa-picture-o mr-2"></i> Escolher imagem
      </div>
      <div 
        class="change-image-button"
        id="photo-galery-2"
        data-toggle="modal" 
        data-target="#modal-image-gallery"
        data-type="photo"
        data-state='${JSON.stringify(datastate)}'
      > 
        <div class="info">
          <i class="fa fa-picture-o mr-2"></i> Substituir imagem
        </div>
      </div>
    </div>
  </div>
  `)
  
  const upbtnRef =  btnTpl.find('.upload-button')
  const chngbtnRef =  btnTpl.find('.change-image-button')

  if (!hidelabel) {
     btnTpl.find('.galeryBtnWrap').prepend("<label>Fazer upload de imagem</label>" )
  }

  if (smallheight) {
    upbtnRef.css('height', '60px')
    chngbtnRef.css('height', '60px')
    chngbtnRef.children(":first").css('padding', '5px 0px')
  }

  if (initWithImage !== '') {
    upbtnRef.css('display', 'none')
    chngbtnRef.css({ backgroundImage: `url(${initWithImage})` })
  }else{
    chngbtnRef.css('display', 'none')
    chngbtnRef.css('background-image', 'none')
  }

  return  btnTpl.html()

}

export default openGalleryButton
