export default /*html*/ `
<div
  style="
    padding: 25px 10px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -1px;
    border-color: rgb(243, 246, 244);
    background-color: white;
    border-style: solid;
    border-width: 1px;
  "
  id="makefunnels-162851888440650329815"
  data-margin_default="-1"
  data-margin_mobile="-1"
  data-spacing-block="true"
>
  <div class="custom-container d-flex flex-wrap">
    <div class="ui-resizable col-md-2" data-type="container-content" id="makefunnels-16286857868789342400137" style="">
      <div id="makefunnels-16285188930584441515535" data-type="component-photo">
        <div>
          <div
            class="photo-panel default-text-center mobile-text-center"
            style="margin-top: 0px"
            data-margin_default="0"
            data-margin_mobile="0"
            data-spacing-block="true"
            data-block-mobile="true"
          >
            <div class="demo-photo" style="display: none">Imagem Demo</div>
            <img
              class="img-product"
              src="https://api.makefunnels.com.br/storage/media/users/u45/seulogo-2.png"
              alt=""
              style="display: inline-block; max-width: 100%; width: 150px; border-style: none"
              data-cke-saved-src="https://api.makefunnels.com.br/storage/media/users/u45/seulogo-2.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="ui-resizable col-md-2"
      data-type="container-content"
      id="makefunnels-16286857869047285605098"
      style=""
    ></div>
    <div
      class="ui-resizable col-md-3"
      data-type="container-content"
      id="makefunnels-16286857869166986257653"
      style=""
    ></div>
    <div
      class="ui-resizable col-md-3"
      data-type="container-content"
      id="makefunnels-16286857869283774188956"
      style=""
    ></div>
    <div class="col-md-2" data-type="container-content" id="makefunnels-16286857869388781509890">
      <div id="makefunnels-16285199081106626073689" data-type="component-button">
        <div>
          <div class="button-wrapper mobile-text-center default-text-left" data-block-mobile="false">
            <a
              type="button"
              class="btn button-style w-100"
              role="button"
              style="
                margin-top: 0px;
                background-color: rgb(255, 42, 135);
                text-decoration: none;
                color: white;
                border-radius: 5px;
                padding: 8px 20px;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                border: 1px solid rgb(255, 42, 135);
                font-family: Montserrat;
              "
              href=""
              data-font-family="Montserrat"
              data-margin_default="0"
              data-margin_mobile="25"
              data-spacing-block="false"
              data-font="15.04"
              data-config-type="roll-to-element"
              data-element-target=""
              data-box-shadow-type="1"
            >
              <div
                class="btn-title"
                data-font_size_default="16"
                data-font_size_mobile="16"
                style="font-size: 16px"
                data-lock="true"
              >
                <span class="text" style="font-weight: 700">Botão aqui</span>
                <i
                  style="margin-left: 13px; font-size: 16px"
                  class="icon fa fa-user btn-title"
                  hidden="hidden"
                  data-lock="true"
                  data-font_size_mobile="16"
                  data-font_size_default="16"
                ></i>
              </div>
              <span
                class="btn-subtitle d-none"
                data-font_size_default="14"
                data-font_size_mobile="14"
                style="font-size: 14px"
                data-lock="true"
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    padding: 50px 5px 100px;
    background-repeat: no-repeat;
    background-size: contain;
    border-style: none;
    border-width: 0px;
    margin-top: 0px;
    background-color: white;
  "
  id="makefunnels-16281637259314419604327"
  data-margin_default="0"
  data-margin_mobile="0"
  data-spacing-block="true"
>
  <div class="custom-container d-flex flex-wrap">
    <div class="col-sm-6 ui-resizable" data-type="container-content" id="makefunnels-16286857869753807616631">
      <div id="makefunnels-16281637287253252913621" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 40px"
          data-margin_default="40"
          data-margin_mobile="30"
          data-spacing-block="false"
        >
          <h1
            class="text-content default-text-left mobile-text-center"
            data-font_size_default="44"
            data-font_size_mobile="38"
            data-font-family="Montserrat"
            style="font-size: 44px; font-family: Montserrat"
            data-lock="false"
            data-block-mobile="false"
          >
            <span style="color: #212529"><strong>Você pode editar estes textos para usar em sua página.</strong></span>
          </h1>
        </div>
      </div>
      <div id="makefunnels-16281639048235498680801" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 25px"
          data-margin_default="25"
          data-margin_mobile="25"
          data-spacing-block="true"
        >
          <h6
            class="text-content default-text-left mobile-text-center"
            data-font_size_default="18"
            data-font_size_mobile="18"
            data-font-family="Montserrat"
            style="font-size: 18px; font-family: Montserrat"
            data-lock="true"
            data-block-mobile="false"
          >
            Ou caso queira excluí-los, é só apertar neles e clicar na lixeira, no canto superior esquerdo&nbsp;👆
          </h6>
        </div>
      </div>
      <div id="makefunnels-16281640615109316142425" data-type="component-button">
        <div>
          <div class="button-wrapper default-text-center mobile-text-center" data-block-mobile="true">
            <a
              type="button"
              class="btn button-style w-100"
              role="button"
              style="
                margin-top: 25px;
                background-color: transparent;
                text-decoration: none;
                color: rgb(255, 42, 135);
                border-radius: 3px;
                padding: 10px 24px;
                box-shadow: none;
                border: 1px solid rgb(255, 42, 135);
                font-family: Montserrat;
              "
              href=""
              data-font-family="Montserrat"
              data-margin_default="25"
              data-margin_mobile="25"
              data-spacing-block="true"
              data-font="15.04"
              data-config-type="roll-to-element"
              data-element-target=""
              data-box-shadow-type="0"
            >
              <div
                class="btn-title"
                data-font_size_default="20"
                data-font_size_mobile="17"
                style="font-size: 20px"
                data-lock="false"
              >
                <span class="text" style="font-weight: 700">Esse aqui é o botão para os mais ansiosos</span>
                <i
                  style="margin-left: 13px; font-size: 20px"
                  class="icon fa fa-user btn-title"
                  hidden="hidden"
                  data-lock="false"
                  data-font_size_mobile="17"
                  data-font_size_default="20"
                ></i>
              </div>
              <span
                class="btn-subtitle d-none"
                data-font_size_default="14"
                data-font_size_mobile="14"
                style="font-size: 14px"
                data-lock="true"
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6" data-type="container-content" id="makefunnels-16286857869929379388064">
      <div id="makefunnels-16281640357923191640250" data-type="component-photo">
        <div>
          <div
            class="photo-panel default-text-center mobile-text-center"
            style="margin-top: 30px; text-align: center"
            data-margin_default="30"
            data-margin_mobile="30"
            data-spacing-block="false"
            data-block-mobile="true"
          >
            <div class="demo-photo" style="display: none">Imagem Demo</div>
            <img
              class="img-product"
              src="https://api.makefunnels.com.br/storage/media/users/u45/makefunnels-img-painel.png"
              alt=""
              style="display: inline-block; max-width: 100%; border-style: none"
              data-cke-saved-src="https://api.makefunnels.com.br/storage/media/users/u45/makefunnels-img-painel.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    padding: 50px 10px 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(255, 42, 135);
    margin-top: 0px;
  "
  id="makefunnels-16281751990338890285262"
  data-margin_default="0"
  data-margin_mobile="0"
  data-spacing-block="true"
>
  <div class="custom-container">
    <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870041099856268">
      <div id="makefunnels-1628175378114196959317" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 30px"
          data-margin_default="30"
          data-margin_mobile="30"
          data-spacing-block="true"
        >
          <h1
            class="text-content default-text-center mobile-text-center"
            data-font_size_default="36"
            data-font_size_mobile="32"
            data-font-family="Montserrat"
            style="font-size: 36px; font-family: Montserrat"
            data-lock="false"
            data-block-mobile="true"
          >
            <span style="color: #ffffff"
              ><strong>Insira o título da seção aqui<br />​​​​​​​Ex: Por que comprar conosco?</strong></span
            >
          </h1>
        </div>
      </div>
      <div id="makefunnels-1628188102373980818269" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 16px"
          data-margin_default="16"
          data-margin_mobile="16"
          data-spacing-block="true"
        >
          <h6
            class="text-content default-text-center mobile-text-center"
            data-font_size_default="18"
            data-font_size_mobile="18"
            data-font-family="Montserrat"
            style="font-size: 18px; font-family: Montserrat"
            data-lock="true"
            data-block-mobile="true"
          >
            <span style="color: #ffffff"
              >Insira aqui uma instrução para o usuário ou uma breve explicação sobre o que ele vai encontrar nesta
              seção<br />Ex: Compra garantida do conforto da sua casa sem dor de cabeça!</span
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    padding: 50px 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(255, 42, 135);
    margin-top: 0px;
  "
  id="makefunnels-16281749223292967773720"
  data-margin_default="0"
  data-margin_mobile="0"
  data-spacing-block="true"
>
  <div class="custom-container d-flex flex-wrap">
    <div class="col-sm-3 ui-resizable" data-type="container-content" id="makefunnels-16286857870154288957140">
      <div
        style="
          padding: 20px 10px;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 20px;
          margin-top: 0px;
        "
        id="makefunnels-16281760319574549363102"
        data-margin_default="0"
        data-margin_mobile="0"
        data-spacing-block="true"
      >
        <div class="custom-container">
          <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870215519572262">
            <div id="makefunnels-16281761078311349319706" data-type="component-icon">
              <div>
                <div
                  class="icon-wrapper d-flex default-text-center mobile-text-center"
                  data-margin_default="0"
                  data-margin_mobile="0"
                  data-spacing-block="true"
                  data-block-mobile="true"
                  style="margin-top: 0px"
                >
                  <i class="icon fa fa-truck fz-4xi" style="margin-top: 16px; color: rgb(255, 42, 135)"></i>
                </div>
              </div>
            </div>
            <div id="makefunnels-16281761503649362679429" data-type="component-text-2">
              <div
                class="text-container"
                style="margin-top: 16px"
                data-margin_default="16"
                data-margin_mobile="16"
                data-spacing-block="true"
              >
                <h6
                  class="text-content default-text-center mobile-text-center"
                  data-font_size_default="18"
                  data-font_size_mobile="18"
                  data-font-family="Montserrat"
                  style="font-size: 18px; font-family: Montserrat"
                  data-lock="true"
                  data-block-mobile="true"
                >
                  <span style="color: #999999">Ex: Entrega rápida e segura em até 7 dias úteis</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 ui-resizable" data-type="container-content" id="makefunnels-1628685787035994927623">
      <div
        style="
          padding: 20px 10px;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 20px;
          margin-top: 0px;
        "
        id="makefunnels-16281764477651837681381"
        data-margin_default="0"
        data-margin_mobile="0"
        data-spacing-block="true"
      >
        <div class="custom-container">
          <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870409632468065">
            <div id="makefunnels-16281764477597886984976" data-type="component-icon">
              <div>
                <div
                  class="icon-wrapper d-flex default-text-center mobile-text-center"
                  data-margin_default="0"
                  data-margin_mobile="0"
                  data-spacing-block="true"
                  data-block-mobile="true"
                  style="margin-top: 0px"
                >
                  <i class="icon fa fa-lock fz-4xi" style="margin-top: 16px; color: rgb(255, 42, 135)"></i>
                </div>
              </div>
            </div>
            <div id="makefunnels-16281764477617399904497" data-type="component-text-2">
              <div
                class="text-container"
                style="margin-top: 16px"
                data-margin_default="16"
                data-margin_mobile="16"
                data-spacing-block="true"
              >
                <h6
                  class="text-content default-text-center mobile-text-center"
                  data-font_size_default="18"
                  data-font_size_mobile="18"
                  data-font-family="Montserrat"
                  style="font-size: 18px; font-family: Montserrat"
                  data-lock="true"
                  data-block-mobile="true"
                >
                  <span style="color: #999999">Ex: Pagamento em ambiente seguro e com criptografia de ponta</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 ui-resizable" data-type="container-content" id="makefunnels-16286857870513415329274">
      <div
        style="
          padding: 20px 10px;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 20px;
          margin-top: 0px;
        "
        id="makefunnels-16281764522571168564448"
        data-margin_default="0"
        data-margin_mobile="0"
        data-spacing-block="true"
      >
        <div class="custom-container">
          <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870553173414945">
            <div id="makefunnels-16281764522541955493173" data-type="component-icon">
              <div>
                <div
                  class="icon-wrapper d-flex default-text-center mobile-text-center"
                  data-margin_default="0"
                  data-margin_mobile="0"
                  data-spacing-block="true"
                  data-block-mobile="true"
                  style="margin-top: 0px"
                >
                  <i class="icon fa fa-star fz-4xi" style="margin-top: 16px; color: rgb(255, 42, 135)"></i>
                </div>
              </div>
            </div>
            <div id="makefunnels-16281764522541132682800" data-type="component-text-2">
              <div
                class="text-container"
                style="margin-top: 16px"
                data-margin_default="16"
                data-margin_mobile="16"
                data-spacing-block="true"
              >
                <h6
                  class="text-content default-text-center mobile-text-center"
                  data-font_size_default="18"
                  data-font_size_mobile="18"
                  data-font-family="Montserrat"
                  style="font-size: 18px; font-family: Montserrat"
                  data-lock="true"
                  data-block-mobile="true"
                >
                  <span style="color: #999999"
                    >Ex: Mais de 1540 clientes satisfeitos. 98,8% de avaliações positivas.</span
                  >
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3" data-type="container-content" id="makefunnels-16286857870641805808632">
      <div
        style="
          padding: 20px 10px;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 20px;
          margin-top: 0px;
        "
        id="makefunnels-16281764569962665951476"
        data-margin_default="0"
        data-margin_mobile="0"
        data-spacing-block="true"
      >
        <div class="custom-container">
          <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870689145897266">
            <div id="makefunnels-1628176456993103716224" data-type="component-icon">
              <div>
                <div
                  class="icon-wrapper d-flex default-text-center mobile-text-center"
                  data-margin_default="0"
                  data-margin_mobile="0"
                  data-spacing-block="true"
                  data-block-mobile="true"
                  style="margin-top: 0px"
                >
                  <i class="icon fa fa-refresh fz-4xi" style="margin-top: 16px; color: rgb(255, 42, 135)"></i>
                </div>
              </div>
            </div>
            <div id="makefunnels-16281764569939287023461" data-type="component-text-2">
              <div
                class="text-container"
                style="margin-top: 16px"
                data-margin_default="16"
                data-margin_mobile="16"
                data-spacing-block="true"
              >
                <h6
                  class="text-content default-text-center mobile-text-center"
                  data-font_size_default="18"
                  data-font_size_mobile="18"
                  data-font-family="Montserrat"
                  style="font-size: 18px; font-family: Montserrat"
                  data-lock="true"
                  data-block-mobile="true"
                >
                  <span style="color: #999999">Ex: Troca e devolução grátis. Garantia incondicional de 7 dias.</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    padding: 20px 10px 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(255, 42, 135);
    margin-top: 0px;
  "
  id="makefunnels-16285537454466767331244"
  data-margin_default="0"
  data-margin_mobile="0"
  data-spacing-block="true"
>
  <div class="custom-container">
    <div class="col-sm-12" data-type="container-content" id="makefunnels-16286857870819361245674">
      <div id="makefunnels-16285537470608703877161" data-type="component-button">
        <div>
          <div class="button-wrapper default-text-center mobile-text-center" data-block-mobile="true">
            <a
              type="button"
              class="btn button-style w-50"
              role="button"
              style="
                margin-top: 0px;
                background-color: transparent;
                text-decoration: none;
                color: white;
                border-radius: 3px;
                padding: 10px 24px;
                box-shadow: none;
                border: 1px solid white;
                font-family: Montserrat;
              "
              href=""
              data-font-family="Montserrat"
              data-margin_default="0"
              data-margin_mobile="0"
              data-spacing-block="true"
              data-font="15.04"
              data-config-type="roll-to-element"
              data-element-target=""
              data-box-shadow-type="0"
            >
              <div
                class="btn-title"
                data-font_size_default="20"
                data-font_size_mobile="20"
                style="font-size: 20px"
                data-lock="true"
              >
                <span class="text" style="font-weight: 700">Botão aqui</span>
                <i
                  style="margin-left: 13px; font-size: 20px"
                  class="icon fa fa-user btn-title"
                  hidden="hidden"
                  data-lock="true"
                  data-font_size_mobile="20"
                  data-font_size_default="20"
                ></i>
              </div>
              <span
                class="btn-subtitle d-none"
                data-font_size_default="14"
                data-font_size_mobile="14"
                style="font-size: 14px"
                data-lock="true"
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    padding: 50px 10px 100px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 0px;
    background-color: white;
  "
  id="makefunnels-1628165245982473363443"
  data-margin_default="0"
  data-margin_mobile="20"
  data-spacing-block="false"
>
  <div class="custom-container d-flex flex-wrap">
    <div class="col-sm-6 ui-resizable" data-type="container-content" id="makefunnels-16286857870851617800847">
      <div id="makefunnels-16281652993665138034277" data-type="component-photo">
        <div>
          <div
            class="photo-panel default-text-center mobile-text-center"
            style="margin-top: 30px; text-align: center"
            data-margin_default="30"
            data-margin_mobile="30"
            data-spacing-block="true"
            data-block-mobile="true"
          >
            <div class="demo-photo" style="display: none">Imagem Demo</div>
            <img
              class="img-product"
              src="https://api.makefunnels.com.br/storage/media/users/u45/pages-final-1.png"
              alt=""
              style="display: inline-block; max-width: 100%; border-style: none"
              data-cke-saved-src="https://api.makefunnels.com.br/storage/media/users/u45/pages-final-1.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6" data-type="container-content" id="makefunnels-16286857870889791005713">
      <div id="makefunnels-16281653693131427079313" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 30px"
          data-margin_default="30"
          data-margin_mobile="30"
          data-spacing-block="true"
        >
          <h6
            class="text-content default-text-left mobile-text-left"
            data-font_size_default="18"
            data-font_size_mobile="18"
            data-font-family="Montserrat"
            style="font-size: 18px; font-family: Montserrat"
            data-lock="true"
            data-block-mobile="true"
          >
            <span style="color: #999999"
              >INSIRA AQUI SEU DIFERENCIAL OU BENEFÍCIO.<br />​​​​​​​EX: BATERIA DURÁVEL</span
            >
          </h6>
        </div>
      </div>
      <div id="makefunnels-16281653960017034526838" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 16px"
          data-margin_default="16"
          data-margin_mobile="16"
          data-spacing-block="true"
        >
          <h1
            class="text-content default-text-left mobile-text-left"
            data-font_size_default="30"
            data-font_size_mobile="30"
            data-font-family="Montserrat"
            style="font-size: 30px; font-family: Montserrat"
            data-lock="true"
            data-block-mobile="true"
          >
            <span style="color: #212529"
              ><strong
                >Insira aqui o resultado que esse benefício gera.<br />​​​​​​​Ex: Escute música por horas sem ser
                interrompido</strong
              ></span
            >
          </h1>
        </div>
      </div>
      <div id="makefunnels-16281655558031732244549" data-type="component-text-2">
        <div
          class="text-container"
          style="margin-top: 16px"
          data-margin_default="16"
          data-margin_mobile="16"
          data-spacing-block="true"
        >
          <h6
            class="text-content default-text-left mobile-text-left"
            data-font_size_default="18"
            data-font_size_mobile="18"
            data-font-family="Montserrat"
            style="font-size: 18px; font-family: Montserrat"
            data-lock="true"
            data-block-mobile="true"
          >
            <span style="color: #212529">Coloque aqui uma breve explicação sobre sobre esse benefício</span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>

`
