import imageEventListener from '../helpers/imageEventListener'

const useImageWidth = ({ form, keditor, elementRef, customFormRef, isFirst }) => {
  const configDefault = customFormRef || 'photo-width'
  const configMobile = `${configDefault}-mobile`
  const blockConfig = 'block-width'

  const element = (name) => form && form.find(`#${name}`)

  const init = () => {
    element(configDefault).on('change', ({ target: { value } } = e) => {
      const isBlocked = elementRef().attr('data-block-width') && JSON.parse(elementRef().attr('data-block-width'))
      let newWidth = +value

      if (newWidth <= 0) {
        value = 0
        newWidth = 0
      }
      elementRef().attr('data-width_default', newWidth)

      if (isBlocked) {
        elementRef().attr(
          'data-width_mobile',
          newWidth <= keditor.options.widthMobile ? newWidth : keditor.options.widthMobile
        )
        element(configMobile).val(newWidth <= keditor.options.widthMobile ? newWidth : keditor.options.widthMobile)
      }
      const currentWidth = keditor.options.currentDeviceWidth
      imageEventListener(currentWidth, keditor)
    })

    element(configMobile).on('change', ({ target: { value } } = e) => {
      let newWidth = +value

      if (newWidth <= 0) {
        value = 0
        newWidth = 0
      }
      elementRef().attr('data-width_mobile', newWidth)

      const currentWidth = keditor.options.currentDeviceWidth
      imageEventListener(currentWidth, keditor)
    })

    element(blockConfig).on('click', function (e) {
      const icon = $(this).find('i')

      if (icon.hasClass('fa-lock')) {
        elementRef().attr('data-block-width', false)
        element(configMobile).attr('disabled', false)
      } else {
        const value = element(configDefault).val() > 420 ? 420 : element(configDefault).val()
        element(configMobile).attr('disabled', true)
        elementRef().attr({ 'data-width_mobile': value, 'data-block-width': true })
        element(configMobile).val(value)
      }

      const currentWidth = keditor.options.currentDeviceWidth
      imageEventListener(currentWidth, keditor)

      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')
    })
  }

  const show = () => {
    const isMobileAlignBlocked =
      elementRef().attr('data-block-width') && !JSON.parse(elementRef().attr('data-block-width'))

    element(configDefault).val(elementRef().attr(`data-width_default`) || elementRef().width())
    element(configMobile).val(
      elementRef().attr(`data-width_mobile`) ||
        (elementRef().width() < keditor.options.widthMobile ? elementRef().width() : keditor.options.widthMobile)
    )
    if (elementRef().attr('src')) {
      if (!elementRef().attr(`data-width_default`)) {
        elementRef().attr(`data-width_default`, elementRef().width())
      }
      if (!elementRef().attr(`data-width_mobile`)) {
        elementRef().attr(
          `data-width_mobile`,
          elementRef().width() < keditor.options.widthMobile ? elementRef().width() : keditor.options.widthMobile
        )
      }
    }

    const icon = element(blockConfig).find('i')

    const currentWidth = keditor.options.currentDeviceWidth
    imageEventListener(currentWidth, keditor)

    switch (!isMobileAlignBlocked) {
      case true:
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')
        element(configMobile).attr('disabled', true)
        elementRef().attr('data-block-width', true)
        break
      case false:
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')
        element(configMobile).attr('disabled', false)
        elementRef().attr('data-block-width', false)
        break
    }
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-6 ${isFirst ? '' : 'mt-3'}">
      <label for="${configDefault}">Largura</label>
      <input type="number" id="${configDefault}" min="0" class="form-control" />
    </div>
    <div class="tab-content col-sm-6 ${isFirst ? '' : 'mt-3'}">
    <label for="${configMobile}">Largura celular</label>
      <div class="d-flex align-items-center">
        <span
        id="${blockConfig}"
        class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
        >
        <i class="fa fa-unlock" aria-hidden="true"></i>
        </span>
        <input type="number" id="${configMobile}" min="0" class="form-control" />
      </div>
    </div>
    `

  return {
    componentName: 'imageWidth',
    init,
    show,
    template,
  }
}

export default useImageWidth
