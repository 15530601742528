import { ModalBase } from './template/modalBase'

export default function () {
  let self = this

  let modalFooter = /*html*/ `
            <button type="button" class="btn outlined" data-dismiss="modal">
                ${this.options.locale.close}
            </button>
            <button type="button" class="btn save">
                ${this.options.locale.insertCode}
            </button>`
  let modal = $(
    ModalBase({
      id: 'modal-custom-css',
      title: this.options.locale.modalCss.title,
      body: /*html*/ `<p class="mb-2">${this.options.locale.modalCss.description}</p>
            <div class="col-sm-12 editor" id="editor-custom-css"></div>`,
      footer: modalFooter,
    })
  )

  modal.find('.save').on('click', function () {
    if (self.options.onCSSInsert && self.options.onCSSInsert instanceof Function) {
      self.options.onCSSInsert.call(self, modal.find('#editor-custom-css'))
    }

    modal.modal('hide')
  })

  modal.on('show.bs.modal', function () {
    self.options.onCSSCodeEditorShow.call(self, modal.find('#editor-custom-css'))
  })

  let result = modal.appendTo(self.wrapper)

  if (self.options.onCSSCodeEditorInit && self.options.onCSSCodeEditorInit instanceof Function) {
    self.options.onCSSCodeEditorInit.call(self, modal.find('#editor-custom-css'))
  }

  return result
}
