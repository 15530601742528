export default class FormComponent {
  #obj = [
    {
      target: 'nome',
      placeholder: '',
      isRequired: true,
      textSuggestion: false,
      hideTitle: false,
      shows: true,
    },
    {
      target: 'email',
      placeholder: '',
      isRequired: true,
      textSuggestion: false,
      hideTitle: false,
      shows: true,
      disabled: true,
    },
    {
      target: 'celular',
      placeholder: '',
      isRequired: true,
      textSuggestion: false,
      hideTitle: false,
      shows: true,
    },
  ]

  #hide = []

  #divConfig = '#form-config'
  #modalBody = '.modal-body'
  #modalID = '#modal-form'
  #emptyContent = '#empty-content'
  //configs
  #placeholder = '.edit-placeholder'
  #inputsConfig = '.form-input-config'

  set data(newObject) {
    this.#obj = []
    this.#hide = []
    newObject.map((el) => {
      if (el.shows) this.#obj.push(el)
      else this.#hide.push(el)
    })
  }

  get object() {
    return [...this.#obj, ...this.#hide]
  }

  #findConfig(target) {
    return this.#obj.find((el) => el.target === target) || this.#hide.find((el) => el.target === target)
  }

  #renderView() {
    let body = ''

    const labels = {
      name: 'Nome',
      email: 'E-mail',
      phone: 'Celular',
    }

    this.#obj.forEach((el, index) => {
      body += /*html*/ `
      <div class="field-group" id="${el.target}">
        <div class="fields-config">
          <div class="field-moves">
            <a 
              class="move-icon move-down" 
              role="button"
              data-target="${el.target}"
              data-index="${index}"
            >
              <i style="cursor: pointer;" class="fa fa-arrow-down"></i>
            </a>
            <a 
              class="move-icon move-up" 
              role="button"
              data-target="${el.target}"
              data-index="${index}"
            >
              <i style="cursor: pointer;" class="fa fa-arrow-up"></i>
            </a>
          </div>
        </div>
  
        
        <div class="fields-inputs">
          <h5>${labels[el.target]}</h5>
          <input 
            type="text" 
            class="form-control edit-placeholder" 
            id="${el.target}-placeholder" 
            name="${el.target}-placeholder"
            data-target="${el.target}" 
            placeholder="Digite aqui para editar o placeholder"
            autocomplete="off"
            value="${el.placeholder}"
            data-index="${index}"
          />
  
          <div class="fields-info">
            <div class="custom-control custom-switch mr-3">
              <input 
                type="checkbox" 
                class="custom-control-input form-input-config" 
                id="${el.target}-isRequired"
                data-target="${el.target}"
                data-index="${index}"
                data-type="isRequired"
                ${el.isRequired ? 'checked' : ''}
                ${el.target === 'email' ? 'disabled' : ''}
              />
              <label class="custom-control-label" for="${el.target}-isRequired">Campo obrigatório</label>
            </div>
  
            <div class="custom-control custom-switch">
              <input 
                type="checkbox" 
                class="custom-control-input form-input-config" 
                id="${el.target}-hideTitle"
                data-target="${el.target}" 
                data-index="${index}"
                data-type="hideTitle"
                ${el.hideTitle ? 'checked' : ''}
              />
              <label class="custom-control-label" for="${el.target}-hideTitle">Esconder título</label>
            </div>
          </div>
        </div>
      </div>
      `
    })

    $(this.#modalID).find(this.#divConfig).html(body)

    $(this.#modalID).find(this.#emptyContent).hide()
    if (!this.#obj.length) $(this.#modalID).find(this.#emptyContent).show()
  }

  #method(target, action, callback) {
    $(this.#modalID).find(this.#modalBody).off(action, target)
    $(this.#modalID).find(this.#modalBody).on(action, target, callback)
  }

  edit() {
    const self = this

    this.#method('.show-input', 'click', function (e) {
      const target = $(this).attr('data-type')
      const el = self.#findConfig(target)

      el.shows = !$(this).find('input[type="checkbox"]').prop('checked')
      $(this).find('input[type="checkbox"]').attr('checked', el.shows)

      if (el.shows) {
        self.#obj.push(el)
        self.#hide = self.#hide.filter((obj) => obj.target != el.target)
      } else {
        self.#hide.push(el)
        self.#obj = self.#obj.filter((obj) => obj.target != el.target)
      }

      self.#renderView()
    })

    this.#method('.move-down', 'click', function (e) {
      const index = $(this).data('index')
      if (index + 1 < self.#obj.length)
        [self.#obj[index], self.#obj[index + 1]] = [self.#obj[index + 1], self.#obj[index]]

      self.#renderView()
    })

    this.#method('.move-up', 'click', function (e) {
      const index = $(this).data('index')
      if (index > 0) [self.#obj[index], self.#obj[index - 1]] = [self.#obj[index - 1], self.#obj[index]]

      self.#renderView()
    })

    this.#method(this.#inputsConfig, 'change', function (e) {
      const target = $(this).data('target')
      const index = $(this).data('index')
      const type = $(this).data('type')
      if (self.#obj[index].target === target) {
        self.#obj[index][type] = !self.#obj[index][type]
      }
    })

    this.#method(this.#placeholder, 'keyup', function (e) {
      const target = $(this).data('target')
      const index = $(this).data('index')
      const { value } = e.target
      if (self.#obj[index].target === target) {
        self.#obj[index].placeholder = value
      }
    })
  }

  show() {
    this.#renderView()

    this.#obj.map((el) => {
      $(this.#modalID).find(`#${el.target}-shows`).attr('checked', true)
    })

    this.#hide.map((el) => {
      $(this.#modalID).find(`#${el.target}-shows`).attr('checked', false)
    })
  }
}
