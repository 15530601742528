import CSS_CLASS from '../constants/cssClass'

const getColByWidth = (grid, width) => {
  let closest
  let minDiff = null

  for (let i = 0; i < grid.length; ++i) {
    let diff = Math.abs(grid[i].width - width)

    if (minDiff == null || diff < minDiff) {
      closest = i
      minDiff = diff
    } else {
      const col = grid[closest]['col']
      return col !== 12 ? col : 1
    }
  }

  return null
}

const setCol = (col, deviceClass, colNum) => {
  col.removeClass(function (index, className) {
    return (className.match(/(^|\s)col-\S+/g) || []).join(' ')
  })
  col.addClass(`col-${deviceClass}-${colNum}`)
  col.css('width', '')
  col.css('height', '')
}

export default function (container) {
  let self = this
  let options = self.options
  let closestAux = null
  let rows = [[]]
  let groupNumber = -1

  container.find('.d-flex > [class*="col-"]').each(function () {
    if (closestAux === null) {
      rows[++groupNumber] = new Array()
      rows[groupNumber].push(this)
      return (closestAux = $(this).closest('.d-flex'))
    }

    const parent = $(this).closest('.d-flex')

    if (parent.length && parent[0] === closestAux[0]) {
      rows[groupNumber].push(this)
      return
    }

    rows[++groupNumber] = new Array()
    rows[groupNumber].push(this)

    return (closestAux = parent)
  })
  
  let cols = []
  groupNumber !== -1 && rows.map(row => {
    row.pop()
    cols = [...cols, ...row]
  })

  if (cols && cols.length > 0) {
    $(cols).resizable({
      handles: 'resizer, none',
      create: function () {
        $(this).find(`.${CSS_CLASS.UI_RESIZER}`).attr('title', options.locale.columnResizeTitle)
        // element_uiResizer()
      },
      resize: function (e, ui) {
        let col = $(this)
        let NextSibling = $(this).next()

        let deviceClass = options.bootstrap.deviceClass[self.deviceMode]

        let row = col.parent()
        let colNum = getColByWidth(options.bootstrap.gridSystem, (100 * ui.size.width) / row.innerWidth())
        let current = getColByWidth(options.bootstrap.gridSystem, (100 * col.outerWidth()) / row.innerWidth())
        let next = getColByWidth(options.bootstrap.gridSystem, (100 * NextSibling.outerWidth()) / row.innerWidth())

        let siblingsColNum = Math.floor(current + next - colNum)
        if (siblingsColNum <= 0) {
          return
        }

        setCol(col, deviceClass, colNum)
        setCol(NextSibling, deviceClass, siblingsColNum)
      },
    })
  }

  const element_uiResizer = () => {
    cols.each(function () {
      let el = $(this)
      const uiResizer = el.find(`.${CSS_CLASS.UI_RESIZER}`)
      uiResizer.css('height', container.outerHeight())
    })
  }
}
