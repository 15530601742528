import renderSnippet from './renderSnippet'
import CSS_CLASS from '../constants/cssClass'
import beautifyCategories from './beautifyCategories'
//import log from '../utils/log';

export default function (resp) {
  let self = this
  let snippetsContentHtml = ''
  //let snippetsHtml = '';
  let snippetsHtmlArray = ['', '']

  self.sidebarLeft.find(`.${CSS_CLASS.BOOTSTRAP_TAB_CONTENT}`).html(`
        <div class="${CSS_CLASS.BOOTSTRAP_TAB_PANE} ${CSS_CLASS.BOOTSTRAP_FADE} show active" id="${self.options.sidebarLeft.nav.items[0].id}" role="tabpanel" aria-labelledby="${self.options.sidebarLeft.nav.items[0].id}-tab">
            <div id="${self.options.sidebarLeft.nav.items[0].id}-filter" class="${CSS_CLASS.UI} ${CSS_CLASS.SNIPPETS_FILTER_WRAPPER}"></div>
            <div class="${CSS_CLASS.SNIPPETS_WRAPPER}"><div class="${CSS_CLASS.SNIPPETS}"></div></div>
        </div>
        <div class="${CSS_CLASS.BOOTSTRAP_TAB_PANE} ${CSS_CLASS.BOOTSTRAP_FADE}" id="${self.options.sidebarLeft.nav.items[1].id}" role="tabpanel" aria-labelledby="${self.options.sidebarLeft.nav.items[1].id}-tab">
            <div id="${self.options.sidebarLeft.nav.items[1].id}-filter" class="${CSS_CLASS.UI} ${CSS_CLASS.SNIPPETS_FILTER_WRAPPER}"></div>
            <div class="${CSS_CLASS.SNIPPETS_WRAPPER}"><div class="${CSS_CLASS.SNIPPETS}"></div></div>
        </div>
    `)

  $(resp)
    .filter('div')
    .each(function () {
      let snippet = $(this)
      let isHidden = snippet.attr('data-hidden')
      if (isHidden === 'true') return

      let content = snippet.html().trim()
      let previewUrl = snippet.attr('data-preview') //img-url
      let type = snippet.attr('data-type') //containter || component-X
      let title = snippet.attr('data-keditor-title') //tittle to show
      let categories = snippet.attr('data-keditor-categories') || '' //for search engine and selector
      let navtab = snippet.attr('data-nav-tab') //which nav tab will show

      if (type == 'component-quiz' && self.options.contentUnique.quiz) return

      let dataAttributes = self.getDataAttributes(
        snippet,
        ['data-preview', 'data-type', 'data-nav-tab', 'data-keditor-title', 'data-keditor-categories'],
        true
      )
      let [snippetPreviewHtml, snippetContentHtml] = renderSnippet.call(
        self,
        type,
        title,
        previewUrl,
        categories,
        content,
        dataAttributes,
        navtab
      )

      self.options.sidebarLeft.nav.items.forEach((element) => {
        if (navtab == element.id && element.numColumns == 2) {
          snippetPreviewHtml = $(snippetPreviewHtml).addClass(`${CSS_CLASS.SNIPPET_COLUMN_2}`).prop('outerHTML')
        }
      })

      snippetsHtmlArray[navtab == self.options.sidebarLeft.nav.items[0].id ? 0 : 1] += snippetPreviewHtml

      //snippetsHtml += snippetPreviewHtml;
      snippetsContentHtml += snippetContentHtml
    })

  self.categoryContainer = beautifyCategories(self.categoryContainer) //esconde os duplicados e ordena para por no selector
  self.categoryComponent = beautifyCategories(self.categoryComponent) //esconde os duplicados e ordena para por no selector

  //self.sidebarLeft.find(`.${CSS_CLASS.SNIPPETS}`).html(snippetsHtml);
  self.sidebarLeft.find(`.${CSS_CLASS.SNIPPETS}`).each(function (index) {
    $(this).html(snippetsHtmlArray[index])
  })

  self.sidebarLeft.find(`.${CSS_CLASS.SIDEBAR_LEFT_BODY}`).append(snippetsContentHtml)
}
