import React, { memo } from 'react'

export const SelectList = memo(
  ({
    emptyItem = 'Escolha uma opção...',
    list,
    itemSelected,
    handleChange,
    OptionComponent,
    disabled,
  }) => {
    return (
      <select
        className="form-control"
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        value={itemSelected}
        style={{
          opacity: disabled ? '0.5' : '1',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <option value="" disabled>
          {emptyItem}
        </option>
        {list &&
          list.map((item, index) => (
            <OptionComponent key={`option-component-${index}`} item={item} />
          ))}
      </select>
    )
  }
)
SelectList.displayName = 'SelectList'
