const useBorderRadius = ({
  form,
  keditor,
  component,
  label,
  customFormRef,
  elementRef,
  customOptions,
  fullWidth,
  isFirst,
}) => {
  const formRef = customFormRef || `border-radius-editor`
  const element = form && form.find(`#${formRef}`)
  const options = customOptions || [
    {
      name: 'Quadrada',
      value: '0px',
    },
    {
      name: '3px',
    },
    {
      name: '5px',
    },
    {
      name: '10px',
    },
    {
      name: '15px',
    },
    {
      name: 'Circular',
      value: '50%',
    },
  ]

  const init = () => {
    //console.log(form.find(`#${formRef}`))
    form.find(`#${formRef}`).on('change', (event) => {
      elementRef().css('border-radius', event.target.value)
    })
  }

  const show = () => {
    const borderValue = elementRef().css('border-radius')
    const option = options.filter((el) => el.value === borderValue || el.name === borderValue)[0]

    form.find(`#${formRef}`).val(option ? option.value || option.name : '5px')
  }

  const renderOptions = () => {
    let acc = ''
    options.forEach((element) => {
      acc += `<option value="${element.value || element.name}">${element.name}</option>`
    })
    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `

  return {
    componentName: 'borderRadius',
    init,
    show,
    template,
  }
}

export default useBorderRadius
