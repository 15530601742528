import * as React from 'react'
import { INTEGRATIONS_PANEL_VIEW } from '../constants'

const IntegrationContext = React.createContext()

function IntegrationProvider({
  children,
  keditor,
  integrations: initialState,
}) {
  const [panelView, setPanelView] = React.useState(INTEGRATIONS_PANEL_VIEW.LIST)
  const [integrations, setIntegrations] = React.useState(initialState ?? {})
  const { redirect_url: redirectURL } = integrations
  const isInitialRender = React.useRef(true)
  const integrationToEdit = React.useRef(null)

  const setIntegrationToEdit = (type, position) => {
    integrationToEdit.current = {
      type,
      position,
    }
  }

  const clearIntegrationToEdit = () => {
    integrationToEdit.current = null
  }

  const setRedirectURL = (value) => {
    setIntegrations((prevState) => ({
      ...prevState,
      redirect_url: value,
    }))
  }

  const addIntegration = ({ integration }) => {
    setIntegrations((prevState) => ({
      ...prevState,
      data: [...(prevState?.data ?? []), { ...integration }],
    }))
  }

  const removeIntegration = (integrationPosition) => {
    const integrationsTemp = [...(integrations?.data ?? [])]

    if (!integrationsTemp.length) return

    integrationsTemp.splice(integrationPosition, 1)

    setIntegrations((prevState) => ({
      ...prevState,
      data: integrationsTemp,
    }))
  }

  const editIntegration = ({ integrationPosition, integration }) => {
    const integrationsTemp = [...(integrations?.data ?? [])]

    if (!integrationsTemp.length) return

    integrationsTemp[integrationPosition] = integration

    setIntegrations((prevState) => ({
      ...prevState,
      data: integrationsTemp,
    }))
  }

  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    // set keditor values direclty because this is a global reference
    // eslint-disable-next-line no-param-reassign
    keditor.options.integrations = integrations
  }, [integrations])

  const value = {
    keditor,
    integrations,
    panelView,
    redirectURL,
    integrationToEdit: integrationToEdit.current,
    setRedirectURL,
    setPanelView,
    addIntegration,
    removeIntegration,
    editIntegration,
    setIntegrationToEdit,
    clearIntegrationToEdit,
  }

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  )
}

function useIntegration() {
  const context = React.useContext(IntegrationContext)
  if (!context) {
    throw new Error('useIntegration must be used within a IntegrationProvider')
  }
  return context
}

export { IntegrationProvider, useIntegration }
