import React from 'react'
import { usePopup } from '../../context/popupContext'
import { Sketch } from '../Sketch'
import { InputFont, InputSize, InputSizeMobile } from '../Form'
import { handleChange, setObjectByKey } from '../../utils'

export const Styles = () => {
    const {
        state: { modalPopup, contentPopup },
        setContent,
    } = usePopup()

    const { color } = modalPopup?.tabs?.styles?.config?.form?.inputs
    const { labels } = modalPopup?.tabs?.styles

    function handleChangeColor(color, key) {
        if (!color) return

        setContent((prevContent) => ({
            ...prevContent,
            ...setObjectByKey(prevContent, key, color),
        }))
    }

    return (
        <>
            <div className="form-group pb-2">
                <label className="col col-form-label font-weight-normal">
                    {labels?.backgroundColor}
                </label>
                <div className='row pt-2'>
                    <div className="col-6">
                        {/* <label className="col">{color?.text}</label> */}
                        <div className="col d-flex justify-content-start align-items-center">
                            <Sketch
                                initialColor={contentPopup.styles.wrapper.backgroundColor}
                                onChange={(color) =>
                                    handleChangeColor(color, 'contentPopup.styles.wrapper.backgroundColor')
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group pb-2">
                <label className="col col-form-label font-weight-normal">
                    {labels?.title}
                </label>
                <div className='row'>
                    <InputSize
                        name="contentPopup.styles.title.size"
                        value={contentPopup.styles.title.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                    <InputSizeMobile
                        name="contentPopup.styles.title.mobile.size"
                        value={contentPopup.styles.title.mobile.size}
                        inheritValue={contentPopup.styles.title.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                </div>
                <div className='row pt-2'>
                    <div className="col-6">
                        <label className="col">{color?.text}</label>
                        <div className="col d-flex justify-content-start align-items-center">
                            <Sketch
                                initialColor={contentPopup.styles.title.color}
                                onChange={(color) =>
                                    handleChangeColor(color, 'contentPopup.styles.title.color')
                                }
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <InputFont
                            name="contentPopup.styles.title.font"
                            value={contentPopup.styles.title.font}
                            onChange={(e) => handleChange(e, setContent)}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="form-group py-2">
                <label className="col col-form-label font-weight-normal">
                    {labels?.content}
                </label>
                <div className='row'>
                    <InputSize
                        name="contentPopup.styles.body.size"
                        value={contentPopup.styles.body.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                    <InputSizeMobile
                        name="contentPopup.styles.body.mobile.size"
                        value={contentPopup.styles.body.mobile.size}
                        inheritValue={contentPopup.styles.body.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                </div>
                <div className='row pt-2'>
                    <div className="col-6">
                        <label className="col">{color?.text}</label>
                        <div className="col d-flex justify-content-start align-items-center">
                            <Sketch
                                initialColor={contentPopup.styles.body.color}
                                onChange={(color) =>
                                    handleChangeColor(color, 'contentPopup.styles.body.color')
                                }
                                direction="top"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <InputFont
                            name="contentPopup.styles.body.font"
                            value={contentPopup.styles.body.font}
                            onChange={(e) => handleChange(e, setContent)}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="form-group py-2">
                <label className="col col-form-label font-weight-normal">
                    {labels?.button}
                </label>
                <div className='row'>
                    <InputSize
                        name="contentPopup.styles.cta.size"
                        value={contentPopup.styles.cta.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                    <InputSizeMobile
                        name="contentPopup.styles.cta.mobile.size"
                        value={contentPopup.styles.cta.mobile.size}
                        inheritValue={contentPopup.styles.cta.size}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                </div>
                <div className='row pt-2'>
                    <div className="col-6">
                        <label className="col">{color?.text}</label>
                        <div className="col d-flex justify-content-start align-items-center">
                            <Sketch
                                initialColor={contentPopup.styles.cta.color}
                                onChange={(color) =>
                                    handleChangeColor(color, 'contentPopup.styles.cta.color')
                                }
                                direction="top"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <InputFont
                            name="contentPopup.styles.cta.font"
                            value={contentPopup.styles.cta.font}
                            onChange={(e) => handleChange(e, setContent)}
                        />
                    </div>
                    <div className="col-6">
                        <label className="col">{color?.background}</label>
                        <div className="col d-flex justify-content-start align-items-center">
                            <Sketch
                                initialColor={contentPopup.styles.cta.background}
                                onChange={(color) =>
                                    handleChangeColor(color, 'contentPopup.styles.cta.background')
                                }
                                direction="top"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
