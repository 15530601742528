import CSS_CLASS from "../constants/cssClass";
import closeSidebar from "../sidebar/closeSidebar";
import setCopyContent from "../utils/setCopyContent";

export default function () {
	let self = this;
	let contentAreasWrapper = self.contentAreasWrapper;
	contentAreasWrapper.on("click", `.${CSS_CLASS.CONTAINER_COPY}`, function (e) {
		e.preventDefault();

		let btn = $(this);

		btn.hide();
		$(this)
			.parent()
			.find(`.${CSS_CLASS.CONTAINER_CANCEL}`)
			.attr("style", "display: block !important;");

		// $(this)
		// 	.parent()
		// 	.parent()
		// 	.find(`.mf-copy-content`)
		// 	.attr("style", "display: inline-block");
		self.iframeBody
			.find(`.mf-copy-content`)
			.attr("style", "display: inline-block");

		let container = btn.closest(`.${CSS_CLASS.CONTAINER}`);
		setCopyContent.call(self, container, false);

		setTimeout(() => {
			closeSidebar.call(self);
		}, 50);
	});

	contentAreasWrapper.on("click", `.${CSS_CLASS.CONTAINER_CANCEL}`, function (
		e
	) {
		e.preventDefault();

		$(this).hide();
		$(this)
			.parent()
			.find(`.${CSS_CLASS.CONTAINER_COPY}`)
			.attr("style", "display: block !important;");

		// $(this).parent().parent().find(`.mf-copy-content`).hide();
		self.iframeBody.find(`.mf-copy-content`).hide();

		setCopyContent.call(self, null, false);

		setTimeout(() => {
			closeSidebar.call(self);
		}, 50);
	});
}
