const clearButton = (id) => /*html*/ `
<div class="col-sm-12">
  <a href="#" class="clear-btn" style="font-size: 14px" data-id="${id}">Limpar configuração</a>
</div>
`
const inputRangeColor = ({ id, idRange, maxRange = 100, label = '' }) => /*html*/ `
<div class="d-flex flex-wrap mt-3">
  <div class="col-sm-6">
    <label>Cor ${label}</label>
    <input data-id="#${id}" class="color-picker-component form-control" />
  </div>
  <div class="col-sm-6">
    <label>Posição</label>
    <input 
      type="number" 
      id="${idRange}" 
      class="form-control" 
      value="000" 
      min="0" 
      max="${maxRange}"
    />
  </div>
</div>
`
const inputCards = ({ value = '', icon = '', rotate = 0, size = '4', target = '' }) => /*html*/ `
<li class="nav-item col-sm-${size} mx-0" style="padding: 1px">
  <a
    class="nav-link nav-item-custom d-flex w-100 justify-content-center"
    id="${value}"
    data-toggle="pill"
    href="#"
    role="tab"
    data-value="${value}"
    data-target="${target}"
    aria-controls=""
    aria-selected="true"
  >
    <i class="fa fa-${icon}" data-value="${value}" style="transform: rotate(${rotate}deg);"></i>
  </a>
</li>
`

export { clearButton, inputRangeColor, inputCards }
