import React, { memo } from 'react'
import { SelectList } from '../SelectList/SelectList'

export const Platforms = memo(({ platforms, itemSelected, handleChange }) => (
  <SelectList
    emptyItem="Escolha um provedor de Email Marketing"
    list={platforms}
    handleChange={(service) => {
      const plaftformSelected =
        platforms.filter((item) => item.service === service)[0] || null
      handleChange(service, plaftformSelected)
    }}
    itemSelected={itemSelected}
    OptionComponent={({ item }) => (
      <option key={item.service} value={item.service}>
        {item.name || item.label}
      </option>
    )}
  />
))
Platforms.displayName = 'Platforms'
