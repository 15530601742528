import DEFAULTS from './constants/defaults'
import { SETTINGS } from '../constants'
import initSnippetsSidebarLeft from './sidebarLeft/initSnippetsSidebarLeft'
import initExtraSettings from './sidebar/initExtraSettings'
import initSidebar from './sidebar/initSidebar'
import initTopbar from './topbar/initTopbar'
import initIframe from './iframe/initIframe'
import generateId from './utils/generateId'
import setContent from './setContent'
import {
  footerSystemTimeDelayUnmount,
  footerSystemAnimateUnmount,
} from './helpers/helpers'
import fontResizeEventListener from '../components/helpers/fontResizeEventListener'
import spacingEventListener from '../components/helpers/spacingEventListener'
import emptyTemplate from './empty-template'
import textSpacingEventListener from '../components/helpers/textSpacingEventListener'
import imageEventListener from '../components/helpers/imageEventListener'
import initModalForm from './modal/initModalForm'
import initModalPhoto from './modal/initModalPhoto'

export default async function (target, config) {
  let self = this
  self.element = target
  self.options = $.extend({}, DEFAULTS, config)

  self.options.locale =
    self.options.lang != 'en'
      ? self.options['locale' + self.options.lang]
      : self.options.locale

  initIframe.call(self)
  initSidebar.call(self)
  initSnippetsSidebarLeft.call(self)
  initExtraSettings.call(self)

  self.id = generateId()
  KEditor.instances[self.id] = self


  const parseData = (result) => {
    self.requestResult = result;
    let data = {}

    result.data.map((el) => {
      if (el.key) data[el.key] = el
    })
    
    return  data;
  }

  const exportAsCode = (data) => {
    const bytes = new TextEncoder().encode(JSON.stringify(data));
    const binString = Array.from(bytes, (byte) =>
       String.fromCodePoint(byte),
      ).join("");

    return btoa(binString);
  }

  
  const importFromCode = (string) => {
    const binString = atob(string);
    
    return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0)));    
  }

  const handleSuccess = (data) => {
    self.options.contentSeo =
      data[SETTINGS.SEO] && data[SETTINGS.SEO].value !== 'false'
        ? JSON.parse(data[SETTINGS.SEO].value)
        : self.options.contentSeo

    self.options.contentCss =
      data[SETTINGS.CSS] && data[SETTINGS.CSS].value !== 'false'
        ? JSON.parse(data[SETTINGS.CSS].value)
        : self.options.contentCss

    self.options.contentTracking.tracking =
      data[SETTINGS.TRACKING] && data[SETTINGS.TRACKING].value !== 'false'
        ? JSON.parse(data[SETTINGS.TRACKING].value)
        : self.options.contentTracking.tracking

    self.options.contentHTMLs.htmls =
      data[SETTINGS.BODY_CUSTOM_HTML] &&
        data[SETTINGS.BODY_CUSTOM_HTML].value !== 'false'
        ? JSON.parse(data[SETTINGS.BODY_CUSTOM_HTML].value)
        : self.options.contentHTMLs.htmls

    self.options.integrations =
      data[SETTINGS.INTEGRATION] &&
        data[SETTINGS.INTEGRATION].value !== 'false'
        ? JSON.parse(data[SETTINGS.INTEGRATION].value)
        : self.options.integrations

    self.options.contentConversions.conversions =
      data[SETTINGS.CONVERSIONS] &&
        data[SETTINGS.CONVERSIONS].value !== 'false'
        ? JSON.parse(data[SETTINGS.CONVERSIONS].value)
        : self.options.contentConversions.conversions

    self.options.contentPopup =
      data[SETTINGS.POPUP] &&
        data[SETTINGS.POPUP].value !== 'false'
        ? JSON.parse(data[SETTINGS.POPUP].value)
        : self.options.contentPopup

    if (data[SETTINGS.SCRIPTS] && data[SETTINGS.SCRIPTS].value !== 'false') {
      let parse = JSON.parse(data[SETTINGS.SCRIPTS].value)
      let tempScript = {}

      parse.forEach((el) => {
        tempScript[el.position] = el
      })

      //Maximize compatibility: If not have footer_system, push it (default).
      if (!tempScript.footer_system) {
        tempScript.footer_system = self.options.contentScripts.footer_system
      } else {
        //If have, push default content (will be populated at elements inits and timeDelayUnmount)
        tempScript.footer_system.content =
          self.options.contentScripts.footer_system.content
      }

      tempScript.footer_system.content.timeDelayArr =
        footerSystemTimeDelayUnmount(tempScript.footer_system.script)

      tempScript.footer_system.content.animateArr =
        footerSystemAnimateUnmount(tempScript.footer_system.script)

      //Maximize compatibility: If not have header_system, push it (default).
      if (!tempScript.header_system) {
        tempScript.header_system = self.options.contentScripts.header_system
      } else {
        if (tempScript.header_system.content) {
          const {
            content: { background = self.options.globalStyle.background }, //if not set, push it (default).
          } = tempScript.header_system

          const style = {
            backgroundColor: background.color || '#ffffff00',
            // backgroundImage: background.gradient.style ? bgImg : `url(${background.img.src})`,
            backgroundImage: background.img && background.img.src ? `url(${background.img.src})` : 'none',
            backgroundPosition: background.img && background.img.position || self.options.globalStyle.background.img.position,
            backgroundRepeat: background.img && background.img.repeat || self.options.globalStyle.background.img.repeat,
            backgroundSize: background.img && background.img.size || self.options.globalStyle.background.img.size,
          }
          self.options.globalStyle.background = background

          // self.iframeBody.parent().css(style)
          self.iframeBody.css(style)

          //==================================================================
          //Maximize compatibility: For legacy pages that header_system.content don't have new props

          if(!tempScript.header_system.content.timeDelayStyle){
            tempScript.header_system.content.timeDelayStyle = self.options.contentScripts.header_system.content.timeDelayStyle
          }
          if(!tempScript.header_system.content.pixelLeadOnSubmit){
            tempScript.header_system.content.pixelLeadOnSubmit = {...self.options.contentScripts.header_system.content.pixelLeadOnSubmit}
          }

        }else{ //Maximize compatibility: May have pages with header_sytem but without content property setted.
          tempScript.header_system.content = {...self.options.contentScripts.header_system.content}
          tempScript.header_system.content.background = JSON.parse(JSON.stringify(self.options.globalStyle.background)); // avoid copy nested objects by reference
          //console.log(tempScript.header_system)
        }
      }

      self.options.contentScripts = tempScript
    }

    if (
      data[SETTINGS.URL] &&
      data[SETTINGS.URL].value !== 'false' &&
      data[SETTINGS.BODY].value
    ) {
      self.options.previewURL = data[SETTINGS.URL].value
    }
    //if (result.data.final_url) self.options.previewURL = result.data.final_url
    window.addEventListener('export-as-code', () => {
      const code = exportAsCode(data);
      console.log(code)
      navigator.clipboard.writeText(code);

   })

   
    // data[SETTINGS.BODY].value &&
    setContent.call(
      self,
      data[SETTINGS.BODY].value || emptyTemplate,
      false,
      false
    )
  }

  const waitCKEDITOR = setInterval(() => {
    
    if (self.iframeWindow.window.CKEDITOR) {
      clearInterval(waitCKEDITOR);
      
      (async () => {
        await $.ajax({
          url: self.options.apiSaveContent,
          type: 'GET',
          dataType: 'json',
          headers: {
            Authorization: self.options.accessToken,
          },
          contentType: 'application/json; charset=utf-8',
          success: function (result) {
            let data = parseData(result);

            window.addEventListener('import-from-code', ({detail}) => {
              
              handleSuccess(JSON.parse(importFromCode(detail)));
           })
           handleSuccess(data);
          },
          error: function (error) {
            console.log(error)
            window.location.href = self.options.redirectUrl
          },
        })

        initTopbar.call(self)
        initModalForm({ page: this, keditor: self, form: null })
        initModalPhoto.call(this)

        const currentWidth = self.options.widthLaptop
        fontResizeEventListener(currentWidth, self)
        spacingEventListener(currentWidth, self)
        imageEventListener(currentWidth, self)
        textSpacingEventListener(currentWidth, self, 'line-height')
        textSpacingEventListener(currentWidth, self, 'letter-spacing')

        if (typeof self.options.onReady === 'function') {
          self.options.onReady.call(self)
        }
      })()
    }
  }, 100/*milli*/); // This interval give time to load CKEDITOR before be used. 
}
