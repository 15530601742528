export const SETTINGS = {
  URL: 'url',
  BODY: 'body',
  SEO: 'seo',
  CSS: 'css',
  TRACKING: 'tracking',
  SCRIPTS: 'scripts',
  BODY_CUSTOM_HTML: 'body_custom_htmls',
  INTEGRATION: 'integration',
  CONVERSIONS: 'conversion',
  POPUP: 'popup',
}
export const INTEGRATIONS_TYPE = {
  EMAIL: 'email_marketing',
}
export const SET_CONTAINER_BACKGROUND = []
export const BLUE_COLOR = '#2eb2e4'
export const EMAIL_MARKETING_INPUTS = {
  EMAIL: 'email',
  NAME: 'name',
  PHONE: 'phone',
}
export const CUSTOM_FORM_REF = {
  SWITCH_INPUT_NAME: 'switch-show-input-name',
  SWITCH_INPUT_PHONE: 'switch-show-input-phone',
}
export const STATE_TOOLBAR_SHOWED = 'showed-keditor-toolbar'
export const IN_FOCUS = 'in-focus'

export const DEFAULT_ACTION_SELECT = 'Selecionar Ação'
export const INTEGRATION_MODAL_REF = 'integration-modal'
export const POPUP_MODAL_REF = 'btn-popup-modal'
