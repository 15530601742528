import set from "lodash.set"
import { FONT_FORMAT } from "../shared/constants"

export function setObjectByKey(obj, key, value) {
    const temp = { ...obj }
    return set(temp, key, value)
}

export function handleChange(input, setContent) {
    if (!input) return

    const value =
        input.target.type === 'checkbox' ? input.target.checked : input.target.value

    setContent((prevContent) => ({
        ...prevContent,
        ...setObjectByKey(prevContent, input.target.name, value),
    }))
}

export function handleQuillEditorChange(value, key, setContent) {
    if (!value) return
    
    setContent((prevContent) => ({
        ...prevContent,
        ...setObjectByKey(prevContent, key, value),
    }))
}

export function handleChangeTrigger(input, setContent) {
    if (!input) return

    const name = input.target.name

    setContent((prevContent) => {
        const setCopy = Array.isArray(prevContent.contentPopup.trigger) ? prevContent.contentPopup.trigger : []
        let newSet;

        if (setCopy.includes(name)) {
            newSet = setCopy.filter(el => el !== name)
        } else {
            newSet = [...setCopy, name]
        }

        return ({
            ...prevContent,
            contentPopup: {
                ...prevContent.contentPopup,
                trigger: newSet,
            }
        })
    })
}

export function generateTextStyle(source) {
    return `
        ${source?.includes(FONT_FORMAT.BOLD) ? 'font-weight: bold;' : 'font-weight: 500;'}
        ${source?.includes(FONT_FORMAT.ITALIC) ? 'font-style: italic;' : ''}
        ${source?.includes(FONT_FORMAT.UNDERLINE) ? 'text-decoration: underline;' : ''}
    `
}