import CSS_CLASS from '../constants/cssClass'
import treatCommaSeparator from '../utils/treatCommaSeparator'
import { ModalBase } from './template/modalBase'


export default function () {
    let self = this

    let conversionArr = this.options.contentConversions.conversions

    let modalTabs = /*html*/ `
    <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
      <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
        <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK} active" id="first-tab" data-toggle="tab" href="#${conversionArr[0].type}" role="tab" aria-controls="first-tab" aria-selected="true">
            ${this.options.locale.modalConversions.tabs[0].tabname}
        </a>
      </li>
      <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
        <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK}" id="second-tab" data-toggle="tab" href="#${conversionArr[1].type}" role="tab" aria-controls="second-tab" aria-selected="true">
            ${this.options.locale.modalConversions.tabs[1].tabname}
        </a>
      </li>
    </ul>`
    
    let modalFooter = /*html*/ `
      <button type="button" class="btn cancel" data-dismiss="modal">
          ${this.options.locale.close}
      </button>
      <button type="button" class="btn save">
          ${this.options.locale.insertCode}
      </button>
    `

    let modal = $(
      ModalBase({
        id: 'modal-conversions',
        title: this.options.locale.modalConversions.title,
        body: convertionsBodyLayout(conversionArr, this.options.locale.modalConversions),
        footer: modalFooter,
        tabs: modalTabs,
        size: 'large',
      })
    )

    modal.find('.save').on('click', function () {
        self.options.contentConversions.conversions = parseFormToConversionsArr(modal, {
            brtype: conversionArr[0].type,
            fntype: conversionArr[1].type
        })

        if (self.options.onConvertionsInsert instanceof Function) {
            self.options.onConvertionsInsert.call(self)
        }
  
        modal.modal('hide')
    })
  
    modal.on('show.bs.modal', function () {
      if (self.options.onConvertionsShow instanceof Function) {
        self.options.onConvertionsShow.call(self)
      }
      setForm(modal, self.options.contentConversions.conversions)
    })
  
    let result = modal.appendTo(self.wrapper)

    initTogglesInput(modal, conversionArr)
  
    if (self.options.onConvertionsInit instanceof Function) {
      self.options.onConvertionsInit.call(self)
    }
  
    return result
}



const convertionOptionForm = (contentObj, localeObj) => {
    
    const backRedirectForm =  /*html*/ `
        <div class="media form-${contentObj.type}">
            <div class="media-body">
                <form class="mt-4" autocomplete="off">
                    <div class="form-group">
                        <label for="${contentObj.type}-url" style="font-size: 14px">
                            ${localeObj.config.url}
                        </label>
                        <input class="form-control" id="${contentObj.type}-url" placeholder="https://" value="${contentObj.config.url}"/>
                    </div>
                </form>
            </div>
        </div>
    `
    const fakeNotificationsForm =  /*html*/ `
        <div class="media form-${contentObj.type}">
            <div class="media-body">
                <form class="mt-4" autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="notification-names" class="col-form-label">
                                ${localeObj.config.names}
                            </label>
                            <textarea class="form-control" placeholder="${localeObj.config.namesplaceholder}" id="notification-names"name="notification-names" >${contentObj.config.names}</textarea>
                        </div>
                        <div class="form-group col">
                            <label for="notification-times" class="col-form-label">
                                ${localeObj.config.times}
                            </label>
                            <textarea class="form-control" placeholder="${localeObj.config.timesplaceholder}" id="notification-times"name="notification-times" >${contentObj.config.times}</textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="notification-event" class="col-form-label">
                                ${localeObj.config.event}
                            </label>
                            <input class="form-control" id="notification-event" placeholder="${localeObj.config.eventplaceholder}" value="${contentObj.config.event}"/>
                        </div>
                        <div class="form-group col">
                            <label for="notification-quantity" class="col-form-label">
                                ${localeObj.config.quantity}
                            </label>
                            <input class="form-control" id="notification-quantity" type="number" min="1" max="50" placeholder="4" value="${contentObj.config.quantity}"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="notification-theme" class="col-form-label">
                                ${localeObj.config.theme}
                            </label>
                            <select id="notification-theme" class="form-control">
                                <option value="light" ${contentObj.config.theme == 'light' ? 'selected' : ''}>${localeObj.config.themelight}</option>
                                <option value="dark" ${contentObj.config.theme == 'dark' ? 'selected' : ''}>${localeObj.config.themedark}</option>
                            </select>
                        </div>
                        <div class="form-group col">
                            <label for="notification-delay" class="col-form-label">
                                ${localeObj.config.delay}
                            </label>
                            <select id="notification-delay" class="form-control">
                                <option value="3" ${contentObj.config.delay == 3 ? 'selected' : ''}>3s</option>
                                <option value="6" ${contentObj.config.delay == 6 ? 'selected' : ''}>6s</option>
                                <option value="9" ${contentObj.config.delay == 9 ? 'selected' : ''}>9s</option>
                                <option value="12" ${contentObj.config.delay == 12 ? 'selected' : ''}>12s</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `
    switch (contentObj.type) {
        case 'backredirect':
            return backRedirectForm
        case 'fakenotifications':
            return fakeNotificationsForm
        default:
            return ''
    }
}

const convertionOption = (contentObj, localeObj) => {
    return /*html*/ `
    <div class="media mt-4">
        <i class="fa fa-${localeObj.icon} fz-2x mr-3"></i>
        <div class="media-body row">
            <h5 class="mt-0 col-sm-12">${localeObj.title}</h5>
            <span class="col-sm-10" style="opacity: 0.35">
                ${localeObj.description}
            </span>
            <div class="button-switch col-sm-2">
                <input type="checkbox" id="switch-blue" class="switch use-${contentObj.type}" ${contentObj.enabled ? 'checked' : ''}/>
                <label for="switch-blue" class="lbl-off"></label>
                <label for="switch-blue" class="lbl-on"></label>
            </div>
        </div>
    </div>
    ${convertionOptionForm(contentObj, localeObj)}`
}

const convertionsBodyLayout = (contentConversions, modalConversions) => {
    let finalBodyLayout = '<div class="tab-content" id="v-pills-tabContent">'
    modalConversions.tabs.forEach((convOptObj, index) => {
        finalBodyLayout += /*html*/ `<div class="tab-pane fade show ${index == 0 ? 'active' : ''}" id="${contentConversions[index].type}" role="tabpanel" aria-labelledby="${contentConversions[index].type}-tab" >`
        finalBodyLayout += convertionOption(contentConversions[index], convOptObj)
        finalBodyLayout += '</div>'
    });
    finalBodyLayout += '</div>'
    return finalBodyLayout
}

/**
 * Inicializa os Toggle do Modal. (Define o estado e seta os eventos de clique).
 * @param {jQuery} modal
 * @param {Array} conversionArr
 */
function initTogglesInput(modal, conversionArr) {
    conversionArr.forEach((convObj) => {
      if (!convObj.enabled) {
        modal.find('.form-' + convObj.type).hide()
      }
  
      modal.find('.use-' + convObj.type).on('click', function () {
        $(this).prop('checked') ? $('.form-' + convObj.type).show() : $('.form-' + convObj.type).hide()
      })
    })
}

/**
 * Retorna um array no formato do array de Conversões com os valores dos campos do modal.
 * @param {jQuery} modal
 * @param {object} types {Back Redirect, Fake Notifications}
 */
function parseFormToConversionsArr(modal, {brtype, fntype}) {
    let finalArr = []
    
    //Back Redirect Form
    finalArr.push({
        type: brtype,
        enabled: modal.find('.use-' + brtype).prop('checked'),
        config: {
          url: modal.find('#' + brtype + '-url').val(),
        }
    })

    //Fake Notification Form
    finalArr.push({
        type: fntype,
        enabled: modal.find('.use-' + fntype).prop('checked'),
        config: {
          names: treatCommaSeparator(modal.find('#notification-names').val()),
          times: treatCommaSeparator(modal.find('#notification-times').val()),
          event: modal.find('#notification-event').val(),
          quantity: modal.find('#notification-quantity').val(),
          delay: modal.find('#notification-delay').val(),
          theme: modal.find('#notification-theme').val()
        }
    })
    
    return finalArr
}

/**
 * Preenche os campos do modal com os valores do array de Conversões.
 * @param {jQuery} modal
 * @param {Array} conversionArr 
 */
function setForm(modal, conversionArr) {
    //Back Redirect Form
    modal.find('.use-' + conversionArr[0].type).prop('checked', conversionArr[0].enabled ? true : false)
    modal.find('#' + conversionArr[0].type + '-url').val(conversionArr[0].config.url)
    //Fake Notification Form
    modal.find('.use-' + conversionArr[1].type).prop('checked', conversionArr[1].enabled ? true : false)
    modal.find('#notification-names').val(conversionArr[1].config.names)
    modal.find('#notification-times').val(conversionArr[1].config.times)
    modal.find('#notification-event').val(conversionArr[1].config.event)
    modal.find('#notification-quantity').val(conversionArr[1].config.quantity)
    modal.find('#notification-delay').val(conversionArr[1].config.delay)
    modal.find('#notification-theme').val(conversionArr[1].config.theme)

    //Esconde ou mostra os campos de acordo com enabled
    modal.find('.form-' + conversionArr[0].type)[conversionArr[0].enabled ? 'show' : 'hide']()
    modal.find('.form-' + conversionArr[1].type)[conversionArr[1].enabled ? 'show' : 'hide']()
}

//construir forms (ok)
//carrega na modal (ok)
//salva na config (ok)
//envia pro servidor
//carrega do servidor