import React from 'react'
import { INTEGRATIONS_PANEL_VIEW } from '../../constants'
import { useIntegration } from '../../context/integrationContext'
import { RedirectURL } from '../RedirectURL'
import { IntegrationCard } from './IntegrationCard'
import './styles.css'
import { PixelLeadOnSubmit } from '../PixelLeadOnSubmit/PixelLeadOnSubmit'

export const IntegrationsList = () => {
  const { integrations, setPanelView, redirectURL } = useIntegration()
  const { data: list } = integrations ?? {}

  const addIntegration = () => {
    setPanelView(INTEGRATIONS_PANEL_VIEW.ADD)
  }

  return (
    <>
      <div className="modal-body modalBodyWithScroll">
        <RedirectURL />
        <PixelLeadOnSubmit />
        <p className="text-left">Lista de integrações</p>
        <div className="row row-cols-2 mt-4">
          <div className="col-5 p-0">
            <div className="col mb-4">
              <button
                type="button"
                className="btnAddIntegration text-uppercase"
                onClick={addIntegration}
                disabled={!redirectURL}
              >
                Adicionar Integração
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {list?.map((integration, index) => (
            <div
              className="col-12 mb-4"
              key={`integration_${integration.type}_${index}`}
            >
              <IntegrationCard id={index} {...integration} />
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <div className="buttons">
          <button type="button" className="btn cancel" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </>
  )
}
