import CSS_CLASS from '../constants/cssClass.js'
import { ModalBase } from './template/modalBase.js'

export default function () {
  let self = this

  let trackingArr = this.options.contentTracking.tracking

  let modalFooter = /*html*/ `
    <button type="button" class="btn save" id="pixel-save">Inserir Pixels</button>
  `
  let modal = $(
    ModalBase({
      id: 'tracking-code',
      title: "PIXELS <i class='fa fa-bar-chart ml-1'></i>",
      body: pixelsLayout(trackingArr, this.options.locale.modalTracking),
      footer: modalFooter,
    })
  )

  modal.find('#pixel-save').on('click', function () {
    self.options.contentTracking.tracking = getPixelValues(modal, self.options.contentTracking.tracking)

    if (self.options.onPixelInsert && self.options.onPixelInsert instanceof Function) {
      self.options.onPixelInsert.call(self)
    }

    modal.modal('hide')
  })

  modal.on('show.bs.modal', function () {
    setPixelValues($(this), self.options.contentTracking.tracking)
  })

  let result = modal.appendTo(self.wrapper)

  initPixelsCheckInput(modal, trackingArr)

  new Tagify(document.querySelector("#fb-pixel"), {
    originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
  })


  const checkForFbPixel = (pixels) =>
  {
    //Display if the input has a value
    if (pixels.length > 0)
    {
      document.querySelector("#fb-tokens").classList.remove('d-none')
    }
    else
    {
      document.querySelector("#fb-tokens").classList.add('d-none')
      document.querySelector("#fb-pixel-tokens").value = ''
    }
  };

  document.querySelector("#fb-pixel").addEventListener('change', function (e)
  {
    //Display if the input has a value
    checkForFbPixel(e.target.value);
  })

  checkForFbPixel(document.querySelector("#fb-pixel").value);

  new Tagify(document.querySelector("#fb-pixel-events"), {
    originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
    whitelist : ['ViewContent','Lead','AddToCart','Purchase'],
    dropdown : {
      classname : "tagify-dropdown-custom-class",
      enabled : 0, 
      position : "text",
      closeOnSelect : false,
    }
  })

  new Tagify(document.querySelector("#fb-pixel-tokens"), {
    originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
  })


  return result
}

/**
 * Retorna um array no formado do Tracking com os valores dos campos do modal já preenchidos.
 * @param {jQuery} modal
 * @param {Array} trackingArr
 */
function getPixelValues(modal, trackingArr) {
  let finalTrackingArr = []

  trackingArr.forEach((trackingObj) => {
    //acho q da pra melhorar/reduzir esse codigo via map
    if (trackingObj.type === 'fb') {
      finalTrackingArr.push({
        type: trackingObj.type,
        enabled: modal.find('.use-' + trackingObj.type).prop('checked'),
        value: modal.find('#' + trackingObj.type + '-pixel').val(),
        extraEvents: modal.find('#' + trackingObj.type + '-pixel-events').val(),
        token: modal.find('#' + trackingObj.type + '-pixel-tokens').val(),
      })
    }else{
      finalTrackingArr.push({
        type: trackingObj.type,
        enabled: modal.find('.use-' + trackingObj.type).prop('checked'),
        value: modal.find('#' + trackingObj.type + '-pixel').val(),
      })
    }
  })
  return finalTrackingArr
}

/**
 * Recebe um array no formado do Tracking e seta os valores dos campos do modal.
 * @param {jQuery} modal
 * @param {Array} trackingArr
 */
function setPixelValues(modal, trackingArr) {

  trackingArr.forEach((trackingObj) => {
    //Seta o valor dos campos
    modal.find('#' + trackingObj.type + '-pixel').val(trackingObj.value)

    //Esconde ou mostra os campos de acordo com enabled
    modal.find('.form-' + trackingObj.type)[trackingObj.enabled ? 'show' : 'hide']()

    //Seta o status do toggle input (chave) de acordo com enabled
    modal.find('.use-' + trackingObj.type).prop('checked', trackingObj.enabled ? true : false)

    if (trackingObj.type === 'fb') {
      modal.find('#' + trackingObj.type + '-pixel-events').val(trackingObj.extraEvents)
      modal.find('#' + trackingObj.type + '-pixel-tokens').val(trackingObj.token)
    }
  })
}

/**
 * Inicializa os Inputs de Check do Modal. (Define o estado e seta os eventos de clique).
 * @param {jQuery} modal
 * @param {Array} trackingArr
 */
function initPixelsCheckInput(modal, trackingArr) {
  trackingArr.forEach((trackingObj) => {
    if (!trackingObj.enabled) {
      modal.find('.form-' + trackingObj.type).hide()
    }

    modal.find('.use-' + trackingObj.type).on('click', function () {
      $(this).prop('checked') ? $('.form-' + trackingObj.type).show() : $('.form-' + trackingObj.type).hide()
    })
  })
}

/**
 * Retorna o Layout em HTML do formulário dos pixels no idioma passado.
 * @param {Array} trackingArr
 * @param {Object} locale | objeto que guarda os textos do idioma.
 */
const pixelsLayout = (trackingArr, locale) => {
  let finalLayout = ''
  trackingArr.forEach((trackingObj) => {
    finalLayout += pixel(trackingObj.type, trackingObj.enabled, trackingObj.value, locale,
      trackingObj.extraEvents ? trackingObj.extraEvents : '', trackingObj.token ? trackingObj.token : '')
    finalLayout += '<hr/>'
  })
  return finalLayout.slice(0, -5) //remove o último hr
}

const pixel = (tag, checked, value, locale, extraEvents = '', token = '') => {
  if (!locale?.[tag]) return '';

  const fbDisclaimer = /*html*/ `
    <p class="text-muted mt-2" style="font-size: 13px !important">
      ${locale.fb.disclaimer}
    </p>
    `

  const fbExtraEvents = /*html*/ `
  <div class="form-group mt-3">
    <label for="${tag}-pixel-events" style="font-size: 14px">
      ${locale[tag].events?.fillwith}
    </label>
    <input class="form-control"
      id="${tag}-pixel-events"
      placeholder="${locale[tag].events?.placeholder}"
      value="${extraEvents}"
    />
    <p class="text-muted mt-2" style="font-size: 13px !important">
      ${locale.fb.events.disclaimer}
    </p>
  </div>
  `

  const fbTokens = /*html*/ `
  <div class="form-group mt-3 d-none" id="fb-tokens">
    <label for="${tag}-pixel-tokens" style="font-size: 14px">
      ${locale[tag].tokens?.fillwith}
    </label>
    <input class="form-control"
      id="${tag}-pixel-tokens"
      placeholder="${locale[tag].tokens?.placeholder}"
      value="${token}"
    />
    <p class="text-muted mt-2" style="font-size: 13px !important">
      ${locale.fb.tokens?.disclaimer}
    </p>
  </div>
  `

  const form = /*html*/ `<div class="media form-${tag}">
      <div class="media-body">
        <form class="mt-4" autocomplete="off">
          <div class="form-group">
            <label for="${tag}-pixel"style="font-size: 14px">
                ${locale[tag].fillwith}
            </label>
            <input
              class="form-control" 
              id="${tag}-pixel"
              placeholder="${locale[tag].placeholder}"
              value="${value}"
              ${tag === 'fb' ? 'pattern=\'^\\d+$\'' : ''}
            />
            ${tag === 'fb' ? fbDisclaimer : ''}
          </div>
          ${tag === 'fb' ? fbExtraEvents : ''}
          ${tag === 'fb' ? fbTokens : ''}
        </form>
      </div>
    </div>
    `

  const pixel = /*html*/ `
    <div class="media mt-4">
      <i class="fa fa-${locale[tag].icon} fz-2x mr-3"></i>
      <div class="media-body row">
        <h5 class="mt-0 col-sm-12">${locale[tag].title}</h5>
        <span class="col-sm-10" style="opacity: 0.35">
            ${locale[tag].subtitle}
        </span>
        <div class="button-switch col-sm-2">
          <input 
            type="checkbox" 
            id="switch-blue" 
            class="switch use-${tag}" 
            ${checked ? 'checked' : ''}
          />
          <label for="switch-blue" class="lbl-off"></label>
          <label for="switch-blue" class="lbl-on"></label>
        </div>
      </div>
    </div>
    ${form}`

  return pixel
}
