const useBorderStyle = ({
  form,
  keditor,
  component,
  label,
  customFormRef,
  elementRef,
  customOptions,
  fullWidth,
  isFirst,
}) => {
  const formRef = customFormRef || `border-style-editor`
  const element = form && form.find(`#${formRef}`)
  const options = customOptions || [
    // {
    //   name: 'Nenhum',
    //   value: 'none',
    // },
    {
      name: 'Sólido',
      value: 'solid',
    },
    {
      name: 'Tracejado',
      value: 'dashed',
    },
    {
      name: 'Pontilhado',
      value: 'dotted',
    },
  ]

  const init = () => {
    form.find(`#${formRef}`).on('change', (event) => {
      elementRef().css('border-style', event.target.value)
    })
  }

  const show = () => {
    const borderValue = elementRef().css('border-style')
    const option = options.find((el) => el.value === borderValue || el.name === borderValue)

    form.find(`#${formRef}`).val(option ? option.value || option.name : 'solid')
  }

  const renderOptions = () => {
    let acc = ''

    options.forEach((element) => {
      acc += `<option value="${element.value || element.name}">${element.name}</option>`
    })

    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `

  return {
    componentName: 'borderStyle',
    init,
    show,
    template,
  }
}

export default useBorderStyle
