import { clearButton } from './helpers.templates'

const FORM_BG_COLOR = /*html*/ `
<div class="form-custom" style="margin: 1rem 0rem;">
  <div class="row">
    ${clearButton('color')}
    <div class="col-sm-12 mt-2">
      <label class="col-sm-12" for="background">
        Cor do fundo
      </label>
      <div class="input-group col-sm-12">
        <input data-id="#background" class="color-picker-component form-control" style="width: 100%" />
      </div>
    </div>
  </div>
</div>
`

export { FORM_BG_COLOR }
