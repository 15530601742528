import React from 'react'
import ReactDOM from 'react-dom'
import { Button } from './components'
import { PopupConfig } from './components/PopupConfig'
import { PopupProvider } from './context/popupContext'
import { ELEMENT_ID } from './shared/constants'

export const PopupContainer = (self, target) => {
  const { button, ...rest } = self.options.locale.modalPopup
  const initialState = {contentPopup: {...self.options.contentPopup}, modalPopup: {...self.options.locale.modalPopup}, defaultsRef: self.options}
  const contentScripts = self.options.contentScripts

  ReactDOM.render(<Button label={button} />, document.getElementById(target))

  self.wrapper.append(`<div id="${ELEMENT_ID}"></div>`)
  ReactDOM.render(
    <>
      <PopupProvider initialState={initialState}>
        <PopupConfig
          self={self}
          //temp
          contentScripts={contentScripts}
        />
      </PopupProvider>
    </>,
    document.getElementById(ELEMENT_ID)
  )
}
