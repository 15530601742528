import CSS_CLASS from '../constants/cssClass';
// import log from '../utils/log';

export default function () {
    let self = this;
    let options = self.options;
    let sidebarLeft = self.sidebarLeft;//self.modal;
    let categoriesOptionsArray = [];
    
   
//log('snippetCategories.navItem[0]', options.locale.snippetCategories["navItem"+0]);
    sidebarLeft.find(`.${CSS_CLASS.BOOTSTRAP_TAB_PANE}`).each(function (index){
        categoriesOptionsArray.push(`<option value="" selected="selected">${options.locale.snippetCategoryAll}</option>`);
        $.each(options.locale.snippetCategories["navItem"+index], function (i, category) {
            categoriesOptionsArray[index] += `<option value="${options.sidebarLeft.nav.items[index].snippetCategories[i]}" class="${CSS_CLASS.SNIPPETS_FILTER_OPTION}">${category}</option>`;
        });
    });

    return categoriesOptionsArray;
};
