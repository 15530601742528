const useBorderWidth = ({ form, keditor, component, label, customFormRef, elementRef, customOptions, isFirst }) => {
  const formRef = customFormRef || `border-width-editor`
  const element = form && form.find(`#${formRef}`)
  const options = customOptions || [
    {
      name: 'Nenhuma',
      value: '0px',
    },
    {
      name: '2px',
    },
    {
      name: '3px',
    },
    {
      name: '4px',
    },
    {
      name: '6px',
    },
  ]

  const init = () => {
    form.find(`#${formRef}`).on('change', (event) => {
      elementRef().css('border-style', elementRef().css('border-style') !== 'none' ? elementRef().css('border-style') : 'solid')
      elementRef().css('border-width', event.target.value)
    })
  }

  const show = () => {
    const borderValue = elementRef().css('border-width')
    const borderRoundedValue = Math.round(borderValue.slice(0,-2)) + 'px';
    const option = options.find((el) => el.value === borderRoundedValue || el.name === borderRoundedValue)

    form.find(`#${formRef}`).val(option ? option.value || option.name : '0px')
  }

  const renderOptions = () => {
    let acc = ''

    options.forEach((element) => {
      acc += `<option value="${element.value || element.name}">${element.name}</option>`
    })

    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-6 ${!isFirst && 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `

  return {
    componentName: 'borderWidth',
    init,
    show,
    template,
  }
}

export default useBorderWidth
