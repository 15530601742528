import CSS_CLASS from "../constants/cssClass";
import closeSidebar from "../sidebar/closeSidebar";
import setCopyContent from "../utils/setCopyContent";

export default function () {
	let self = this;
	let contentAreasWrapper = self.contentAreasWrapper;

	contentAreasWrapper.on("click", `.${CSS_CLASS.COMPONENT_COPY}`, function (e) {
		$(this)
			.parent()
			.find(`.${CSS_CLASS.COMPONENT_CANCEL}`)
			.attr("style", "display: inline !important;");
		e.preventDefault();
		let btn = $(this);
		btn.hide();
		let component = btn.closest(`.${CSS_CLASS.COMPONENT}`);
		setCopyContent.call(self, component, false);

		setTimeout(() => {
			closeSidebar.call(self);
		}, 50);
	});

	contentAreasWrapper.on("click", `.${CSS_CLASS.COMPONENT_CANCEL}`, function (
		e
	) {
		e.preventDefault();
		$(this).hide();

		$(this)
			.parent()
			.find(`.${CSS_CLASS.COMPONENT_COPY}`)
			.attr("style", "display: inline !important;");

		setCopyContent.call(self, null, false);

		setTimeout(() => {
			closeSidebar.call(self);
		}, 50);
	});
}
