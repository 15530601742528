import CSS_CLASS from '../constants/cssClass'
import getContent from '../getContent'
import ICON from '../constants/icon'

export default function () {
  let self = this
  let options = self.options
  let btnSave = (self.btnSave = $(/*html*/ `<button 
      type="button" 
      title="${options.locale.save}" 
      class="btn-top-bar"
      id="btn-content-save"
    >
      <img 
        style="margin-right: 8px"
        src="bundle/assets/img/save.svg"
      /> ${options.locale.save}
    </button>`))

  btnSave.on('click', function (e) {
    e.preventDefault()
    $('.unsaved').hide()

    let content = getContent.call(self)

    options.onSave.call(self, content)
  })

  let warningUnsave = $(/*html*/ `
    <div 
      class="mx-2" 
      data-toggle="tooltip" 
      data-placement="bottom" 
      title="${options.locale.contentUnsaved}" 
      style="min-width: 20px"
    >
      <i class="fa fa-exclamation-triangle fz-3x unsaved" style="color: orange; "></i>
    </div>`)

  self.topbarRight.append(warningUnsave)
  self.topbarRight.append(btnSave)
}
