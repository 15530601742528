export const verifyHasClass = (component, arrayClass = []) => {
  let value = ''
  arrayClass.map((curretClass) => {
    if (component.hasClass(curretClass)) {
      value = curretClass
    }
  })
  return value
}

export const changeOneClass = (element, term, value) => {
  let classList = element.attr('class').split(/\s+/)
  let indexToChange = null
  $.each(classList, function (index, item) {
    if (item.search(new RegExp(term, 'i')) !== -1) {
      indexToChange = index
    }
  })
  classList[indexToChange] = value
  return classList.toString().replace(/,/g, ' ')
}

export const applySpacing = (element, target, style, form) => {
  try {
    const prevValues = target.css(style).split(' ')
    const space = setSpaceStructure(prevValues)
    const value = parseInt(Object.assign(element).value)
    const direction = $(element).attr('id')

    if (direction === 'all') {
      space.top = value
      space.bottom = value
      space.left = value
      space.right = value
      const selector = style === 'padding' ? '.p-element' : '.m-element'
      const inputElements = form.find(selector)
      inputElements.val(value)
    } else {
      const selector = style === 'padding' ? '#all.p-element' : '#all.m-element'
      const inputElement = form.find(selector)
      inputElement.val(0)
      space[direction] = value
    }
    const parseStyle = `${space.top}px ${space.right}px ${space.bottom}px ${space.left}px`
    target.css(style, parseStyle)
  } catch (error) {
    alert('Não existe elemento para executar esta ação')
  }
}

const setSpaceStructure = (prevValues) => {
  const space = {}
  switch (prevValues.length) {
    case 1:
      space.all = parseInt(prevValues[0])
      space.top = parseInt(prevValues[0])
      space.right = parseInt(prevValues[0])
      space.bottom = parseInt(prevValues[0])
      space.left = parseInt(prevValues[0])
      break
    case 2:
      space.all = 0
      space.top = parseInt(prevValues[0])
      space.right = parseInt(prevValues[1])
      space.bottom = parseInt(prevValues[0])
      space.left = parseInt(prevValues[1])
      break

    case 3:
      space.all = 0
      space.top = parseInt(prevValues[0])
      space.right = parseInt(prevValues[1])
      space.bottom = parseInt(prevValues[2])
      space.left = parseInt(prevValues[1])
      break

    default:
      space.all = 0
      space.top = parseInt(prevValues[0])
      space.right = parseInt(prevValues[1]) || 0
      space.bottom = parseInt(prevValues[2]) || 0
      space.left = parseInt(prevValues[3]) || 0
      break
  }
  return space
}

export const setSpaceInputValues = (direction, target, element, form) => {
  if (typeof element !== 'object' || typeof element.css !== 'function') throw Error('You should pass a valid element object setSpaceInputValues')
  const directionValues = element.css(direction)
  if (!directionValues) throw Error('You should pass a valid direction for setSpaceInputValues')

  const prevValues = directionValues.split(' ')
  const space = setSpaceStructure(prevValues)
  Object.keys(space).forEach((direction) => {
    const inputElement = form.find(`#${direction}.${target}`)
    inputElement.val(space[direction], 10)
  })
}

export const setBackgroundColor = (target, style, newValue) => {
  const prevValues = target.css(style[1]) != 'none' ? target.css(style[1]) : target.css(style[0])
  let newStyle = {
    style: 'backgroundColor',
    color: `${newValue}`,
    display: newValue ? newValue : prevValues,
  }
  if (prevValues.indexOf('linear') != -1) {
    const prevDisplay = prevValues.split('linear-gradient(')[1].split(',')
    const display = newValue ? newValue : `${prevDisplay[0]},${prevDisplay[1]},${prevDisplay[2].split(')')[0]})`
    newStyle = {
      style: 'backgroundImage',
      color: newValue ? `linear-gradient( ${newValue} 55%, #f9f9f9 45%)` : prevValues,
      display: display,
    }
  }

  return newStyle
}

export const setBorderStruct = (prevValues, type, newValue) => {
  const newBorder = {}

  switch (type) {
    case 'size':
      newBorder.size = parseInt(newValue)
      newBorder.style = prevValues[1]
      newBorder.color = prevValues[2]
      break
    case 'style':
      newBorder.size = parseInt(prevValues[0])
      newBorder.style = newValue
      newBorder.color = prevValues[2]
      break
    case 'color':
      newBorder.size = parseInt(prevValues[0])
      newBorder.style = prevValues[1]
      newBorder.color = newValue
      break
    default:
      newBorder.size = parseInt(prevValues[0])
      newBorder.style = prevValues[1]
      newBorder.color = prevValues[2]
  }

  return newBorder
}

export const applyBorder = (element, target, style, form, type, newValue) => {
  const prevValues = target.css(style).split(' ')
  const prevColor = prevValues[2] + prevValues[3] + prevValues[4]
  const newBorder = setBorderStruct([prevValues[0], prevValues[1], prevColor], type, newValue)

  return newBorder
}

export const textOptions = (isList = false, buttons = '') => {
  const removeButtons = isList ? 'FontSize,Format' + ',' + buttons : ''
  const options = {
    toolbarGroups: [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing'],
      },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      // {
      //   name: 'paragraph',
      //   groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
      // },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      '/',
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
    ],
    title: false,
    allowedContent: true, // DISABLES Advanced Content Filter. This is so templates with classes: allowed through
    bodyId: 'editor',
    templates_replaceContent: false,
    enterMode: 1,
    format_tags: 'p;h1;h2;h3;h4;h5;h6',
    removePlugins: 'table,magicline,tableselection,tabletools',
    removeButtons: `Save,NewPage,Preview,Print,Templates,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,HiddenField,ImageButton,Button,Select,Textarea,TextField,Radio,Checkbox,Outdent,Indent,Blockquote,CreateDiv,Language,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,About,ShowBlocks,BidiLtr,BidiRtl,Flash,Image,Subscript,Superscript,Anchor,CopyFormatting,${removeButtons}`,
    minimumChangeMilliseconds: 100,
    floatSpaceDockedOffsetY: 10,
    // uiColor: '#292929',
  }

  return options
}

export const textEditor = (
  target,
  element,
  keditor,
  options,
  contentArea,
  container,
  notEditableTarget = '',
  isList = false,
  buttons = ''
) => {
  let componentText = element.find(target)
  componentText.prop('contenteditable', true)
  if (notEditableTarget != '') componentText.find(notEditableTarget).prop('contenteditable', false)

  componentText.on('input', function (e) {
    if (typeof options.onComponentChanged === 'function') {
      options.onComponentChanged.call(keditor, e, element)
    }
    if (typeof options.onContainerChanged === 'function') {
      options.onContainerChanged.call(keditor, e, container, contentArea)
    }
    if (typeof options.onContentChanged === 'function') {
      options.onContentChanged.call(keditor, e, contentArea)
    }
  })

  try {
    // let editor = CKEDITOR.inline(componentText[0], self.options);
    const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    let editor = CKEDITOR.inline(componentText[0], textOptions(isList, buttons))

    editor.on('instanceReady', function (e) {
      $('#cke_' + componentText.attr('id')).appendTo(keditor.wrapper)
      if (typeof options.onComponentReady === 'function') {
        options.onComponentReady.call(contentArea, element, editor)
      }
    })
  } catch (error) {
    console.warn(error)
  }
}

export const initContentAreaTemplate = () => {
  return `
  <div class="content-area-inner-template empty-page" style="text-align: center; margin: 16px; font-style: italic;color: gainsboro;">
    <h5>
      Aperte no botão abaixo para incluir um novo elemento ou bloco em sua página
    </h5>
  </div>
  `
}

export const initContentAreaState = (contentArea) => {
  const areaInner = contentArea.find('.keditor-content-area-inner')
  const parent = areaInner.parent()
  const children = areaInner.children()
  const template = contentArea.find('.content-area-inner-template')

  if (children.length === 0) {
    parent.find('.btn-add-content').addClass('isEmpty')
    return contentArea.prepend(initContentAreaTemplate())
  }
  parent.find('.btn-add-content').removeClass('isEmpty')
  template.remove()
}

export const toggleEmptyContainers = (contentArea) => {
  var containers = $(contentArea).find('.keditor-container-content-inner')
  for (let index = 0; index < containers.length; index++) {
    const element = $(containers[index]);

    if (element.children().length === 0) {
      element.css('min-height', '100px')
      element.css('background-color', '#d9d9d9')
      const toolbar = element.parent().find('.keditor-toolbar-container-content')
      toolbar.css({
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bottom: 'auto',
        position: 'absolute',
      })
    } else {
      element.css('background-color', '')
      element.css('min-height', 'auto')
      const toolbar = element.parent().find('.keditor-toolbar-container-content')
      toolbar.css({
        top: '',
        left: '',
        transform: '',
        bottom: '',
        position: '',
      })
    }
  }
}

export const componentSpacing = (target, form, component, keditor, type) => {
  /**
   * type 1 => quando estiver carregando o componente
   * type 2 => quando estiver editando o componente
   */
  if (type == 1) {
    setSpaceInputValues('margin', 'm-element', target, form)
    setSpaceInputValues('padding', 'p-element', target, form)
    let customClass = form.find('.custom-class')
    customClass.val(target.attr('class'))
    let keditorID = form.find('.keditor-id')
    keditorID.val(component.attr('id'))
  } else if (type == 2) {
    //Padding
    let pElement = form.find('.p-element')
    pElement.on('change', function () {
      let divisor = keditor.getSettingComponent().find(target)
      if (target === '.keditor-component-content') {
        let componentContent = keditor.getSettingComponent().find(target).children().children()
        divisor = componentContent
      }

      applySpacing(this, divisor, 'padding', form)
    })

    //Margin
    let mElement = form.find('.m-element')
    mElement.on('change', function () {
      let divisor = keditor.getSettingComponent().find(target)
      if (target === '.keditor-component-content') {
        let componentContent = keditor.getSettingComponent().find(target).children().children()
        divisor = componentContent
      }
      applySpacing(this, divisor, 'margin', form)
    })

    let customClass = form.find('.custom-class')
    customClass.on('change', function () {
      let divisor = keditor.getSettingComponent().find(target)
      if (target === '.keditor-component-content') {
        let componentContent = keditor.getSettingComponent().find(target).children().children()
        divisor = componentContent
      }
      divisor.attr('class', this.value)
    })
  }
}

export const mobileFontSize = (target, limit, form, component, keditor, type, customClass = '.mobile-font-size') => {
  /**
   * type 1 => quando estiver carregando o componente
   * type 2 => quando estiver editando o componente
   */
  let option = []
  for (let i = 12; i <= 42; i++) {
    option.push(`font-${i}`)
  }

  let mobileFont = form.find(customClass)
  if (type == 1) {
    // if (!verifyHasClass(target, option)) {
    //   target.addClass("font-16");
    // }
    // console.log(component.find(`.${option[0]},.${option.slice(1).join(",.")}`));
    const value = verifyHasClass(target, option) || `font-16`

    mobileFont.val(value)
  } else if (type == 2) {
    mobileFont.on('change', function () {
      const componentContent = keditor.getSettingComponent().find('.keditor-component-content').children().children()
      const content = componentContent.find('span,h1,h2,h3,h4,h5,h6,p,small')
      let contentTarget = ''
      if (target !== '.keditor-component-content') {
        const auxContentTarget = (contentTarget = keditor.getSettingComponent().find(target))
        if ('.item-title.button-style'.indexOf(target) != -1) {
          contentTarget = auxContentTarget
        } else contentTarget = auxContentTarget.find('span,h1,h2,h3,h4,h5,h6,p,small')
      }

      let element = target === '.keditor-component-content' ? content : contentTarget //keditor.getSettingComponent().find(target);

      element.removeClass(option.join(' '))
      element.addClass(this.value)
    })
  }
}

export const applyAlignment = (target, form, component, keditor, type) => {
  const TEMPLATE = `
  <label for="element-align" class="mt-3">Alinhamento</label>
  <select id="element-align" class="form-control">
    <option value="start">Esquerda</option>
    <option value="center">Centro</option>
    <option value="end">Direita</option>
  </select>`

  const classes = 'justify-content-start justify-content-center justify-content-end'

  function setAlign(target, form, keditor) {
    form.find('#element-align').on('change', function () {
      const component = keditor.getSettingComponent().find(target)
      component.removeClass(classes)
      component.addClass(`justify-content-${this.value}`)
    })
  }

  function getAlign(target, form, component) {
    const value = verifyHasClass(component.find(target), classes.split(' '))

    form.find('#element-align').val(value.split('justify-content-'))
  }

  switch (type) {
    case 1:
      setAlign(target, form, keditor)
      break
    case 2:
      getAlign(target, form, component)
      break

    default:
      return TEMPLATE
      break
  }
}
