export default function (form) {
  const labels = {
    name: 'Nome',
    // email: 'E-mail',
    phone: 'Celular',
  }

  let inputShows = ''
  ;['name', 'phone'].forEach((el) => {
    inputShows += /*html*/ `
      <div class="show-input" data-type="${el}">
        <input 
          type="checkbox" 
          id="${el}-shows"
        />
        <span class="checkmark"></span>
        <div>
          ${labels[el]}
        </div>
      </div>
      `
  })

  return /*html*/ `
    <h5>
      Selecione o que deseja incluir em seu formulário:
    </h5>
    <div class="shows-config">
      ${inputShows}
    </div>

    <hr />
    <div id="form-config">
    </div>
    
    <div id="empty-content">
      Formulário vazio
    </div>
  `
}
