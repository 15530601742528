import CSS_CLASS from '../constants/cssClass';
import hideSidebarLeft from './hideSidebarLeft';

export default function () {
    let self = this;
    let sidebarLeft = self.sidebarLeft;//self.modal;

    if(!sidebarLeft.hasClass(CSS_CLASS.STATE_OPENED)) return

    
    self.sidebarLeftTarget = null;
    self.sidebarLeftTargetAction = null;
    sidebarLeft.find(`.${CSS_CLASS.STATE_SELECTED}`).removeClass(CSS_CLASS.STATE_SELECTED);
    sidebarLeft.find(`.${CSS_CLASS.STATE_NOT_MATCHED}`).removeClass(CSS_CLASS.STATE_NOT_MATCHED);
    sidebarLeft.find(`.${CSS_CLASS.SNIPPETS_FILTER}`).val('');
    sidebarLeft.removeClass(CSS_CLASS.MODAL_COMPONENT);
    sidebarLeft.removeClass(CSS_CLASS.MODAL_CONTAINER);
    
    hideSidebarLeft.call(self, sidebarLeft);
};
