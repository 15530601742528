import React from 'react'
import { BTN_INTEGRATION_MODAL_REF } from '../../constants'
import './styles.css'

export const Button = () => (
  <>
    <div className="dropdown-divider"></div>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      className="dropdown-item d-flex justify-content-between align-items-center my-1"
      href="#"
      data-toggle="modal"
      data-target={`#${BTN_INTEGRATION_MODAL_REF}`}
      data-type="integration"
    >
      INTEGRAÇÕES
      <i className="fa fa-share-alt-square ml-1"></i>
    </a>
  </>
)
