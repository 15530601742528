import React from 'react'
import { usePopup } from '../../context/popupContext'
import { Switch } from '../Switch'
import { handleChange, handleChangeTrigger } from '../../utils'

export const General = () => {
    const {
        state: { modalPopup, contentPopup },
        setContent,
    } = usePopup()

    return (
        <>
            <div className="media mt-4 col">
                <div className="media-body d-flex justify-content-between">
                    <div style={{ flex: 1 }}>
                        <h5 className="mt-0 font-weight-normal">
                            {modalPopup.tabs.general.config.status.label}
                        </h5>
                        <span className="" style={{ opacity: '0.35', fontSize: '14px' }}>
                            {modalPopup.tabs.general.config.status.description}
                        </span>
                    </div>
                    <Switch
                        name="contentPopup.status"
                        checked={contentPopup.status}
                        onChange={(e) => handleChange(e, setContent)}
                    />
                </div>
            </div>
            <hr />
            <div className="form-group">
                <div className='col'>
                    <label className="col-form-label">
                        {modalPopup.tabs.general.config.trigger.label}
                    </label>
                    <div className="col-form-label pt-0" style={{ opacity: '0.35', fontSize: '14px' }}>
                        <small>
                            {modalPopup.tabs.general.config.status.footer}
                        </small>
                    </div>
                </div>
                {/*<div className='col'>                    
                     <div className="d-flex justify-content-between">
                        <div style={{flex: 1}}>
                            <h5 className="mt-0 font-weight-normal">
                                {modalPopup.tabs.general.config.trigger.options.loadPage.label}
                            </h5>
                            <span className="" style={{ opacity: '0.35', fontSize: '14px' }}>
                                {modalPopup.tabs.general.config.trigger.options.loadPage.description}
                            </span>
                        </div>
                        <Switch
                            name={modalPopup.tabs.general.config.trigger.options.loadPage.value}
                            checked={Array.isArray(contentPopup.trigger) && contentPopup.trigger.includes(modalPopup.tabs.general.config.trigger.options.loadPage.value)}
                            onChange={(e) => handleChangeTrigger(e, setContent)}
                        />
                    </div> 
                </div>*/}
                <div className='col mt-4'>
                    <div className="d-flex justify-content-between">
                        <div style={{ flex: 1 }}>
                            <h5 className="mt-0 font-weight-normal">
                                {modalPopup.tabs.general.config.trigger.options.closePage.label}
                            </h5>
                            <span className="" style={{ opacity: '0.35', fontSize: '14px' }}>
                                {modalPopup.tabs.general.config.trigger.options.closePage.description}
                            </span>
                        </div>
                        <Switch
                            name={modalPopup.tabs.general.config.trigger.options.closePage.value}
                            checked={Array.isArray(contentPopup.trigger) && contentPopup.trigger.includes(modalPopup.tabs.general.config.trigger.options.closePage.value)}
                            onChange={(e) => handleChangeTrigger(e, setContent)}
                        />
                    </div>
                </div>


                {/* <div className="col">
                    <select
                        className="custom-select"
                        name="contentPopup.trigger"
                        value={contentPopup.trigger}
                        onChange={(e) => handleChange(e, setContent)}
                    >
                        <option value="load-page">Ao carregar a página</option>
                        <option value="close-page">Ao sair da página</option>
                    </select>
                </div> */}
            </div>
            <hr />
        </>
    )
}