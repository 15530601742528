import initPixelModal from '../modal/initPixelModal'
import initCssModal from '../modal/initCssModal'
import initCustomCodeModal from '../modal/initCustomCodeModal'
import initSeoModal from '../modal/initSeoModal'
import initConversionsModal from '../modal/initConversionsModal'
import initGlobalStyleModal from '../modal/initGlobalStyleModal'
import { PopupContainer } from '../../ReactComponents/Popup'
import { IntegrationsContainer } from '../../ReactComponents/Integrations/IntegrationsContainer'
import { INTEGRATION_MODAL_REF, POPUP_MODAL_REF } from '../../constants'

export default function () {
  const btn = html`
    <div
      class="btn-top-bar config"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img src="bundle/assets/img/config.svg" /> Configurações
    </div>
  `
  const btnSettings = $(html`
    <div class="dropdown">
      ${btn}
      <div
        class="dropdown-menu dropdown-body dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
        style="width: 300px; font-size: 16px;"
      >
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#modal-seo"
          data-type="seo"
        >
          ${this.options.locale.modalSeo.title}
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#tracking-code"
          data-type="pixel"
        >
          PIXELS
          <i class="fa fa-bar-chart ml-1"></i>
        </a>

        <div id="${INTEGRATION_MODAL_REF}"></div>
        <div id="${POPUP_MODAL_REF}"></div>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#modal-custom-code"
          data-type="code"
        >
          ${this.options.locale.modalCustomCode.title}
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#modal-custom-css"
          data-type="css"
        >
          ${this.options.locale.modalCss.title}
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#modal-global-css"
          data-type="css"
        >
          ${this.options.locale.globalSettings.title}
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item d-flex justify-content-between align-items-center"
          href="#"
          data-toggle="modal"
          data-target="#modal-conversions"
          data-type="convertions"
        >
          ${this.options.locale.modalConversions.title}
        </a>
      </div>
    </div>
  `)

  initPixelModal.call(this)
  initGlobalStyleModal.call(this)
  initCssModal.call(this)
  initCustomCodeModal.call(this)
  initSeoModal.call(this)
  initConversionsModal.call(this)

  this.topbarRight.append(btnSettings)

  PopupContainer(this, POPUP_MODAL_REF)
  IntegrationsContainer(this, INTEGRATION_MODAL_REF)
}
