import React, { memo } from 'react'
import { SelectList } from '../SelectList/SelectList'

export const Actions = memo(
  ({ platform, itemSelected, handleChange, disabled }) => {
    const { actions } = platform || {}

    return (
      <SelectList
        list={actions}
        handleChange={handleChange}
        itemSelected={itemSelected}
        OptionComponent={({ item }) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )}
        disabled={disabled}
      />
    )
  }
)
Actions.displayName = 'Actions'
