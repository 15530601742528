import ColorPicker from '../../../components/helpers/colorPicker'
import initModalPhoto from '../initModalPhoto'
import STYLE_GLOBAL_CONFIG from './constant'
import useImageConfig from './image.method'

const colorPicker = new ColorPicker()
const useGlobalStyle = ({ form, keditor }) => {
  const constant = keditor.options.globalStyle //STYLE_GLOBAL_CONFIG
  const imageConfig = useImageConfig({ form, keditor })

  const iframeBody = keditor.iframeBody

  const edit = () => {
    form.on('click', '#photo-galery, #photo-galery-2', function (e) {
      keditor.options.locale.modalImage.isContainer = false
      keditor.options.locale.modalImage.isIframe = true
      keditor.options.locale.modalImage.target = iframeBody
      keditor.options.locale.modalImage.form = form
    })

    colorPicker.init(
      form,
      (color) => {
        constant.background.color = color
      },
      `#background`
    )

    form.find('.clear-btn').on('click', function (e) {
      const { background } = constant
      const type = $(this).data('id')
      background[type] = STYLE_GLOBAL_CONFIG.background[type]
      switch (type) {
        case 'img':
          background['img'] = { src: '', repeat: 'no-repeat', position: '50% 0%', size: 'auto' }
          form.find('#bg-preview').css({ backgroundImage: '' })
          form.find('#external-bg-url').val('')
          break
        case 'color':
          form.find('#bg-preview').css({ backgroundColor: STYLE_GLOBAL_CONFIG.background.color })
          break

        default:
          break
      }
      load()
    })

    imageConfig.init()
  }

  const load = () => {
    const {
      background: { color },
    } = keditor.options.globalStyle

    form.find('.color-picker-component').each(function () {
      let el = $(this)

      el.spectrum({
        type: 'component',
        hideAfterPaletteSelect: true,
        togglePaletteOnly: 'true',
        showButtons: true,
        cancelText: 'Cancelar',
        chooseText: 'Escolher',
        togglePaletteMoreText: 'Mais',
        togglePaletteLessText: 'Menos',
        clearText: 'Limpar',
        noColorSelectedText: 'Nenhuma cor selecionada',
      })
    })

    form.find('.sp-add-on').css({ width: 50, borderRadius: 4 })
    form.find('.sp-colorize').css({ borderRadius: 4 })

    colorPicker.show(form, color, `#background`)

    imageConfig.show()
  }

  const save = () => {
    form.find('#global-style-save').on('click', function () {
      const { background } = constant
      //console.log(background)
      let style = {
        backgroundColor: background.color,
        backgroundImage: background.img.src ? `url(${background.img.src})` : 'none',
        backgroundPosition: background.img.position,
        backgroundRepeat: background.img.repeat,
        backgroundSize: background.img.size,
      }

      iframeBody.css(style)

      keditor.options.contentScripts.header_system.content.background = background
      //console.log(keditor.options.contentScripts.header_system)

      form.modal('hide')
    })
  }

  return { edit, load, save }
}

export default useGlobalStyle
