import React, { useMemo, useState } from 'react'
import { useIntegration } from '../../context/integrationContext'
import { ItemCard } from './ItemCard'
import { INTEGRATIONS_TYPE } from '../../constants'
import { EmailMktIntegration } from '../../../EmailMktIntegration'
import { Webhook } from '../../../Webhook'

const STEPS = {
  DEFINE_TYPE: 1,
  FILL_FORM_EMAIL_MKT: 2,
  FILL_FORM_WEBHOOK: 3,
  REVIEW: 4,
}

export const IntegrationsAdd = () => {
  const {
    integrations: { data: list },
    keditor,
  } = useIntegration()
  const [step, setStep] = useState(STEPS.DEFINE_TYPE)
  const hasEmailMkt = useMemo(() => {
    return list?.some(
      (integration) => integration.type === INTEGRATIONS_TYPE.EMAIL_MARKETING
    )
  }, [list])

  switch (step) {
    case STEPS.DEFINE_TYPE:
      return (
        <div className="modal-body modalBodyWithScroll">
          <p className="text-left">Escolha um tipo de integração</p>
          <div className="row row-cols-1 row-cols-md-2 mt-5">
            <div className="col mb-4">
              <ItemCard
                name="Email Marketing"
                disabled={hasEmailMkt}
                onClick={() => setStep(STEPS.FILL_FORM_EMAIL_MKT)}
              />
              {hasEmailMkt && (
                <h6 className="card-subtitle mt-2 text-muted text-center">
                  Já existe uma integração de Email Marketing ativa.
                </h6>
              )}
            </div>
            <div className="col mb-4">
              <ItemCard
                name="Webhook"
                onClick={() => setStep(STEPS.FILL_FORM_WEBHOOK)}
              />
            </div>
          </div>
        </div>
      )
    case STEPS.FILL_FORM_EMAIL_MKT:
      // TODO: Fix async request issue in Email Mkt integration component
      return <EmailMktIntegration self={keditor} />
    case STEPS.FILL_FORM_WEBHOOK:
      return <Webhook />
    case STEPS.REVIEW:
      return <div>REVIEW</div>
    default:
      return null
  }
}
