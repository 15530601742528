import CSS_CLASS from '../constants/cssClass'
import generateId from '../utils/generateId'
import initDeviceSwitcher from './initDeviceSwitcher'
import initTopbarRightActions from './initTopbarRightActions'
import initTopbarExtraItems from './initTopbarExtraItems'

export default function () {
  let self = this
  let topbarId = generateId()
  let options = self.options

  self.topbar = $(/*html*/ `
    <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR}" id="${topbarId}">
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR_LEFT} d-flex align-items-center justify-content-start">
        <div 
          class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR_TITLE}" 
          title="${options.title}"
          style="font-family: 'Poppins'"
        >
          <img src="bundle/assets/img/logo.svg" /> 
        </div>
        <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR_TITLE} ">
          <button
            class="btn-top-bar"
            id="btn-back-page" 
            title="sair"
          >
            <img 
              style="margin-right: 8px"
              src="bundle/assets/img/back.svg"
            /> Sair
          </button>
        </div>
      </div>
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR_CENTER} d-flex align-items-center justify-content-center">
      </div>
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.TOPBAR_RIGHT} d-flex align-items-center justify-content-end" style="font-size: 14px">
      </div>
    </div>
  `)

  self.topbarLeft = self.topbar.find(`.${CSS_CLASS.TOPBAR_LEFT}`)
  self.topbarCenter = self.topbar.find(`.${CSS_CLASS.TOPBAR_CENTER}`)
  self.topbarRight = self.topbar.find(`.${CSS_CLASS.TOPBAR_RIGHT}`)

  self.topbar.appendTo(self.wrapper)

  $('#btn-back-page').on('click', () => {
    let confirmation = confirm('Deseja sair? Caso saía, perderá todo conteúdo não salvo')
    if (confirmation) $(location).attr('href', self.options.redirectUrl)
    else return
  })

  initDeviceSwitcher.call(self)
  initTopbarRightActions.call(self)
  initTopbarExtraItems.call(self)
}
