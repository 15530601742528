import React from 'react'
import { SketchPicker } from 'react-color'

import styled from 'styled-components'

export const Sketch = ({ initialColor, onChange, direction }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const [color, setColor] = React.useState(
    initialColor || {
      r: 241,
      g: 112,
      b: 19,
      a: 1,
    }
  )

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color) => {
    onChange(color.hex)
    setColor(color.hex)
  }

  return (
    <div>
      <Wrapper>
        <Swatch onClick={handleClick}>
          <Color color={color} />
        </Swatch>
        <input id="color-input" type="text" className="form-control" value={color} onClick={handleClick} readOnly />
      </Wrapper>
      {displayColorPicker ? (
        <Popover >
          <Cover onClick={handleClose} />
          <StyledSketchPicker
            width={225}
            color={color}
            onChange={handleChange}
            direction={direction}
            disableAlpha
          />
        </Popover>
      ) : null}
    </div>
  )
}

const Wrapper = styled.div`
    display: flex;
    background: #292929 !important;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
`

const Color = styled.div`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  border: 1px solid #fff;
  /* background: ${(props) =>
    `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`}; */
  background: ${(props) => `${props.color}`};
`

const Swatch = styled.div`
  /* padding: 5px; */
  padding-top: 0px;
  /* background: #fff; */
  border-radius: 1px;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); */
  display: inline-block;
  cursor: pointer;
`

const Popover = styled.div`
  position: absolute;
  z-index: 999;
  /* transform: translate(-100%, -100%);
    left: 100%;
    top: 100%; */
`

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const StyledSketchPicker = styled(SketchPicker)`
  &.sketch-picker {
    input,
    span,
    label {
      color: #333 !important;
    }
    ${({ direction }) => direction === 'top' && `
      transform: translate(0px, -112%);
    `}
  }
`
