import React from 'react'
import { IntegrationsList } from '../IntegrationsList'
import { useIntegration } from '../../context/integrationContext'
import { INTEGRATIONS_PANEL_VIEW } from '../../constants'
import { IntegrationsAdd } from '../IntegrationsAdd'
import { IntegrationsEdit } from '../IntegrationsEdit'

export const Panel = () => {
  const { panelView } = useIntegration()

  switch (panelView) {
    case INTEGRATIONS_PANEL_VIEW.LIST:
      return <IntegrationsList />
    case INTEGRATIONS_PANEL_VIEW.ADD:
      return <IntegrationsAdd />
    case INTEGRATIONS_PANEL_VIEW.EDIT:
      return <IntegrationsEdit />
    default:
      return null
  }
}
