const UPLOAD_IMAGE = /*html*/ `
  <div 
    id="drag-and-drop-zone" 
    class="dm-uploader py-3" 
    style="background: #292929; color: #f9f9f9"
  > 
    <div>
      <label for="arquivo">
        <i
          class="fa fa-cloud-upload fa-4x"
          aria-hidden="true"
        ></i>
      </label>
      <input type="file" name="arquivo" id="arquivo" style="display: none" >
    </div>
    <h3 class="mb-2 mt-2">
      Arraste &amp; Solte a imagem aqui
    </h3>
    <span style="font-size: 10px">
      Extensões permitidas: <b>jpg, jpeg, png, gif, svg, bmp ou webp</b>
      <br/>
      Tamanho máximo permitido da imagem: <b>2mb</b>
    </span>
  </div>
`

export const MODAL = /*html*/ `
<div class="pb-5">
  ${UPLOAD_IMAGE}
</div>
<h5 class="modal-title">Galeria</h5>
<div class="d-flex justify-content-center">
  <div id="data-container" class="gallery d-flex justify-content-center flex-wrap"></div>
</div>
<div class="file-alert none"></div>
`
