import React, { useReducer, useState } from 'react'
import { usePopup } from '../../context/popupContext'
import { BUTTON_ACTION, ELEMENTS } from '../../shared/constants'
import { handleChange, handleQuillEditorChange } from '../../utils'
import { Switch } from '../Switch'
import './styles.css'
import { TextEditor } from '../Form/Form'

function reducer(state, action,) {
    action?.callback?.()
    return {
        ...state,
        [action.type]: action.payload
    }
}

export const Texts = () => {
    const {
        state: { modalPopup, contentPopup },
        setContent,
    } = usePopup()
    const [state, dispatch] = useReducer(reducer, { [ELEMENTS.TITLE]: contentPopup.title, [ELEMENTS.BODY]: contentPopup.body, [ELEMENTS.BUTTON_TEXT
    ]: contentPopup.cta.text });
    const buttonAction = contentPopup?.cta?.action;

    const handleButtonAction = (e) => {
        setContent(prevContent => ({
            ...prevContent,
            contentPopup: {
                ...prevContent.contentPopup,
                cta: {
                    ...prevContent.contentPopup.cta,
                    action: e.target.value
                },
            }
        }))
    }

    return (
        <>
            <div className="form-group">
                {/* //TODO: Replace by locale key */}
                <label htmlFor="inputTitle" className="col col-form-label">
                    Título
                </label>
                <div className="col">
                    <TextEditor
                        value={state[ELEMENTS.TITLE]}
                        onChange={(value) => dispatch({ type: ELEMENTS.TITLE, payload: value, callback: () => handleQuillEditorChange(value, 'contentPopup.title', setContent) })}
                    />
                </div>
            </div>
            <div className="form-group">
                {/* //TODO: Replace by locale key */}
                <label htmlFor="inputEmail3" className="col col-form-label">
                    Corpo
                </label>
                <div className="col">
                    <TextEditor
                        value={state[ELEMENTS.BODY]}
                        onChange={(value) => dispatch({ type: ELEMENTS.BODY, payload: value, callback: () => handleQuillEditorChange(value, 'contentPopup.body', setContent) })}
                    />
                </div>
            </div>
            <hr />
            <div className="form-group">
                {/* //TODO: Replace by locale key */}
                <label htmlFor="inputBtnConfirmation" className="col col-form-label">
                    Botão
                </label>
                <div className="col">
                    <TextEditor
                        value={state[ELEMENTS.BUTTON_TEXT]}
                        onChange={(value) => dispatch({ type: ELEMENTS.BUTTON_TEXT, payload: value, callback: () => handleQuillEditorChange(value, 'contentPopup.cta.text', setContent) })}
                    />
                </div>
            </div>
            <div className="form-group pt-2">
                {/* //TODO: Replace by locale key */}
                <label htmlFor="inputBtnConfirmation" className="col col-form-label">
                    Ação do Botão
                </label>
                <div className="col">
                    <select
                        className="form-control"
                        value={buttonAction}
                        onChange={(e) => handleButtonAction(e)}
                    >
                        <option value={BUTTON_ACTION.CLOSE_MODAL}>Fechar modal </option>
                        <option value={BUTTON_ACTION.REDIRECT}>Redirecionar para URL</option>
                        <option value={BUTTON_ACTION.SUBMIT}>Submeter Formulário</option>
                    </select>
                </div>
                {buttonAction === BUTTON_ACTION.REDIRECT && (
                    <div className="col">
                        <div className="pt-3">
                            <label htmlFor="inputBtnConfirmation" className="col-form-label">
                                Para onde redirecionar:
                            </label>
                            <div>
                                <input
                                    type="text"
                                    name="contentPopup.cta.href"
                                    className="form-control"
                                    placeholder='https://'
                                    value={contentPopup?.cta.href}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {buttonAction === BUTTON_ACTION.SUBMIT && (
                    <div className="col">
                        <div className="pt-3">
                            <div className='d-flex justify-content-center align-items-center phone-label-title'>
                                <label htmlFor="inputBtnConfirmation" className="col-form-label ">
                                    Nome
                                </label>
                                <Switch
                                    name="contentPopup.form.name.status"
                                    checked={contentPopup.form?.name?.status}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                            <div>
                                <input
                                    id="inputBtnConfirmation"
                                    className="form-control"
                                    type="text"
                                    name="contentPopup.form.name.placeholder"
                                    value={contentPopup.form?.name?.placeholder}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <label htmlFor="inputBtnConfirmation" className="col-form-label">
                                Email
                            </label>
                            <div>
                                <input
                                    id="inputBtnConfirmation"
                                    type="text"
                                    name="contentPopup.form.email.placeholder"
                                    className="form-control"
                                    value={contentPopup.form?.email.placeholder}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className='d-flex justify-content-center align-items-center phone-label-title'>
                                <label htmlFor="inputBtnConfirmation" className="col-form-label ">
                                    Celular
                                </label>
                                <Switch
                                    name="contentPopup.form.phone.status"
                                    checked={contentPopup.form?.phone.status}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="contentPopup.form.phone.placeholder"
                                    className="form-control"
                                    id="inputBtnConfirmation"
                                    value={contentPopup.form?.phone.placeholder}
                                    onChange={(e) => handleChange(e, setContent)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
