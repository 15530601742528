export default function (width, self, style) {
  if (!self) return
  function setFontSize(el, device) {
    const value = $(el).attr(`data-width_${device}`)

    $(el).css('width', value ? value + 'px' : '')
  }

  if (width !== '100%') {
    const elMobile = self.iframeBody.find(`[data-width_mobile]`)

    elMobile.each(function () {
      setFontSize(this, 'mobile')
    })
  } else {
    const elDesktop = self.iframeBody.find(`[data-width_default]`)

    elDesktop.each(function () {
      setFontSize(this, 'default')
    })
  }
}
