import ColorPicker from '../../../../components/helpers/colorPicker'
import useImageWidth from '../../../../components/microComponents/ImageWidth'
import useBorderRadius from '../../../../components/microComponents/BorderRadius'
import useBorderColor from '../../../../components/microComponents/BorderColor'
import useBorderStyle from '../../../../components/microComponents/BorderStyle'
import useBorderWidth from '../../../../components/microComponents/BorderWidth'
import { SET_CONTAINER_BACKGROUND } from '../../../../constants'

const colorPicker = new ColorPicker()

/**
 * Callback when the image is set on the container
 * @param {Function} callback
 * @returns {void}
 */
function onSetImageContainer(callback)
{
  if (typeof callback === 'function')
    window.onSetImageContainerCallback = callback
}

/**
 * Reset callback
 * @returns {void}
 */
function resetOnSetImageContainer()
{
  window.onSetImageContainerCallback = null
}

/**
 * Responsável por adicionar a imagem escolhida no elemento em questão
 * @param {JQuery} target onde a imagem será adicionada
 * @param {JQuery} form usado para atualizar as informações da configuração do component/container que esta sendo alterado
 * @param {JQuery} imageSelected imagem selecionada
 */
function componentSave(target, form, imageSelected, page) {
  const loading = {
    message: 'Carregando as informações da imagem...',
  }
  if (target.find('.demo-photo').is(':visible')) {
    target.removeClass('d-flex justify-content-center')
    target.css('text-align', 'center')
    target.find('.demo-photo').css({ display: 'none' })
    target.find('.img-product').attr({
      src: imageSelected,
      'data-cke-saved-src': imageSelected,
    })
    form.find('#photo-style').val('')
  } else {
    target.find('.img-product').attr({
      src: imageSelected,
      'data-cke-saved-src': imageSelected,
    })
    loading.message = 'Recarregando as informações da imagem...'
  }

  // Mostra o restante das configurações da imagem
  form.find('.photo-config').css('display', 'none')
  form.find('.border-config').css('display', 'none')
  form.find('.config-alert').show()
  form.find('.loading-config').html(`
  <div style="width: 100%; text-align: center" class="my-3">
    <div>
      ${loading.message}
    </div>
    <div class="spinner-border text-primary mt-2" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  `)

  form.find('#external-url').val(imageSelected)

  // setTimeout(() => {
  const targetImage = target.find('.img-product')

  const ImageWidth = useImageWidth({ form, keditor: page, elementRef: () => targetImage })
  const borderRadius = useBorderRadius({
    form,
    keditor: page,
    elementRef: () => targetImage,
  })

  const borderWidth = useBorderWidth({
    form,
    keditor: page,
    elementRef: () => targetImage,
  })

  const borderColor = useBorderColor({
    form,
    keditor: page,
    elementRef: () => targetImage,
  })
  const borderStyle = useBorderStyle({
    form,
    keditor: page,
    elementRef: () => targetImage,
  })

  targetImage.on('load', function () {
    if (!targetImage.is(':visible')) return
    form.find('.photo-config').css('display', 'block')
    form.find('.border-config').css('display', 'block')
    form.find('.config-alert').hide()
    form.find('.loading-config').html('')

    form.find('.custom-class').val(target.prop('class'))
    form.find('#photo-align').val(target.css('text-align'))

    colorPicker.show(form, targetImage.css('border-color'))
    ImageWidth.show()
    borderWidth.show()
    borderRadius.show()
    borderColor.show()
    borderStyle.show()

    form.find('#image-alt').val(targetImage.attr('alt'))

    form.find('.change-image-button').css({ backgroundImage: `url(${imageSelected})` })

    form.find('.upload-button').hide()
    form.find('.change-image-button').show()

    const anchorRef = target.find('.image-anchor')

    const valHref = validationHref(anchorRef.attr('href'))
    const aTarget = valHref || ''

    form.find('#button-target-label').html(anchorTarget(aTarget))
  })
}

/**
 * Responsável por adicionar a imagem no background do container escolhido
 * @param {JQuery} target onde a imagem será adicionada
 * @param {JQuery} form usado para atualizar as informações da configuração do component/container que esta sendo alterado
 * @param {JQuery} imageSelected imagem selecionada
 */
function containerSave(target, form, imageSelected) {
  const data = JSON.parse(target.attr('data-background'))
  data.img.src = imageSelected
  target.attr('data-background', JSON.stringify(data))

  if (typeof window.onSetImageContainerCallback === 'function')
  {
    try
    {
      window.onSetImageContainerCallback(imageSelected)
    }
    catch (e)
    {
      console.error(e)
    }
  }

  const { init, final, orientation } = data.gradient
  const style = `linear-gradient(${orientation}, ${init.color} ${init.position}%, ${final.color} ${final.position}%)`
  target.css('backgroundImage', `${style},url(${imageSelected})`)
  form.find('.change-image-button').css({ backgroundImage: `url(${imageSelected})` })

  form.find('.upload-button').hide()
  form.find('.change-image-button').show()
}

/**
 * Responsável por mostrar a mensagem de erro quando for fazer o upload de uma imagem
 * @param {String} message mensagem de que aparecerá
 */
function showFileErrorAlert(message) {
  $('.file-alert').removeClass('none')
  $('.file-alert').addClass('alert-fade-in')

  $('.file-alert').html(message)

  setTimeout(() => {
    $('.file-alert').removeClass('alert-fade-in')
    $('.file-alert').addClass('none')
  }, 3000)
}

/**
 * Realiza as alterações visuais de quando um elemento é selecionado e retorna esse elemento
 * @param {String} target classe do elemento que está sendo selecionado
 */
function selectImage(modal) {
  let selectedImage = ''
  const target = this
  if (modal.find('.image-item.active').attr('id') != $(target).attr('id')) {
    modal.find('.save').attr('disabled', false)
    modal.find('.image-item').removeClass('active')
    modal.find(target).addClass('active')

    selectedImage = $(target)
  } else {
    modal.find('.image-item').removeClass('active')
    modal.find('.save').attr('disabled', true)
  }

  return selectedImage
}

/**
 * Retorna o html das imagens para serem renderizados
 * @param {Array} data array que será percorrido
 */
function renderImagesArray(data, apiUrl) {
  const imgURl = `${apiUrl}/storage/media/user/u1/`
  let html = ''
  data.forEach((el, index) => {
    html += /*html*/ `
      <div
        class="image-item d-flex justify-content-center align-items-center"
        id="img-${el.split(imgURl).join('')}"
        data-name="${el.split(imgURl).join('')}"
      >
        <div class="d-flex justify-content-center align-items-center" >
          <img
            src="${el}"
            alt="${el.split(imgURl).join('')}"
          />
        </div>
      </div>`
  })
  return html
}

function addImageViaUrl({ value, target, constant }) {
  console.log(target)
  if (!target.parent().hasClass('keditor-container-inner') && !target.hasClass('keditor-iframe-body')) {
    target.find('.img-product').attr({
      src: value,
      'data-cke-saved-src': value,
    })
  } else if (target.hasClass('keditor-iframe-body')) {
    constant.img.src = value
  } else {
    containerSave(target, form, value)
  }
}

function validationHref(value) {
  if (!value) return null
  const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|#|www\.|)/
  let valueMatch = value.match(regex)

  if (valueMatch && valueMatch[0]) {
    const valTarget = valueMatch[0].indexOf('www.') != -1 ? valueMatch[0].split('www.')[0] : valueMatch[0]

    let label = '#'
    if (valTarget != '#' && valTarget.length) label = valTarget
    else if (!valTarget.length) label = 'https://'

    return label
  }
  return null
}

function anchorTarget(type) {
  switch (type) {
    case 'http://':
      return 'http://'
    default:
      return 'https://'
  }
}

export { componentSave, containerSave, showFileErrorAlert, selectImage, renderImagesArray, addImageViaUrl, onSetImageContainer, resetOnSetImageContainer }
