import React from 'react'
import { useIntegration } from '../../context/integrationContext'
import { INTEGRATIONS_TYPE } from '../../constants'
import { Webhook } from '../../../Webhook'
import { EmailMktIntegration } from '../../../EmailMktIntegration'

export const IntegrationsEdit = () => {
  const { integrationToEdit, keditor } = useIntegration()
  const { type, position } = integrationToEdit

  switch (type) {
    case INTEGRATIONS_TYPE.EMAIL_MARKETING:
      return <EmailMktIntegration self={keditor} />
    case INTEGRATIONS_TYPE.WEBHOOK:
      return <Webhook integrationPosition={position} />
    default:
      break
  }
}
