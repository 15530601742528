import React from 'react'
import { INTEGRATIONS_PANEL_VIEW } from '../../constants'
import { useIntegration } from '../../context/integrationContext'

const typesTranslation = {
  email_marketing: 'Email Marketing',
  webhook: 'Webhook',
}

export const IntegrationCard = ({ id, type, ...rest }) => {
  const { removeIntegration, setPanelView, setIntegrationToEdit } =
    useIntegration()

  const subtitle =
    type === 'webhook'
      ? rest?.resources.find((resource) => resource?.name === 'destination_url')
          ?.value
      : ''

  const handleRemoveIntegration = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Tem certeza que deseja excluir esta integração?')) {
      removeIntegration(id)
    }
  }

  const handleEditIntegration = () => {
    setPanelView(INTEGRATIONS_PANEL_VIEW.EDIT)
    setIntegrationToEdit(type, id)
  }

  return (
    <div
      className="card h-100 mb-3"
      style={{
        backgroundColor: '#292929',
      }}
    >
      <div className="card-body bg-transparent">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{typesTranslation?.[type]}</h5>
          <div>
            <button type="button" className="btn btn-link">
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                style={{ fontSize: '1.2rem', color: 'white' }}
                onClick={handleEditIntegration}
              />
            </button>
            <button type="button" className="btn btn-link">
              <i
                className="fa fa-trash-o"
                aria-hidden="true"
                style={{ fontSize: '1.2rem', color: 'white' }}
                onClick={handleRemoveIntegration}
              />
            </button>
          </div>
        </div>
        <span
          className="card-subtitle mt-2"
          style={{
            fontSize: 14,
            color: '#6c757d',
          }}
        >
          {subtitle}
        </span>
      </div>
    </div>
  )
}
