import CSS_CLASS from '../constants/cssClass'

export default function (contentArea) {
  let mouseEnter, mouseLeave

  contentArea.on('mouseover', `.${CSS_CLASS.CONTAINER}`, function (e) {
    const container = $(this)
    const target = $(e.target)

    // clearTimeout(mouseEnter)

    mouseEnter = setTimeout(() => {
      const parent = target.closest('.keditor-container, .keditor-component-content, .keditor-container-content')
      if (parent.length && parent[0] == container[0]) {
        return container.addClass('ui-hover')
      }
      return container.removeClass('ui-hover')
    }, 100)
  })
  contentArea.on('mouseleave', `.${CSS_CLASS.CONTAINER}`, function (e) {
    const container = $(this)

    // clearTimeout(mouseLeave)

    mouseLeave = setTimeout(() => {
      container.removeClass('ui-hover')
    }, 100)
  })
}
