import CSS_CLASS from '../constants/cssClass';
import getClickedElement from '../utils/getClickedElement';
import openSidebar from '../sidebar/openSidebar';
import closeSidebar from '../sidebar/closeSidebar';
import hideSidebarLeft from '../sidebarLeft/hideSidebarLeft';
import hideSnippetSidebarLeft from '../sidebarLeft/hideSnippetSidebarLeft';

export default function () {
  let self = this;
  let options = self.options;
  let sidebar = self.sidebar;
  let contentAreasWrapper = self.contentAreasWrapper;

  self.iframeBody[0].addEventListener('click', function (e) {
    let sidebar = getClickedElement(e, `.${CSS_CLASS.SIDEBAR}`);
    let sidebarLeft = getClickedElement(e, `.${CSS_CLASS.SIDEBAR_LEFT}`);
    let container = getClickedElement(e, `.${CSS_CLASS.CONTAINER}`);
    let component = getClickedElement(e, `.${CSS_CLASS.COMPONENT}`);
    let toolbar = getClickedElement(e, `.${CSS_CLASS.TOOLBAR_COMPONENT}`);
    let actions = getClickedElement(e, `.${CSS_CLASS.TOOLBAR_CONTAINER_CONTENT}`);
    

    const removeSelected = () => {
      contentAreasWrapper.find(`.${CSS_CLASS.STATE_TOOLBAR_SHOWED}`).removeClass(CSS_CLASS.STATE_TOOLBAR_SHOWED);
    };

    const openConfigSidebar = (el, self) => {
      hideSnippetSidebarLeft.call(self)
      openSidebar.call(self, el)      
    };
    
    if(toolbar || actions) return

    if (component) {
      if (!component.hasClass(CSS_CLASS.STATE_TOOLBAR_SHOWED)) {
        let contentArea = component.parent();
        removeSelected();
        
        // closeSidebar.call(self)
        component.addClass(CSS_CLASS.STATE_TOOLBAR_SHOWED);

        if (typeof options.onComponentSelected === 'function') {
          options.onComponentSelected.call(self, e, component, contentArea);
        }

        openConfigSidebar(component, self)
        options.elementSelected = component
      }

      return;
    } else if (container) {
      if (!container.hasClass(CSS_CLASS.STATE_TOOLBAR_SHOWED)) {
        let contentArea = container.parent();
        // closeSidebar.call(self);
        removeSelected();

        container.addClass(CSS_CLASS.STATE_TOOLBAR_SHOWED);

        if (typeof options.onContainerSelected === 'function') {
          options.onContainerSelected.call(self, e, container, contentArea);
        }

        openConfigSidebar(container, self)
        options.elementSelected = component
      }

      return;
    }
  });
}
