import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useBorderColor = ({ form, keditor, component, label, elementRef, customFormRef, isFirst }) => {
  const formRef = customFormRef || `border-color-editor`

  const init = (callback) => {
    //Cor do texto
    colorPicker.init(
      form,
      (color) => {
        elementRef().css(`border-color`, `${color}`)
        if (typeof callback === 'function')
        {
          callback(color)
        }
      },
      `#${formRef}`
    )
  }

  const show = () => {
    colorPicker.show(form, elementRef().css('border-color'), `#${formRef}`)
  }

  const template = /*html*/ `
  <div class="col-sm-6 ${!isFirst && 'mt-3'}">
    <label for="${formRef}">${label}</label>
    <div class="input-group">
      <input data-id="#${formRef}" class="color-picker-component form-control" />
    </div>
  </div>   
  `

  return {
    componentName: 'borderColor',
    init,
    show,
    template,
  }
}

export default useBorderColor
