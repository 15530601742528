import React from 'react';

function Switch({name, checked, onChange, ...rest}) {
    return (
        <div className="button-switch" {...rest}>
            <input
                id="switch-blue"
                type="checkbox"
                className="switch"
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor="switch-blue" className="lbl-off"></label>
            <label htmlFor="switch-blue" className="lbl-on"></label>
        </div>
    );
}

export default Switch;