export default function (value)
{
  //Remove all line breaks and replace them with commas, then remove double commas
  return value.trim()
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(',,', ',')
    .split(',')
    .map(item =>
    {
        let s = item.trim()
        return s.length > 0 ? s : undefined
    })
    .filter(item => item).join(',')
}