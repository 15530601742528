import initModalForm from './initModalForm.js';
import initModalPhoto from './initModalPhoto.js';

export default function (modalId, page, title, type = 'modal', target = null, form) {
  switch (type) {
    case 'photo':
      initModalPhoto({ modalId, page, title, target, form });
      break;
    case 'form':
      initModalForm({ modalId, page, title, target });
      break;
  }
}
