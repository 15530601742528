import DEFAULTS from '../keditor/constants/defaults'
const defaultAxiosHeaders = {
  headers: { Authorization: DEFAULTS.accessToken, 'Accept-Language': 'pt_BR' },
}

export const getMytIntegrations = () =>
  axios({
    method: 'get',
    url: `${DEFAULTS.apiUrl}/editor/integrations`,
    ...defaultAxiosHeaders,
  })

export const getAction = (service, action) =>
  axios({
    method: 'get',
    url: `${DEFAULTS.apiUrl}/editor/integrations/${service}/actions/${action}`,
    ...defaultAxiosHeaders,
  })

export const getResource = (service, resource, params = null, query = null) => {
  let url = `${DEFAULTS.apiUrl}/editor/integrations/${service}/resource?resource=${resource}`
  if (params && params.length > 0) {
    let dataParams = ''
    params.map((item, index) => {
      dataParams += `&params[${index}][name]=${item.name}&params[${index}][value]=${item.value}`
    })

    url += dataParams
  }

  if (query) {
    url += `&query=${query}`
  }

  return axios({
    method: 'get',
    url,
    ...defaultAxiosHeaders,
  })
}

export const postActionForm = (service, action, resources, redirect_url) =>
  axios({
    method: 'POST',
    url: `${DEFAULTS.apiUrl}editor/integrations/${service}/action_form`,
    data: {
      action: action,
      resources: resources,
      redirect_url: redirect_url,
    },
    ...defaultAxiosHeaders,
  })
