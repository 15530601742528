import CSS_CLASS from '../constants/cssClass'
import ICON from '../constants/icon'
import DEVICE_MODE from '../constants/deviceMode'
import switchDevice from './switchDevice'

export default function () {
  let self = this
  let options = self.options
  let topbarCenter = self.topbarCenter

  let btnMobile = (self.btnMobile = $(`
    <button 
      title="${options.locale.viewOnMobile}" 
      class="btn-top-bar devices"
    >
      ${ICON.DEVICE_MOBILE} Mobile
    </button>
  `))

  btnMobile.on('click', function (e) {
    e.preventDefault()
    switchDevice.call(self, DEVICE_MODE.MOBILE, btnMobile)
  })

  let btnLaptop = (self.btnLaptop = $(`
    <button 
      href="javascript:void(0);" 
      title="${options.locale.viewOnLaptop}" 
      class="btn-top-bar devices"
    >${ICON.DEVICE_LAPTOP} Desktop</button>
  `))
  btnLaptop
    .on('click', function (e) {
      e.preventDefault()
      switchDevice.call(self, DEVICE_MODE.LAPTOP, btnLaptop)
    })
    .trigger('click')

  topbarCenter.append(btnLaptop)
  topbarCenter.append(btnMobile)
}
