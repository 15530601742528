import { ModalBase } from './template/modalBase'

export default function () {
  let self = this

  let seoObj = this.options.contentSeo

  let modalFooter = /*html*/ `
    <button type="button" class="btn cancel" data-dismiss="modal">
        ${this.options.locale.close}
    </button>
    <button type="button" class="btn save">
        ${this.options.locale.insertInfo}
    </button>`
  let modalForm = /*html*/ `
    <form>
      <div style="font-weight: bold; margin-bottom: 30px; font-size: 20px">
        Preencha com as informações de SEO da página.
      </div>
      <div class="form-group">
          <label for="meta-title" class="col-form-label">
          ${this.options.locale.modalSeo.metaTitleLabel}
          </label>
          <input type="text" class="form-control" id="meta-title" name="meta-title" value="${seoObj.title}"placeholder="Meta title"/>
      </div>
      <div class="form-group">
          <label for="meta-description" class="col-form-label">
          ${this.options.locale.modalSeo.metaDescriptionLabel}
          </label>
          <textarea class="form-control" id="meta-description"name="meta-description"placeholder="Meta description">${seoObj.description}</textarea>
      </div>
      <div class="form-group">
          <label for="meta-keywords" class="col-form-label">
          ${this.options.locale.modalSeo.metaKeywordLabel}
          </label>
          <input type="text" class="form-control" id="meta-keywords"name="meta-keywords"placeholder="Meta Keyword"value="${seoObj.keywords}"/>
      </div>
    </form>`

  let modal = $(
    ModalBase({
      id: 'modal-seo',
      title: this.options.locale.modalSeo.title,
      body: modalForm,
      footer: modalFooter,
      info: this.options.locale.modalSeo.description,
    })
  )

  modal.find('.save').on('click', function () {
    self.options.contentSeo = getSeoValues(modal)

    if (self.options.onSeoInsert && self.options.onSeoInsert instanceof Function) {
      self.options.onSeoInsert.call(self)
    }

    modal.modal('hide')
  })

  modal.on('show.bs.modal', function () {
    setSeoValues($(this), self.options.contentSeo)
  })

  let result = modal.appendTo(self.wrapper)

  return result
}

/**
 * Retorna um objeto no formado do SEO com os valores dos campos do modal já preenchidos.
 * @param {jQuery} modal
 */
function getSeoValues(modal) {
  return {
    title: modal.find('#meta-title').val(),
    description: modal.find('#meta-description').val(),
    keywords: modal.find('#meta-keywords').val(),
  }
}

/**
 * Recebe um objeto no formado do SEO e seta os valores dos campos do modal.
 * @param {jQuery} modal
 */
function setSeoValues(modal, seoObj) {
  modal.find('#meta-title').val(seoObj.title)
  modal.find('#meta-description').val(seoObj.description)
  modal.find('#meta-keywords').val(seoObj.keywords)
}
