import CSS_CLASS from '../constants/cssClass'
import { escapeHtml } from '../helpers/helpers'
import { ModalBase } from './template/modalBase'

export default function () {
  let self = this

  let modalTabs = /*html*/ `
  <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
    <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
      <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK} active" id="header-tab" data-toggle="tab" href="#v-pills-header" role="tab" aria-controls="header-tab" aria-selected="true">
          ${this.options.locale.modalCustomCode.tabs[0].name}
      </a>
    </li>
    <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
      <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK}" id="footer-tab" data-toggle="tab" href="#v-pills-footer" role="tab" aria-controls="footer-tab" aria-selected="true">
          ${this.options.locale.modalCustomCode.tabs[1].name}
      </a>
    </li>
  </ul>`
  let modalBody = /*html*/ `<div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-header" role="tabpanel" aria-labelledby="v-pills-header-tab">
                <p class="mb-2">${escapeHtml(this.options.locale.modalCustomCode.tabs[0].description)} </p>
                <div class="col-sm-12 editor" id="editor-header"></div>
            </div>
            <div class="tab-pane fade show" id="v-pills-footer" role="tabpanel" aria-labelledby="v-pills-footer-tab" >
                <p class="mb-2">${escapeHtml(this.options.locale.modalCustomCode.tabs[1].description)} </p>
                <div class="col-sm-12 editor" id="editor-footer"></div>
            </div>
        </div>`
  let modalFooter = /*html*/ `
    <button type="button" class="btn cancel" data-dismiss="modal">
        ${this.options.locale.close}
    </button>
    <button type="button" class="btn save">
        ${this.options.locale.insertCode}
    </button>
  `

  let modal = $(
    ModalBase({
      id: 'modal-custom-code',
      title: this.options.locale.modalCustomCode.title,
      body: modalBody,
      footer: modalFooter,
      tabs: modalTabs,
      size: 'large',
    })
  )

  modal.find('.save').on('click', function () {
    if (self.options.onCustomCodeInsert && self.options.onCustomCodeInsert instanceof Function) {
      self.options.onCustomCodeInsert.call(self, modal.find('#editor-header'), modal.find('#editor-footer'))
    }

    modal.modal('hide')
  })

  modal.on('show.bs.modal', function () {
    self.options.onCustomCodeCodeEditorShow.call(self, modal.find('#editor-header'), modal.find('#editor-footer'))
  })

  let result = modal.appendTo(self.wrapper)

  if (self.options.onCustomCodeCodeEditorInit && self.options.onCustomCodeCodeEditorInit instanceof Function) {
    self.options.onCustomCodeCodeEditorInit.call(self, modal.find('#editor-header'), modal.find('#editor-footer'))
  }

  return result
}
