export default function (width, self) {
  if (!self) return
  function setFontSize(el, device) {
    $(el).css({ marginTop: $(el).attr('data-margin_' + device) + 'px' })
  }
  if (width !== '100%') {
    const elMobile = self.iframeBody.find('[data-margin_mobile]')

    elMobile.each(function () {
      setFontSize(this, 'mobile')
    })
  } else {
    const elDesktop = self.iframeBody.find('[data-margin_default]')

    elDesktop.each(function () {
      setFontSize(this, 'default')
    })
  }
}
