import CSS_CLASS from '../constants/cssClass'
import ICON from '../constants/icon'
import initDynamicContent from '../component/initDynamicContent'
import closeSidebar from '../sidebar/closeSidebar'
import getContent from '../getContent'

export default function () {
  let self = this
  let options = self.options
  let btnPreview = (self.btnPreview = $(/*html*/ `<button 
      title="${options.locale.previewOff}" 
      class="btn-top-bar config"
    >
      <img 
        style="margin-right: 8px"
        src="bundle/assets/img/preview.svg"
      /> Preview
    </button>`))

  self.previewArea = $(`<div class="${CSS_CLASS.PREVIEW_AREA}"></div>`)
  self.contentAreasWrapper.after(self.previewArea)

  btnPreview.on('click', function (e) {
    e.preventDefault()

    let isPreviewOn = !btnPreview.hasClass(CSS_CLASS.STATE_ACTIVE)

    btnPreview.toggleClass(CSS_CLASS.STATE_ACTIVE)
    self.iframeBody[isPreviewOn ? 'addClass' : 'removeClass'](CSS_CLASS.STATE_PREVIEWING)
    self.previewArea.html('')

    // Close sidebar when previewing
    closeSidebar.call(self)

    self.iframeBody.find('.custom-html').attr('hidden', isPreviewOn)

    isPreviewOn &&
      self.previewArea
        .html(getContent.call(self))
        .find('[data-dynamic-href]')
        .each(function () {
          let dynamicElement = $(this)
          dynamicElement.html('Loading...')
          initDynamicContent.call(self, dynamicElement)
        })

    self.previewArea.find('a,button').on('click', function (e) {
      e.preventDefault()
    })
  })

  //self.topbarRight.append(btnPreview)
}
