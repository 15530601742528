import CSS_CLASS from "./constants/cssClass";
import getContainerContent from "./container/getContainerContent";
import { removeIntegrationUndefined } from './helpers/helpers.js';

export default function (inArray) {
  let self = this;
  let result = [];

  self.contentAreasWrapper.find(`.${CSS_CLASS.CONTENT_AREA_INNER}`).each(function () {
    let html = "";
    $(this).children(`.${CSS_CLASS.CONTAINER}`).each(function () {
        let container = $(this);
        html += getContainerContent.call(self, container);
    });
    html = removeIntegrationUndefined(html)
    result.push(html);
  });

  return inArray ? result : result.join("\n");
}
