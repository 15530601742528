export default function () {
  let self = this
  let options = self.options
  let btnVisit = (self.btnVisit = $(/*html*/ `
    <button class="btn-top-bar config">
      <img 
        style="margin-right: 8px"
        src="bundle/assets/img/visit_page.svg"
      /> Visitar Página
    </button>
    `))

  btnVisit.hide()
  if (options.previewURL) btnVisit.fadeIn(1000)

  btnVisit.on('click', function () {
    window.open(`http://${options.previewURL}`, '_blank')
  })

  self.topbarRight.append(btnVisit)
}
