import CSS_CLASS from "../constants/cssClass";
import generateId from "../utils/generateId";
import hideSidebarLeft from "./hideSidebarLeft";
import { removeShowedElements, clearBtnFocus } from '../../keditor/helpers/helpers';

export default function () {
  let self = this;
  let sidebarId = generateId();
  let contentAreasWrapper = self.contentAreasWrapper;

  let sidebarLeft = (self.sidebarLeft = $(`
    <div class="${CSS_CLASS.UI} ${CSS_CLASS.SIDEBAR_LEFT}" id="${sidebarId}">
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.SIDEBAR_LEFT_HEADER}">
        <div class="${CSS_CLASS.UI} ${CSS_CLASS.SIDEBAR_LEFT_TITLE}"></div>
        <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
          <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
            <a
              class="${CSS_CLASS.BOOTSTRAP_NAV_LINK} active"
              id="${self.options.sidebarLeft.nav.items[0].id}-tab"
              data-toggle="tab" href="#${self.options.sidebarLeft.nav.items[0].id}"
              role="tab"
              aria-controls="${self.options.sidebarLeft.nav.items[0].id}"
              aria-selected="true"
            ></a>
          </li>
          <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_50}">
            <a
              class="${CSS_CLASS.BOOTSTRAP_NAV_LINK}"
              id="${self.options.sidebarLeft.nav.items[1].id}-tab"
              data-toggle="tab"
              href="#${self.options.sidebarLeft.nav.items[1].id}"
              role="tab"
              aria-controls="${self.options.sidebarLeft.nav.items[1].id}"
              aria-selected="true"
            ></a>
          </li>
        </ul>
        <a href="javascript:void(0);" class="${CSS_CLASS.UI} ${CSS_CLASS.SIDEBAR_LEFT_CLOSER}">&times;</a>
      </div>
      <div class="${CSS_CLASS.UI} ${CSS_CLASS.SIDEBAR_LEFT_BODY}"></div>
    </div>
    `));

  sidebarLeft
    .find(`.${CSS_CLASS.SIDEBAR_LEFT_TITLE}`)
    .html(self.options.sidebarLeft.title);
  sidebarLeft.find(`.${CSS_CLASS.BOOTSTRAP_NAV_LINK}`).each(function (index) {
    $(this).html(self.options.sidebarLeft.nav.items[index].title);
  });
  sidebarLeft
    .find(`.${CSS_CLASS.SIDEBAR_LEFT_CLOSER}`)
    .on("click", function (e) {
      e.preventDefault();
      removeShowedElements(contentAreasWrapper)
      clearBtnFocus(contentAreasWrapper)
      hideSidebarLeft.call(self);
    });
  self.sidebarLeftTitle = sidebarLeft.find(`.${CSS_CLASS.SIDEBAR_LEFT_TITLE}`);
  self.sidebarLeftBody = sidebarLeft.find(`.${CSS_CLASS.SIDEBAR_LEFT_BODY}`);
  return sidebarLeft.appendTo(self.wrapper);
}
