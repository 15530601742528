import React from 'react'
import { Modal } from './components/Modal'
import { Panel } from './components/Panel/Panel'
import './styles.css'

export const Integrations = () => {
  return (
    <Modal>
      <Panel />
    </Modal>
  )
}
