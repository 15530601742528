export const integrationParse = (integration) => {
  if (!integration) return {}

  const { resources } = { ...integration }
  const integrationResourcesParsed = {}
  resources.forEach((resource) => {
    const { name, ...rest } = resource
    integrationResourcesParsed[name] = {
      ...rest,
    }
  })

  return {
    ...integration,
    resources: integrationResourcesParsed,
  }
}
