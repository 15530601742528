import React, { useEffect } from 'react'
import { useIntegration } from '../../context/integrationContext'

export const PixelLeadOnSubmit = () => {
  const { keditor } = useIntegration()
  const { header_system: headerSystem } = keditor.options.contentScripts
  const [leadToggle, setLeadToggle] = React.useState(
    headerSystem?.content?.pixelLeadOnSubmit?.enabled || false
  )
  const isInitialRender = React.useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    headerSystem.content = {
      ...headerSystem?.content,
      pixelLeadOnSubmit: {
        ...headerSystem?.content?.pixelLeadOnSubmit,
        enabled: leadToggle,
      },
    }
  })

  return (
    <div className="mb-4">
      <div
        className="d-flex justify-content-start align-items-center"
        style={{
          gap: '1rem',
        }}
      >
        <div>
          <label
            htmlFor="mkt-redirectURL"
            className="col-form-label py-0"
            style={{ fontWeight: 'bold' }}
          >
            {' '}
            Disparar evento de Lead no envio do formulário.
          </label>
        </div>

        <div className="button-switch mt-2">
          <input
            type="checkbox"
            id="lead-toggle"
            className="switch"
            checked={leadToggle}
            onChange={() => setLeadToggle((prevState) => !prevState)}
          />
        </div>
      </div>
      <div className="mb-2">
        <span className="text-left text-muted ">
          Se adicionado token ao pixel, enviaremos para o Meta, via API, também
          as informações capturadas do lead.
        </span>
      </div>
    </div>
  )
}
