import useSpacing from '../../components/microComponents/Spacing.js'
import CSS_CLASS from '../constants/cssClass.js'

let spacing
const componentTarget = function ({ component, keditor }) {
  const { elementsRef, snippetsClasses } = keditor.options
  let _component = () => component
  if (!component) {
    _component = () => keditor.getSettingComponent() || keditor.getSettingContainer()
  }

  let curComponent = elementsRef.getElement(_component().attr('id'))

  if (curComponent) return curComponent

  if (_component().hasClass(`${CSS_CLASS.CONTAINER}`)) {
    curComponent = _component().children().first().children().first()
  } else {
    for (let i = 0; i < snippetsClasses.length; i++) {
      if (_component().find(snippetsClasses[i]).length) curComponent = _component().find(snippetsClasses[i])
    }
  }
  if (!curComponent) curComponent = _component().find('.keditor-component-content').children().children().first()

  elementsRef.setElement(_component().attr('id'), curComponent)

  return curComponent
}

export default {
  initGlobal: function (form, keditor) {
    spacing = useSpacing({
      form,
      keditor,
      elementRef: () => componentTarget.call(this, { keditor }),
    })
    spacing.init()
  },

  showGlobal: function (form, component, keditor) {
    const curElement = componentTarget.call(this, { component, keditor })
    if (!curElement.find('.custom-html').length) {
      spacing = useSpacing({
        form,
        keditor,
        elementRef: () => curElement,
      })
      spacing.show()
    } else {
      curElement.removeAttr('data-margin_default')
      curElement.removeAttr('data-margin_mobile')
      curElement.css('marginTop', '')
    }
  },
}
