import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { usePopup } from '../../context/popupContext'
import './styles.css'

export const InputSize = ({ onChange, value, name }) => {
    const { state: { modalPopup } } = usePopup()
    if (!modalPopup) return null

    const { size } = modalPopup?.tabs?.styles?.config?.form?.inputs

    return (
        <div className="col-6">
            <label className="col-6">{size?.label}</label>
            <div className="col">
                <div className="padding-box-inner d-inline">
                    <div className="padding-box-inner-item">
                        <div className="input-group input-group-sm">
                            <input id="btn-font-size" type="number" name={name} className="form-control" value={value} onChange={onChange} />
                            <div className="input-group-append">
                                <span className="input-group-text">px</span>
                            </div>
                        </div>
                    </div>
                    <div className="padding-box-inner-item"></div>
                </div>
            </div>
        </div>
    )
}

export const InputSizeMobile = ({ onChange, inheritValue, value, name }) => {
    const { state: { modalPopup } } = usePopup()
    const [lock, setLock] = useState(true)
    const icons = {
        lock: 'fa fa-lock',
        unLock: 'fa fa-unlock',
    }
    const currentValue = lock ? inheritValue : value

    if (!modalPopup) return null

    const { size: { mobile } } = modalPopup?.tabs?.styles?.config?.form?.inputs

    const toggleLock = () => setLock(!lock)

    return (
        <div className="col-6">
            <div className="col d-flex">
                <span className="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock" onClick={toggleLock}>
                    <i className={lock ? icons.lock : icons.unLock} aria-hidden="true"></i>
                </span>
                <label htmlFor="${formRef}" className="d-flex">
                    {mobile?.label}
                </label>
            </div>
            {/* <label className="col-6">{mobile?.label}</label> */}
            <div className="col">
                <div className="padding-box-inner d-inline">
                    <div className="padding-box-inner-item">
                        <div className="input-group input-group-sm">
                            <input id="btn-font-size" type="number" name={name} className="form-control" value={currentValue} onChange={onChange} disabled={lock} />
                            <div className="input-group-append">
                                <span className="input-group-text">px</span>
                            </div>
                        </div>
                    </div>
                    <div className="padding-box-inner-item"></div>
                </div>
            </div>
        </div>
    )
}

export const InputFont = ({ onChange, value, name }) => {
    const { state: { modalPopup } } = usePopup()
    if (!modalPopup) return null

    const { font } = modalPopup?.tabs?.styles?.config?.form?.inputs
    const fontFamilyList = [
        'Roboto',
        'Fira Sans',
        'Poppins',
        'Fjalla One',
        'Lato',
        'Noto Sans',
        'Nunito Sans',
        'Montserrat',
        'Open Sans',
        'Oswald',
        'Oxygen',
        'Reenie Beanie',
        'Rock Salt',
        'Shadows Into Light Two',
        'Source Sans Pro',
        'Ubuntu',
        'Inter',
        'Raleway',
        'Sora'
    ]

    return (
        <div>
            <label className="col-6">{font?.label}</label>
            <div className="col">
                <select className="form-control" value={value} onChange={onChange} name={name}>
                    {fontFamilyList.map(font => (
                        <option key={font} value={font} style={{ fontFamily: font }}>{font}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export const TextEditor = ({ value, onChange }) => (
    <ReactQuill
        className='quil-text-editor'
        theme="snow"
        value={value}
        onChange={onChange}
        modules={{
            toolbar: ['bold', 'italic', 'underline', 'strike'],
        }}
    />
)
