export default function (width, self) {
  if(!self) return
  function setFontSize(el,device) {
      $(el).css('font-size', $(el).attr('data-font_size_' + device) + 'px')
      $(el).children().each(function () {
        var $currentElement = $(this);
        $currentElement.css('font-size', $(el).attr('data-font_size_' + device) + 'px')
      });
  }
  if (width !== "100%") {
      const elMobile = self.iframeBody.find('[data-font_size_mobile]')
      
      elMobile.each(function() {
        setFontSize(this, 'mobile')                
      });
  }else{
      const elDesktop = self.iframeBody.find('[data-font_size_default]')
      
      elDesktop.each(function() {
        setFontSize(this, 'default')                
      });
  }    
}
// Page final

// (function () {
//   function verifyFont() {
//        //this = window
//       function setFontSize(el,device) {
//           // $(el).css('font-size', $(el).attr('data-fontSize-' + device) + 'px')
//           const size = el.dataset[`font_size_${device}`] + 'px'
//           el.style.fontSize = size
//       }
//       if (window.innerWidth <= 425) {          
//           // const elMobile = $('[data-fontSize-mobile]')
//           const elMobile = document.querySelectorAll("[data-font_size_mobile]")
//           elMobile.forEach(function(el) {
//             setFontSize(el, 'mobile')                
//           });            
//       }else{
//           const elDesktop = document.querySelectorAll("[data-font_size_default]")
//           elDesktop.forEach(function(el) {
//             setFontSize(el, 'default')                
//           });
//       }
//   }

//   function windowOnResize() {
//     verifyFont()
//   }
  
//   window.onresize = windowOnResize;

//   verifyFont()
// }())