import CSS_CLASS from "../constants/cssClass";
import closeSidebar from "../sidebar/closeSidebar";
import openSidebar from "../sidebar/openSidebar";

export default function () {
	let self = this;
	let contentAreasWrapper = self.contentAreasWrapper;

	contentAreasWrapper.on("click", `.${CSS_CLASS.COMPONENT_SETTING}`, function (
		e
	) {
		e.preventDefault();

		let btn = $(this);
		let component = btn.closest(`.${CSS_CLASS.COMPONENT}`);
		// openSidebar.call(self, component);

		closeSidebar.call(self);
		setTimeout(() => {
			openSidebar.call(self, component);
		}, 100);
	});
}
