const STYLE_GLOBAL_CONFIG = {
  background: {
    style: '',
    color: '#fff',
    // gradient: {
    //   style: '',
    //   init: {
    //     color: 'rgba(0,0,0,0)',
    //     value: 0,
    //   },
    //   final: {
    //     color: 'rgba(0,0,0,0)',
    //     value: 100,
    //   },
    //   angle: 180,
    // },
    img: {
      src: '',
      repeat: 'no-repeat',
      position: '50% 0%',
      size: 'auto',
    },
  },
  font: {
    default: 'primary',
    style: '',
    primary: '#222',
    secondary: '#222',
    none: '#222',
    family: 'Roboto',
  },
}
export default STYLE_GLOBAL_CONFIG
