import CSS_CLASS from '../constants/cssClass';
import ACTION_TYPE from '../constants/actionType';
import setCopyContent from '../utils/setCopyContent';
import convertToContainer from '../container/convertToContainer';
import getComponentContent from '../component/getComponentContent';
import getContainerContent from '../container/getContainerContent';
import convertToComponent from '../component/convertToComponent';
import closeSidebar from '../sidebar/closeSidebar';
import { removeShowedElements } from '../../keditor/helpers/helpers';

export default function () {
  let self = this;
  let contentAreasWrapper = self.contentAreasWrapper;

  contentAreasWrapper.on('click', `.${CSS_CLASS.PASTE_CONTENT}`, function (e) {
    e.preventDefault();
    setTimeout(() => {
      closeSidebar.call(self);
    }, 1);
    let btn = $(this);
    let isCopy = !!self.copyContent;

    self.iframeBody.find(`.${CSS_CLASS.COMPONENT_CANCEL}`).hide();
    self.iframeBody.find(`.${CSS_CLASS.CONTAINER_CANCEL}`).hide();

    self.iframeBody.find('.mf-copy-content').hide();

    self.iframeBody.find(`.${CSS_CLASS.COMPONENT_COPY}`).attr('style', 'display: block !important;');
    self.iframeBody.find(`.${CSS_CLASS.CONTAINER_COPY}`).attr('style', 'display: block !important;');

    // Check source
    let source = isCopy ? self.copyContent : self.cutContent;
    let isComponent = source.hasClass(CSS_CLASS.COMPONENT);
    let isContainer = source.hasClass(CSS_CLASS.CONTAINER);
    let isSubContainer = source.hasClass(CSS_CLASS.SUB_CONTAINER);
    let pasteContent;
    if (isCopy) {
      pasteContent = $(
        isComponent
          ? getComponentContent.call(self, source)
          : getContainerContent.call(self, source, isSubContainer, 'paste')
      );
    } else {
      pasteContent = source;
    }

    // Check target
    let target = null;

    let toolbarBottom = btn.closest(`.${CSS_CLASS.TOOLBAR_BOTTOM}`);
    if (toolbarBottom.length > 0) {
      if (toolbarBottom.hasClass(CSS_CLASS.TOOLBAR_CONTAINER_BOTTOM)) {
        target = btn.closest(`.${CSS_CLASS.CONTAINER}`);
      }

      if (toolbarBottom.hasClass(CSS_CLASS.TOOLBAR_COMPONENT_BOTTOM)) {
        target = btn.closest(`.${CSS_CLASS.COMPONENT}`);
      }
    }

    let toolbarContainerContent = btn.closest(`.${CSS_CLASS.TOOLBAR_CONTAINER_CONTENT}`);
    if (toolbarContainerContent.length > 0) {
      target = toolbarContainerContent.siblings(`.${CSS_CLASS.CONTAINER_CONTENT_INNER}`);
    }

    let toolbarContentArea = btn.closest(`.${CSS_CLASS.TOOLBAR_CONTENT_AREA}`);
    if (toolbarContentArea.length > 0) {
      target = toolbarContentArea.siblings(`.${CSS_CLASS.CONTENT_AREA_INNER}`);
    }

    // Check action
    let action = null;
    if (toolbarBottom.length > 0) {
      action = ACTION_TYPE.AFTER;
    } else {
      action = ACTION_TYPE.APPEND;
    }

    target[action](pasteContent);
    setCopyContent.call(self, null);

    if (isCopy) {
      if (isComponent) {
        convertToComponent.call(self, pasteContent, false, 'paste');
      }

      if (isContainer) {
        convertToContainer.call(self, pasteContent, 'paste');
      }
    }
    removeShowedElements(contentAreasWrapper)
    if (typeof self.options.onInitEmptyContainers === "function") {
      self.options.onInitEmptyContainers.call(self, self.contentAreasWrapper);
    }
  });
  contentAreasWrapper.on('click', `.mf-copy-content`, function (e) {
    e.preventDefault();
    setTimeout(() => {
      closeSidebar.call(self);
    }, 1);
    let btn = $(this);
    let isCopy = !!self.copyContent;

    self.iframeBody.find(`.${CSS_CLASS.COMPONENT_CANCEL}`).hide();
    self.iframeBody.find(`.${CSS_CLASS.CONTAINER_CANCEL}`).hide();

    btn.hide();
    self.iframeBody.find('.mf-copy-content').hide();

    self.iframeBody.find(`.${CSS_CLASS.COMPONENT_COPY}`).attr('style', 'display: block !important;');
    self.iframeBody.find(`.${CSS_CLASS.CONTAINER_COPY}`).attr('style', 'display: block !important;');

    // Check source
    let source = isCopy ? self.copyContent : self.cutContent;

    let isContainer = source.hasClass(CSS_CLASS.CONTAINER);
    let isSubContainer = source.hasClass(CSS_CLASS.SUB_CONTAINER);
    let pasteContent;

    if (isCopy) {
      pasteContent = $(getContainerContent.call(self, source, isSubContainer, 'paste'));
    } else {
      pasteContent = source;
    }

    // Check target
    let target = btn.closest(`.${CSS_CLASS.CONTAINER}`);

    // Check action
    let action = ACTION_TYPE.AFTER;

    target[action](pasteContent);
    setCopyContent.call(self, null);

    if (isCopy) {
      if (isContainer) {
        convertToContainer.call(self, pasteContent, 'paste');
      }
    }
    removeShowedElements(contentAreasWrapper)
    if (typeof self.options.onInitEmptyContainers === "function") {
      self.options.onInitEmptyContainers.call(self, self.contentAreasWrapper);
    }
  });
}
