import React, { forwardRef, useEffect, useRef } from 'react'
import { usePopup } from '../../context/popupContext'
import { BUTTON_ACTION, MODAL_ID } from '../../shared/constants'
import { PreviewBase } from './PreviewBase'

const CustomButton = forwardRef(({ as = 'button', children, ...rest }, ref) => {
  const As = as;
  return <As ref={ref} {...rest} dangerouslySetInnerHTML={{ __html: children }} />;
});

const PreviewOriginal = (props, ref) => {
  const {
    state: { contentPopup },
  } = usePopup()
  const css = String.raw
  const { body, title, cta, form, styles } = contentPopup
  const ctaAction = cta?.action
  const hasForm = ctaAction === BUTTON_ACTION.SUBMIT
  const hasPhoneForm = form.phone.status
  const hasNameForm = form?.name?.status
  const ctaRef = useRef(null)

  const getButtonProps = () => {
    switch (ctaAction) {
      case BUTTON_ACTION.CLOSE_MODAL:
        ctaRef?.current?.removeAttribute('onclick')
        return {
          type: 'button',
          'data-dismiss': "modal",
        }
      case BUTTON_ACTION.REDIRECT:
        return {
          as: 'a',
          role: "button",
          href: cta.href
        }
      case BUTTON_ACTION.SUBMIT:
        return ctaRef?.current?.setAttribute('onclick', "submitForm()")
    }
  }

  useEffect(() => {
    if (ctaAction === BUTTON_ACTION.SUBMIT) {
      ctaRef?.current?.setAttribute('onclick', "submitForm()")
    }
  }, [ctaRef.current])

  return (
    <>
      <PreviewBase>
        <div
          ref={ref}
          className="modal fade"
          id={MODAL_ID}
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <style>
            {css`
              .popup-header {
                padding: 0.3rem 0.8rem;
              }

              .modal-content.popup {
                text-align: center;
              }

              .modal-dialog.popup-dialog .modal-content.popup {
                background-color: ${styles.wrapper.backgroundColor} !important;
              }

              .modal-content.popup h3.popup-title, .modal-content.popup h3.popup-title p {
                color: ${styles.title.color};                
                font-family: ${styles.title.font} !important;
                padding-bottom: 1rem;
                font-size: ${styles.title.size}px !important;
              }
              
              .modal-content.popup .popup-body, .modal-content.popup .popup-body p {
                color: ${styles.body.color};
                font-size: ${styles.body.size}px !important;
                font-family: ${styles.body.font} !important;
                padding-bottom: 1rem;
              }

              .modal-content.popup .cta {
                width: 100%;
                background-color: ${styles.cta.background};
                border-color: ${styles.cta.background};
                color: ${styles.cta.color};
                font-size: ${styles.cta.size}px !important;
                font-family: ${styles.cta.font} !important;
                text-transform: uppercase;
              }
              .modal-content.popup .cta.btn {
                padding: 12px 12px;
              }
              .modal-content.popup .cta p {
                font-size: ${styles.cta.size}px !important;
                font-family: ${styles.cta.font} !important;
                text-transform: uppercase;
                color: ${styles.cta.color};
              }
              
              .modal-content.popup .cta:hover {
                border-color: ${styles.cta.background};
                opacity: 0.7;
              }
              .modal-content.popup .cta:active {
                border-color: ${styles.cta.background};
                background-color: ${styles.cta.background};
              }
              .modal-content.popup .cta:focus {
                box-shadow: 0 0 0 0.2rem ${styles.cta.background};
              }
              .keditor-modal .modal-dialog.popup-dialog {
                max-width: 500px !important;
              }

              .keditor-modal .modal-dialog.popup-dialog .modal-content.popup .close{
                color: initial;
              }

              .keditor-modal .modal-dialog.popup-dialog .modal-body .form-control {
                background: #fff !important;
                height: 38px;
              }

              @media only screen and (max-width: 768px) {
                /* For mobile phones: */
                .modal-content.popup h3.popup-title {
                  font-size: ${styles.title.mobile.size}px;
                }
                .modal-content.popup .popup-body {
                  font-size: ${styles.body.mobile.size}px;
                }
                .modal-content.popup .cta {
                  font-size: ${styles.cta.mobile.size}px;
                }
              }
            `}
          </style>
          <div className="modal-dialog popup-dialog" role="document">
            <div className="modal-content popup">
              <div className="popup-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h3 className="popup-title" dangerouslySetInnerHTML={{ __html: title }} />
                <div className="popup-body" dangerouslySetInnerHTML={{ __html: body }} />
                {hasForm && (
                  <form>
                    {hasNameForm && (
                      <div className="form-group">
                        {/* <label for="exampleInputPassword1">Password</label> */}
                        <input
                          className="form-control"
                          name='name'
                          type="text"
                          placeholder={form.name.placeholder}
                        />
                      </div>
                    )}
                    <div className="form-group">
                      {/* <label for="exampleInputEmail1">Email address</label> */}
                      <input
                        className="form-control"
                        type="email"
                        name='email'
                        placeholder={form.email.placeholder}
                      />
                    </div>
                    {hasPhoneForm && (
                      <div className="form-group">
                        {/* <label for="exampleInputPassword1">Password</label> */}
                        <input
                          className="form-control"
                          name='phone'
                          type="text"
                          placeholder={form.phone.placeholder}
                        />
                      </div>
                    )}
                  </form>
                )}
                <CustomButton
                  ref={ctaRef}
                  {...getButtonProps()}
                  className="btn cta">
                  {cta.text}
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </PreviewBase>
    </>
  )
}

export const Preview = React.forwardRef(PreviewOriginal)
