import openGalleryButton from '../../../../components/helpers/gallery.template'
import { clearButton, inputCards } from './helpers.templates'

const FORM_BG_IMAGE = /*html*/ `
<div class="row">
  ${clearButton('img')}
  ${openGalleryButton()}
  
  <div class="col-sm-12 mt-3">
    <label>Tamanho da imagem</label>
    <ul
      class="nav nav-pills"
      id="background-size"
      role="tablist"
    >
      <li class="nav-item col-sm-4 px-0" >
        <a
          class="nav-link nav-item-custom active w-100"
          id="contain"
          data-target='size'
          data-toggle="pill"
          href="#"
          role="tab"
          data-value="contain"
          aria-controls=""
          aria-selected="true"
          >Conter</a
        >
      </li>
      <li class="nav-item col-sm-4 px-0">
        <a
          class="nav-link nav-item-custom w-100"
          id="cover"
          data-target='size'
          data-toggle="pill"
          href="#"
          role="tab"
          data-value="cover"
          aria-controls=""
          aria-selected="false"
          >Cobrir</a
        >
      </li>
      <li class="nav-item col-sm-4 px-0">
        <a
          class="nav-link nav-item-custom w-100"
          id="auto"
          data-toggle="pill"
          data-target='size'
          href="#"
          role="tab"
          data-value="auto"
          aria-controls=""
          aria-selected="false"
          >Auto</a
        >
      </li>
    </ul>
  </div>
  <div class="col-sm-6 mt-3">
    <label>Posição da imagem</label>
    <ul
      class="nav nav-pills"
      id="background-position"
      role="tablist"
    >
      ${inputCards({
        value: '0% 0%',
        icon: 'arrow-up',
        rotate: -45,
        target: 'position',
      })}
      ${inputCards({
        value: '50% 0%',
        icon: 'arrow-up',
        target: 'position',
      })}
      ${inputCards({
        value: '100% 0%',
        icon: 'arrow-up',
        rotate: 45,
        target: 'position',
      })}

      ${inputCards({
        value: '0% 50%',
        icon: 'arrow-left',
        target: 'position',
      })}
      ${inputCards({
        value: '50% 50%',
        icon: 'circle',
        target: 'position',
      })}
      ${inputCards({
        value: '100% 50%',
        icon: 'arrow-right',
        target: 'position',
      })}

      ${inputCards({
        value: '0% 100%',
        icon: 'arrow-down',
        rotate: 45,
        target: 'position',
      })}
      ${inputCards({
        value: '50% 100%',
        icon: 'arrow-down',
        target: 'position',
      })}
      ${inputCards({
        value: '100% 100%',
        icon: 'arrow-down',
        rotate: -45,
        target: 'position',
      })}
    </ul>
  </div>
  <div class="col-sm-2"></div>
  <div class="col-sm-4 mt-3">
    <label>Repetição</label>
    <ul
      class="nav nav-pills"
      id="background-repeat"
      role="tablist"
    >
    ${inputCards({
      value: 'repeat-x',
      icon: 'arrows-h',
      size: 6,
      target: 'repeat',
    })}
    ${inputCards({
      value: 'repeat-y',
      icon: 'arrows-v',
      size: 6,
      target: 'repeat',
    })}
    ${inputCards({
      value: 'repeat',
      icon: 'arrows',
      size: 6,
      target: 'repeat',
    })}
    ${inputCards({
      value: 'no-repeat',
      icon: 'times',
      size: 6,
      target: 'repeat',
    })}
    </ul>
  </div>
</div>
`

export { FORM_BG_IMAGE }
