import CSS_CLASS from "../constants/cssClass";
import SETTING_CATEGORY from "../constants/settingCategory";
import closeSidebar from "./closeSidebar";
import initSettingForm from "./initSettingForm";
let first = true;
export default function (
  target,
  settingType,
  settingCategory,
  settingTitle,
  initFunction,
  showFunction,
  functionContext
) {
  let self = this;
  let sidebar = self.sidebar;
  let sidebarTitle = self.sidebarTitle;
  let sidebarBody = self.sidebarBody;
  const open = () => sidebar.addClass(CSS_CLASS.STATE_OPENED);

  let { settingForm, isExisting } = initSettingForm.call(
    self,
    target,
    settingType,
    settingCategory,
    initFunction,
    functionContext,
    () => {
      if (typeof showFunction === "function") {
        $.when(showFunction.call(functionContext, settingForm, target, self)).done(
          function () {
            open()              
          }
        );
      }
    }
  );

  let shouldCloseSidebar =
    settingForm.hasClass(CSS_CLASS.STATE_ACTIVE) &&
    (target.is(self.settingContainer) ||
      target.is(self.settingComponent) ||
      target.is("[data-extra-setting]"));

  self.settingComponent = null;
  self.settingContainer = null;
  switch (settingCategory) {
    case SETTING_CATEGORY.COMPONENT:
      self.settingComponent = target;
      break;

    case SETTING_CATEGORY.CONTAINER:
      self.settingContainer = target;
      break;

    default:
    // Do nothing
  }
  if (1) {
    const formCollapse = sidebarBody.find(".keditor-setting-form");
    first = true;
    formCollapse
      .find(".form-settings-head")
      .off("click")
      .on("click", function () {
        // first = true;
        var curelement = $(this);
        var nextGroup = curelement.next();
        nextGroup.slideToggle("fast", function () {
          nextGroup.css("display") == "none"
            ? curelement
                .find("i")
                .removeClass("fa-caret-down")
                .addClass("fa-caret-right")
            : curelement
                .find("i")
                .removeClass("fa-caret-right")
                .addClass("fa-caret-down");
        });
      });
  }

  if (isExisting) {
    if (shouldCloseSidebar) {
      closeSidebar.call(self);
      return;
    } else {
      if (typeof showFunction === "function") {
        $.when(showFunction.call(functionContext, settingForm, target, self)).done(
          function () {
            open()              
          }
        );
      }
    }
  }

  sidebarTitle.html(settingTitle);
  sidebarBody
    .children(`.${CSS_CLASS.STATE_ACTIVE}`)
    .removeClass(CSS_CLASS.STATE_ACTIVE);
  settingForm.addClass(CSS_CLASS.STATE_ACTIVE);
  self.iframeBody.addClass(CSS_CLASS.STATE_SIDEBAR_SHOWED);  
}
