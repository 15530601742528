import ACTION_TYPE from '../constants/actionType';
import CSS_CLASS from '../constants/cssClass';
import closeSidebar from '../sidebar/closeSidebar';
import showSnippetSidebarLeft from '../sidebarLeft/showSnippetSidebarLeft';
import { removeShowedElements, clearBtnFocus } from '../../keditor/helpers/helpers';
import { IN_FOCUS } from '../../constants';

export default function () {
  let self = this;
  let contentAreasWrapper = self.contentAreasWrapper;

  contentAreasWrapper.on('click', `.${CSS_CLASS.ADD_CONTENT}`, function (e) {
    e.preventDefault();
    let btn = $(this);
    let toolbar = btn.parent() 
    let container;
    
    if(toolbar.hasClass(CSS_CLASS.TOOLBAR_CONTENT_AREA)){
      container = toolbar.siblings(`.${CSS_CLASS.CONTENT_AREA_INNER}`)  
    }else {
      container = toolbar.siblings(`.${CSS_CLASS.CONTAINER_CONTENT_INNER}`)
    }
    let isNested = btn.closest('.keditor-sub-container').length > 0;
        
    closeSidebar.call(self);
    showSnippetSidebarLeft.call(self, container, ACTION_TYPE.APPEND, true, !isNested);

    removeShowedElements(contentAreasWrapper)
    clearBtnFocus(contentAreasWrapper)

    btn.addClass(IN_FOCUS);
  });
}
