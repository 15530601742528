import STYLE_GLOBAL_CONFIG from './constant'
function useImageConfig({ form, keditor }) {
  const constant = keditor.options.globalStyle
  const bgSize = '#background-size'
  const bgPosition = '#background-position'
  const bgRepeat = '#background-repeat'

  const init = () => {
    form.find('#external-bg-url').on('keyup', function (e) {
      const { value } = e.target
      constant.background.img.src = value
    })

    form.find(bgSize).on('click', '.nav-item', function (e) {
      const value = $(e.target).attr('data-value')
      constant.background.img.size = value
    })

    form.find(bgPosition).on('click', '.nav-item', function (e) {
      const value = $(e.target).attr('data-value')
      constant.background.img.position = value
    })

    form.find(bgRepeat).on('click', '.nav-item', function (e) {
      const value = $(e.target).attr('data-value')
      constant.background.img.repeat = value
    })
  }

  const show = () => {
    const {
      background: { img },
    } = constant

    if (img.src) {
      form.find('.change-image-button').css({ backgroundImage: `url(${img.src})` })

      form.find('.upload-button').hide()
      form.find('.change-image-button').show()
    } else {
      form.find('.upload-button').show()
      form.find('.change-image-button').hide()
    }

    form.find('#external-bg-url').val(img.src)

    form.find(bgSize).find(`.nav-link[data-target="size"]`).removeClass('active')
    form.find(bgSize).find(`.nav-link[data-value="${img.size}"]`).addClass('active')

    form.find(bgPosition).find(`.nav-link[data-target="position"]`).removeClass('active')
    form.find(bgPosition).find(`.nav-link[data-value="${img.position}"]`).addClass('active')

    form.find(bgRepeat).find(`.nav-link[data-target="repeat"]`).removeClass('active')
    form.find(bgRepeat).find(`.nav-link[data-value="${img.repeat}"]`).addClass('active')
  }

  return {
    init,
    show,
  }
}

export default useImageConfig
