import CSS_CLASS from '../../constants/cssClass'

export function ModalBase({ id, title, body, footer, info, tabs = '', size = 'medium' }) {
  return /*html*/ `
    <div class="${CSS_CLASS.MODAL} fade" tabindex="-1" role="dialog" id="${id}">
      <div class="${CSS_CLASS.MODAL_DIALOG} ${size}">
        <div class="modal-content ${size} ${tabs ? 'modal-tab' : ''}">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">${title}</h5>
            <button type="button" id="close-modal" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          ${tabs}
          <div class="modal-body ">
            ${body || ''}
          </div>
          ${
            footer
              ? /*html*/ `
              <div class="modal-footer">
                ${
                  info
                    ? /*html*/ `
                  <div class="note">
                    <img src="/bundle/assets/icons/info.svg" alt="" srcset="" />
                    <div class="d-flex flex-column px-3">
                      <span style="font-weight: bold">
                        Nota:
                      </span>
                      <div>
                        ${info}
                      </div>
                    </div>
                  </div>
                  `
                    : ''
                }
                <div class="buttons">
                  ${footer}
                </div>
              </div>
            `
              : ''
          }
        </div>
      </div>
    </div>
  `
}
