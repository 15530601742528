import React from 'react'
import CSS_CLASS from '../../../../keditor/constants/cssClass'
import {
  BTN_INTEGRATION_MODAL_REF,
  INTEGRATIONS_PANEL_VIEW,
} from '../../constants'
import './styles.css'
import { useIntegration } from '../../context/integrationContext'

export const Modal = ({ children }) => {
  const { setPanelView } = useIntegration()
  return (
    <div
      className={`${CSS_CLASS.MODAL} fade`}
      tabIndex="-1"
      role="dialog"
      id={`${BTN_INTEGRATION_MODAL_REF}`}
    >
      <div className={`${CSS_CLASS.MODAL_DIALOG} modal-w50`}>
        <div className="modal-content modal-tab">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              INTEGRAÇÕES
            </h5>
            <button
              type="button"
              id="close-modal"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                setTimeout(
                  () => setPanelView(INTEGRATIONS_PANEL_VIEW.LIST),
                  1000
                )
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
