const TOOLBAR_TYPE = {
    CONTENT_AREA: 1000,
    
    CONTAINER: 2000,
    CONTAINER_CONTENT: 2100,
    CONTAINER_BOTTOM: 2200,
    
    SUB_CONTAINER: 3000,
    SUB_CONTAINER_BOTTOM: 3100,
    SUB_CONTAINER_CONTENT: 3200,
    
    COMPONENT: 4000,
    COMPONENT_BOTTOM: 4100,
};

export default TOOLBAR_TYPE;
