import React, { memo } from 'react'
import { useAsync } from '../../../utils'
import { useOutsideClick, usePrevious } from '../../hooks'
import { getResource } from '../../../../services/integrations'

export const ResourcesSearch = memo(
  ({
    initialSelectedValue,
    setOptionSelected,
    service,
    resourceContent,
    dependences,
  }) => {
    const { status, run } = useAsync()
    const { resource } = resourceContent
    const [query, setQuery] = React.useState('')
    const dropdownRef = React.useRef(null)
    const [showDropdown, setShowDropdown] = React.useState(false)
    useOutsideClick(dropdownRef, () => setShowDropdown(false))
    const [resourceOptions, setResourceOptions] = React.useState([])
    const [optionSelect, setOptionSelect] = React.useState(() => {
      if (!initialSelectedValue) return { name: null }
      return {
        name: null,
        ...initialSelectedValue,
      }
    })
    const prevDependences = usePrevious(dependences)

    // if dependeces.find(...) return true that means all values of dependeces was marked. So if we negate this afirmative
    // that means no have more dependeces
    const hasDependeces = dependences && !dependences.find((el) => el.value)

    const handleChangeSearch = (e) => {
      const { value } = e.target
      setQuery(value)
    }

    const handleSelectResourceOption = (option) => {
      setOptionSelect(option)
      setOptionSelected({
        value: option[resourceContent.attribute],
        name: option.name,
      })
      setResourceOptions([])
      setQuery('')
      setShowDropdown(false)
    }

    const fetchResource = (resourceQuery) => {
      run(
        getResource(service, resource, dependences, resourceQuery).then(
          (response) => {
            const options = response.data.data
            if (!options) return setResourceOptions([])
            return setResourceOptions(options)
          }
        )
      )
    }

    const openDropdown = () => {
      if (!showDropdown) {
        fetchResource('')
        setShowDropdown(true)
      }
    }

    React.useEffect(() => {
      let timeOutId
      if (query !== undefined && query !== null) {
        timeOutId = setTimeout(() => fetchResource(query), 500)
      }
      return () => clearTimeout(timeOutId)
    }, [query])

    React.useEffect(() => {
      const diff = () => {
        if (!prevDependences) return
        for (let index = 0; index < prevDependences.length; index += 1) {
          if (prevDependences[index].value !== dependences[index].value) {
            setOptionSelect({ name: null })
            return
          }
        }
      }
      diff()
    }, [dependences])

    return (
      <div
        className="dropdown dropup"
        ref={dropdownRef}
        style={{
          opacity: hasDependeces ? '0.5' : '1',
          pointerEvents: hasDependeces ? 'none' : 'auto',
        }}
      >
        <button
          type="button"
          className="col-sm-12 btn btn-dropdown dropdown-toggle d-flex align-items-center justify-content-between"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={openDropdown}
        >
          {optionSelect.resource_name ||
            optionSelect.name ||
            'Selecionar uma opção...'}
        </button>
        <div
          className="dropdown-menu"
          style={{
            display: showDropdown ? 'block' : 'none',
            height: '200px',
            overflowY: 'scroll',
          }}
        >
          <div className="dropdown-header">
            <input
              onChange={handleChangeSearch}
              placeholder="Digite um item"
              type="search"
              className="form-control searchIcon"
              value={query}
            />
          </div>
          {status === 'pending' && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="d-flex dropdown-item align-items-center px-3"
              disabled
            >
              Carregando... Isso pode levar vários segundos.
            </a>
          )}
          {status === 'resolved' &&
            resourceOptions.map((option) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={`key_${option.id}`}
                className="d-flex dropdown-item align-items-center px-3"
                onClick={() => handleSelectResourceOption(option)}
                onKeyUp={(event) => {
                  // Check if the pressed key is 'Enter'
                  if (event.key === 'Enter') {
                    handleSelectResourceOption(option)
                  }
                }}
                role="link"
                tabIndex={0}
              >
                {option.name}
              </a>
            ))}
        </div>
      </div>
    )
  }
)

ResourcesSearch.displayName = 'ResourcesSearch'
