import CSS_CLASS from "../constants/cssClass";
import generateId from "../utils/generateId";
import renderSnippets from "../snippet/renderSnippets";
import initSnippetsFilter from "../snippet/initSnippetsFilter";
import hideSnippetSidebarLeft from "./hideSnippetSidebarLeft";
import initSnippetAction from "./initSnippetAction";
import addSnippetToTarget from "../snippet/addSnippetToTarget";
import initSidebarLeft from "./initSidebarLeft";
import closeSidebar from "../sidebar/closeSidebar";

export default function () {
  let self = this;
  let options = self.options;
  let sidebarLeft = (self.sidebarLeft = initSidebarLeft.call(
    self,
    generateId(),
    false,
    true
  ));

  if (
    typeof options.snippetsUrl === "string" &&
    options.snippetsUrl.length > 0
  ) {
    sidebarLeft
      .find(`.${CSS_CLASS.SIDEBAR_LEFT_BODY}`)
      .append(`<div class="${CSS_CLASS.BOOTSTRAP_TAB_CONTENT}"></div>`);

    $.ajax({
      type: "get",
      dataType: "html",
      url: options.snippetsUrl,
      success: function (resp) {
        if (typeof options.onSnippetsLoaded === "function") {
          resp = options.onSnippetsLoaded.call(self, resp) || resp;
        }

        //render nav-tabs and snippets
        renderSnippets.call(self, resp);
        //render filters
        initSnippetsFilter.call(self);
      },
      error: function (jqXHR) {
        if (typeof options.onSnippetsError === "function") {
          options.onSnippetsError.call(self, jqXHR);
        }
      },
    });

    // Snippet events
    initSnippetAction.call(self);

    // Close buttons
    sidebarLeft
      .find(`.${CSS_CLASS.SIDEBAR_LEFT_CLOSER}`)
      .on("click", function (e) {
        e.preventDefault();

        hideSnippetSidebarLeft.call(self);
      });

    // Add buttons
    sidebarLeft.on("click", `.${CSS_CLASS.SNIPPET}`, function (e) {
      e.preventDefault();

      let selectedSnippet = $(this);

      addSnippetToTarget.call(
        self,
        e,
        selectedSnippet,
        self.sidebarLeftTarget,
        self.sidebarLeftTargetAction
      );
      hideSnippetSidebarLeft.call(self);
      closeSidebar.call(self);
    });
  } else {
    self.error('"snippetsUrl" must be not null!');
  }
}
