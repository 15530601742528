import React from 'react'
import './styles.css'

export const ItemCard = ({ name, onClick, disabled }) => (
  <div
    className={`card h-100 mb-3 item-card ${disabled && 'disabled'}`}
    onClick={onClick}
    onKeyUp={onClick}
    role="button"
    tabIndex="0"
  >
    <div className="card-body d-flex justify-content-center align-items-center">
      <h5 className="card-title">{name}</h5>
    </div>
  </div>
)
