import CSS_CLASS from './cssClass'
import {
  toggleEmptyContainers,
  initContentAreaState,
} from '../../components/helpers/helpers'

import { loadEditor } from '../helpers/helpers'

import closeSidebar from '../sidebar/closeSidebar'

import { INTEGRATIONS_TYPE } from '../../constants'

export default {
  /*
   * Title will be showed in left of topbar
   * @option {String}
   */
  title: 'Editing with KEditor',

  /*
   * containerForQuickAddComponent The container snippet which will be added automatically in content are when you adding a component. Note: component will be added in first container content of container
   * @option {String}
   */
  containerForQuickAddComponent: `
        <div class="d-flex justify-content-center">
          <div class="custom-container" data-type="container-content">
          </div>
        </div>
    `,

  /*
   * Extra settings in sidebar
   * @option {Object<String, Object>}
   * @format:
   * {
   *     settingName: {
   *         title,
   *         content,
   *         trigger
   *         settingShowFunction
   *     }
   * }
   * @settingName.option {String} title
   * @settingName.option {jQuery|Function|String} trigger If pass as function, argument will be current extra setting. This function must return a jQuery object
   * @settingName.option {Boolean} autoInit By default, all settings form of KEditor will be initialized in first time you show it. If you want it to initialize intermediately, just set `autoIni=true`
   * @settingName.option {Function} settingInitFunction Same arguments with `containerSettingInitFunction`
   * @settingName.option {Function} settingShowFunction Same arguments with `containerSettingShowFunction`
   * @example:
   * {
   *     settingName1: {
   *         title: 'Page Settings',
   *         trigger: $('.btn-page-setting'),
   *         autoInit: true,
   *         settingInitFunction: function (form, keditor) {
   *             form.append('<div>This is content of page settings</div>');
   *         },
   *         settingShowFunction: function (form, trigger, keditor) {
   *             // Do something
   *         }
   *     },
   *     settingName2: {
   *         title: 'Page Settings 2',
   *         triggerSelector: '.btn-page-settings',
   *         trigger: function (extraSetting) {
   *             return $(extraSetting.triggerSelector);
   *         },
   *         settingInitFunction: function (form, keditor) {
   *             form.append('<div>This is content of page settings 2</div>');
   *         },
   *         settingShowFunction: function (form, trigger, keditor) {
   *             // Do something
   *         }
   *     },
   *     settingName3: {
   *         title: 'Page Settings 3',
   *         trigger: '.btn-page-settings',
   *         settingInitFunction: function (form, keditor) {
   *             form.append('<div>This is content of page settings 3</div>');
   *         },
   *         settingShowFunction: function (form, trigger, keditor) {
   *             // Do something
   *         }
   *     }
   * }
   */
  extraSettings: null,

  /*
   * Extra items in topbar
   * @option {Array<Object>}
   * @format:
   * {
   *     itemName: {
   *         html,
   *         click
   *     }
   * }
   * @itemName.option {String} html HTML string for extra item
   * @itemName.option {Function} click Handler for click event of extra item
   */
  extraTopbarItems: null,

  sidebarLeft: {
    title: 'Seleção de componentes',
    nav: {
      items: [
        {
          title: 'Elementos',
          id: 'elements',
          numColumns: 2,
          snippetCategories: [
            'Layouts',
            'Medias',
            'Texts',
            'Buttons',
            // "Socials",
            // "Sliders",
            // "Navigations",
            // "Forms",
            // "Counters",
            'Extras',
          ],
        },
        {
          title: 'Blocos',
          id: 'blocks',
          numColumns: 2,
          snippetCategories: [
            'Headers',
            // "Launchs",
            // "Bites",
            'Features',
            'Authors',
            'Testemonials',
            'Offers',
            // "Forms",
            'Footer',
            'Extras',
          ],
        },
      ],
    },
  },

  /*
   * Editor visual language, english by default
   * @option {String}
   */
  lang: 'en',

  locale: {
    /*
     * Text title for devices switcher
     * @option {String}
     */
    viewOnMobile: 'View on mobile',
    viewOnTablet: 'View on tablet',
    viewOnLaptop: 'View on laptop',
    viewOnDesktop: 'View on desktop',

    /*
     * Text title for preview mode
     * @option {String}
     */
    previewOn: 'Preview ON',
    previewOff: 'Preview OFF',

    /*
     * Text title for fullscreen mode
     * @option {String}
     */
    fullscreenOn: 'Fullscreen ON',
    fullscreenOff: 'Fullscreen Off',

    /*
     * Text title for save button
     * @option {String}
     */
    save: 'Save',

    /*
     * Text title for Warning Unsaved flag
     * @option {String}
     */
    contentUnsaved: 'Unsaved content',

    /*
     * Text title for add content button
     * @option {String}
     */
    addContent: 'Add content',

    /*
     * Text title for add content button below sub-container and component
     * @option {String}
     */
    addContentBelow: 'Add content below',

    /*
     * Text title for paste content button
     * @option {String}
     */
    pasteContent: 'Paste content',

    /*
     * Text title for paste content button below sub-container and component
     * @option {String}
     */
    pasteContentBelow: 'Paste content below',

    /*
     * Text title for move button
     * @option {String}
     */
    move: 'Drag',

    /*
     * Text title for move up button
     * @option {String}
     */
    moveUp: 'Move up',

    /*
     * Text title for move down button
     * @option {String}
     */
    moveDown: 'Move down',

    /*
     * Text title for setting button
     * @option {String}
     */
    setting: 'Setting',

    /*
     * Text title for copy button
     * @option {String}
     */
    copy: 'Copy',

    /*
     * Text title for cut button
     * @option {String}
     */
    cut: 'Cut',

    /*
     * Text title for delete button
     * @option {String}
     */
    delete: 'Delete',

    /*
     * Text title for close button
     * @option {String}
     */
    close: 'Close',

    /*
     * Text title for Insert Code button
     * @option {String}
     */
    insertCode: 'Insert Code',

    /*
     * Text title for Insert Info button
     * @option {String}
     */
    insertInfo: 'Insert Informations',

    /*
     * Text for snippet category label
     * @option {String}
     */
    snippetCategoryLabel: 'Category',

    /*
     * Text for snippet category `All`
     * @option {String}
     */
    snippetCategoryAll: 'All',

    /*
     * Text for snippet categories
     * @option {String}
     */
    snippetCategories: {
      navItem0: [
        'Layouts',
        'Medias',
        'Texts',
        'Buttons',
        // "Socials",
        // "Sliders",
        // "Navigations",
        // "Forms",
        // "Counters",
        'Extras',
      ],
      navItem1: [
        'Headers',
        // "Launchs",
        // "Bites",
        'Features',
        'Authors',
        'Testemonials',
        'Offers',
        // "Forms",
        'Footer',
        'Extras',
      ],
    },

    /*
     * Text for snippet search text box
     * @option {String}
     */
    snippetCategorySearch: 'Type to search...',

    /*
     * Text title for bootstrap column resizer
     * @option {String}
     */
    columnResizeTitle: 'Drag to resize',

    /*
     * Text title for container setting
     * @option {String}
     */
    containerSetting: 'Container Settings',

    /*
     * Text content for confirm dialog when deleting container
     * @option {String}
     */
    confirmDeleteContainerText:
      'Are you sure that you want to delete this container? This action can not be undone!',

    /*
     * Text content for confirm dialog when deleting container
     * @option {String}
     */
    confirmDeleteComponentText:
      'Are you sure that you want to delete this component? This action can not be undone!',

    /*
     * Text content for Pixel Modal
     * @option {String}
     */
    modalTracking: {
      fb: {
        title: 'Facebook Pixel',
        subtitle: '',
        fillwith: '',
        placeholder: '',
        icon: 'code',
        disclaimer: '',
      },
      ga: {
        title: 'Google Analytics ID',
        subtitle: '',
        fillwith: '',
        placeholder: 'G-',
        icon: 'line-chart',
      },
      gtm: {
        title: 'Google Tag Manager',
        subtitle: '',
        fillwith: '',
        placeholder: 'GTM-',
        icon: 'google',
      },
    },

    /*
     * Text content for Custom CSS Modal
     * @option {String}
     */
    modalCss: {
      title: "CUSTOM CSS <i class='fa fa-code ml-1'></i>",
      description: 'Insert below your CSS code',
    },

    modalImage: {
      isContainer: false,
      target: null,
      form: null,
      title: 'Gallery',
    },

    /*
     * Text content for Custom Code Modal
     * @option {String}
     */
    modalCustomCode: {
      title: "CUSTOM CODE <i class='fa fa-code ml-1'></i>",
      tabs: [
        {
          name: 'Header',
          description:
            'Insert below your HTML code. This code will be insert before </head> closing tag',
        },
        {
          name: 'Footer',
          description:
            'Insert below your HTML code.  This code will be insert before </body> closing tag',
        },
      ],
    },

    /*
     * Text content for SEO Modal
     * @option {String}
     */
    modalSeo: {
      title: "SEO META DATA <i class='fa fa-globe ml-1'></i>",
      description:
        'Fill with your SEO info for this page. By informing this will helps search engines like Google to rank up your page.',
      metaTitleLabel: 'Page title:',
      metaDescriptionLabel: 'Page description:',
      metaKeywordLabel: 'Keywords: (separated by comma)',
    },

    /*
     * Text content for HTMLs Component Modal
     * @option {String}
     */
    modalHtmlComponent: {
      title: "HTML Editor <i class='fa fa-code ml-1'></i>",
      errors: [
        '*Maximum number of characters reached. Please enter a code shorter than 4095 characters.',
      ],
    },

    globalSettings: {
      title: "BACKGROUND <i class='fa fa-paint-brush ml-1'></i>",
    },

    /*
     * Text content for Convertions Modal
     * @option {String}
     */
    modalConversions: {
      title: "CONVERSIONS <i class='fa fa-bullseye ml-1'></i>",
      tabs: [
        {
          icon: 'undo',
          tabname: 'Back Redirect',
          title: 'Back Redirect',
          description:
            'By activating this option, the visitor will be redirected when pressed back button of browser.',
          config: {
            url: 'URL to redirect:',
          },
        },
        {
          icon: 'bell',
          tabname: 'Fake Notifications',
          title: 'Fake Notifications',
          description: '',
          config: {
            names: 'Names',
            namesplaceholder: 'Joe,Brian,Emma,Jimmy,Juan,Noah',
            times: 'Time',
            timesplaceholder:
              '1 minute ago,3 minute ago,16 seconds ago,1 hour ago,1 day ago',
            event: 'Event name',
            eventplaceholder: 'bought!',
            quantity: 'Quantity to show',
            delay: 'Delay between notifications',
            theme: 'Theme',
            themelight: 'light',
            themedark: 'dark',
          },
        },
      ],
    },

    /*
     * Text content for Integration Modal
     * @option {String}
     */
    modalIntegration: {
      title: 'EMAIL MARKETING INTEGRATION',
      service: {
        label: 'Provider',
        helper:
          '*Here are the provider options you have integrated into your dashboard. If you have not performed the integration yet,',
        cta: 'click here.',
      },
      action: {
        label: 'Action',
      },
      redirectURL: {
        label: 'Where to redirect after form submission?',
        placeholder: 'Destination URL',
      },
    },

    /*
     * Text content for Popup setup
     */
    modalPopup: {
      title: 'Popup',
      confirmation: 'Salvar Alterações',
      button: 'Popup',
      tabs: {
        general: {
          tabName: 'Configuração',
          config: {
            status: {
              label: 'Inserir Popup',
              description:
                'Para que exista um popup a ser mostrado na página, esta opção precisa estar marcada.',
              footer:
                'Lembre-se, você pode configurar para que os botões dessa página também mostrem este modal.',
              value: true,
            },
            trigger: {
              label: 'Eventos adicionais de abertura:',
              description: '',
              options: {
                loadPage: {
                  value: 'load-page',
                  label: 'Mostrar após carregar',
                  description:
                    'Quando a página for carregada, o Popup será mostrado.',
                },
                closePage: {
                  value: 'close-page',
                  label: 'Mostrar ao sair',
                  description:
                    'Quando o visitante tentar sair da página, o Popup será mostrado.',
                },
              },
            },
          },
        },
        texts: {
          tabName: 'Conteúdo',
          config: {
            title: {
              label: 'Título',
              value: 'Algum título aqui',
            },
            body: {
              label: 'Conteúdo',
              value: 'Algum texto aqui',
            },
            button: {
              label: 'Texto do botão',
              value: 'Inscreva-se',
            },
            form: {
              title: 'Formulário',
              inputs: {
                email: {
                  placeholder: 'Digite seu email',
                },
                phone: {
                  placeholder: 'Digite seu celular',
                },
                name: {
                  placeholder: 'Digite seu celular',
                },
              },
            },
          },
        },
        styles: {
          tabName: 'Estilo',
          labels: {
            backgroundColor: 'Cor de Fundo',
            title: 'Título',
            content: 'Conteúdo',
            button: 'Botão',
          },
          config: {
            form: {
              inputs: {
                size: {
                  label: 'Tamanho',
                  mobile: {
                    label: 'Tamanho Celular',
                  },
                },
                font: {
                  label: 'Fonte',
                },
                color: {
                  text: 'Cor do texto',
                  background: 'Cor do fundo',
                },
              },
            },
          },
        },
      },
      close: 'Fechar',
    },
  },

  localePtBr: {
    /*
     * Text title for devices switcher
     * @option {String}
     */
    viewOnMobile: 'Ver em mobile',
    viewOnTablet: 'Ver em tablet',
    viewOnLaptop: 'Ver em laptop',
    viewOnDesktop: 'Ver em desktop',

    /*
     * Text title for preview mode
     * @option {String}
     */
    previewOn: 'Pré-visualizar Ligado',
    previewOff: 'Pré-visualizar Desligado',

    /*
     * Text title for fullscreen mode
     * @option {String}
     */
    fullscreenOn: 'Tela Cheia Ligado',
    fullscreenOff: 'Tela Cheia Desligado',

    /*
     * Text title for save button
     * @option {String}
     */
    save: 'Salvar',

    /*
     * Text title for Warning Unsaved flag
     * @option {String}
     */
    contentUnsaved: 'Conteúdo não salvo',

    /*
     * Text title for add content button
     * @option {String}
     */
    addContent: 'Adicionar conteúdo',

    /*
     * Text title for add content button below sub-container and component
     * @option {String}
     */
    addContentBelow: 'Adicionar conteúdo abaixo',

    /*
     * Text title for paste content button
     * @option {String}
     */
    pasteContent: 'Colar conteúdo',

    /*
     * Text title for paste content button below sub-container and component
     * @option {String}
     */
    pasteContentBelow: 'Colar conteúdo abaixo',

    /*
     * Text title for move button
     * @option {String}
     */
    move: 'Arrastar',

    /*
     * Text title for move up button
     * @option {String}
     */
    moveUp: 'Mover para cima',

    /*
     * Text title for move down button
     * @option {String}
     */
    moveDown: 'Mover para baixo',

    /*
     * Text title for setting button
     * @option {String}
     */
    setting: 'Configurações',

    /*
     * Text title for copy button
     * @option {String}
     */
    copy: 'Copiar',

    /*
     * Text title for cut button
     * @option {String}
     */
    cut: 'Cortar',

    /*
     * Text title for delete button
     * @option {String}
     */
    delete: 'Deletar',

    /*
     * Text title for close button
     * @option {String}
     */
    close: 'Fechar',

    /*
     * Text title for Insert Code button
     * @option {String}
     */
    insertCode: 'Inserir Código',

    /*
     * Text title for Insert Info button
     * @option {String}
     */
    insertInfo: 'Inserir Informações',

    /*
     * Text title for Insert Integration button
     * @option {String}
     */
    insertIntegration: 'Inserir Integração',

    /*
     * Text for snippet category label
     * @option {String}
     */
    snippetCategoryLabel: 'Filtro',

    /*
     * Text for snippet category `All`
     * @option {String}
     */
    snippetCategoryAll: 'Todos',

    /*
     * Text for snippet categories
     * @option {String}
     */
    snippetCategories: {
      navItem0: [
        'Layouts',
        'Mídias',
        'Textos',
        'Botões',
        // "Sociais",
        // "Carrosséis",
        // "Navegações",
        // "Formulários",
        // "Contadores",
        'Extras',
      ],
      navItem1: [
        'Cabeçalhos',
        // "Lançamentos",
        // "Iscas",
        'Benefícios',
        'Autores',
        'Depoimentos',
        'Ofertas',
        // "Formulários",
        'Rodapés',
        'Extras',
      ],
    },

    /*
     * Text for snippet search text box
     * @option {String}
     */
    snippetCategorySearch: '&#xF002; Digite para procurar...',

    /*
     * Text title for bootstrap column resizer
     * @option {String}
     */
    columnResizeTitle: 'Arraste para redimensioar',

    /*
     * Text title for container setting
     * @option {String}
     */
    containerSetting: 'Configurações do Container',

    /*
     * Text content for confirm dialog when deleting container
     * @option {String}
     */
    confirmDeleteContainerText:
      'Você tem certeza que quer deletar esse container? Essa ação não poderá ser desfeita!',

    /*
     * Text content for confirm dialog when deleting container
     * @option {String}
     */
    confirmDeleteComponentText:
      'Você tem certeza que quer deletar esse componente? Essa ação não poderá ser desfeita!',

    /*
     * Text content for Pixel Modal
     * @option {String}
     */
    modalTracking: {
      fb: {
        title: 'Pixel do Facebook',
        subtitle: 'Integração de Pixel',
        fillwith:
          'Preencha com os números dos seus Pixels do Facebook (separe com vírgula)',
        placeholder: 'Digite apenas números',
        icon: 'code',
        disclaimer: 'Por padrão, será disparado o evento PageView.',
        events: {
          fillwith:
            'Opcionalmente, adicione disparo de mais eventos na página (separe com vírgula).',
          placeholder: 'Digite os nomes dos eventos',
          disclaimer:
            'Por padrão, os eventos serão disparados quando a página carregar.',
        },
        tokens: {
          fillwith:
            'Opcionalmente, adicione seu Token para disparo também via API (separe com vírgula, na mesma ordem dos pixels).',
          placeholder: 'Digite seu token',
          disclaimer:
            'Se dicionado o token, os disparos de evento do pixel da página acontecerão via Navegador e API.',
        },
      },
      ga: {
        title: 'ID do Google Analytics',
        subtitle: 'Integração de Pixel',
        fillwith: 'Preencha com seu ID de acompanhamento do Google Analytics',
        placeholder: 'G-',
        icon: 'line-chart',
      },
      gtm: {
        title: 'Google Tag Manager',
        subtitle: 'Integração de Pixel',
        fillwith:
          'Preencha com o Código do Contêiner da sua conta do Google Tag Manager',
        placeholder: 'GTM-',
        icon: 'google',
      },
    },

    /*
     * Text content for Custom CSS Modal
     * @option {String}
     */
    modalCss: {
      title: "CSS CUSTOMIZÁVEL <i class='fa fa-code ml-1'></i>",
      description: 'Insira aqui seu código CSS',
    },

    /*
     * Text content for Photo Modal
     * @isContainer {Boolean}
     * @target {DOM element}
     * @form {DOM element}
     */
    modalImage: {
      isContainer: false,
      target: null,
      form: null,
      title: 'Escolha uma imagem',
    },

    /*
     * Text content for Custom Code Modal
     * @option {String}
     */
    modalCustomCode: {
      title: "CÓDIGO CUSTOMIZÁVEL <i class='fa fa-code ml-1'></i>",
      tabs: [
        {
          name: 'Cabeçalho',
          description:
            'Insira abaixo o código. Este código será adicionado antes do fechamento da tag </head>',
        },
        {
          name: 'Rodapé',
          description:
            'Insira abaixo o código. Este código será adicionado antes do fechamento da tag </body>',
        },
      ],
    },

    /*
     * Text content for SEO Modal
     * @option {String}
     */
    modalSeo: {
      title: "SEO META DATA <i class='fa fa-globe ml-1'></i>",
      description:
        'Preencha com as informações de SEO da página.<br>Essas informações dão mais relevância à sua página para buscadores como o Google.',
      metaTitleLabel: 'Título da página:',
      metaDescriptionLabel: 'Descrição da página:',
      metaKeywordLabel: 'Palavras-chave relacionadas: (separadas por vírgula)',
    },

    /*
     * Text content for HTMLs Component Modal
     * @option {String}
     */
    modalHtmlComponent: {
      title: "Editor de HTML <i class='fa fa-code ml-1'></i>",
      errors: [
        '*Número máximo de caracteres atingido. Por favor, insira um código menor que 4095 caracteres.',
      ],
    },

    globalSettings: {
      title: "FUNDO DA PÁGINA <i class='fa fa-paint-brush ml-1'></i>",
    },

    /*
     * Text content for Convertions Modal
     * @option {String}
     */
    modalConversions: {
      title: "CONVERSÕES <i class='fa fa-bullseye ml-1'></i>",
      tabs: [
        {
          icon: 'undo',
          tabname: 'Back Redirect',
          title: 'Back Redirect',
          description:
            'Ativando essa opção, o visitante será redirecionado quando pressionar no botão voltar do navegador.',
          config: {
            url: 'Para onde redirecionar:',
          },
        },
        {
          icon: 'bell',
          tabname: 'Fake Notifications',
          title: 'Fake Notifications',
          description:
            'Ativando essa opção, notificações irão aparecer no canto inferior da tela.',
          config: {
            names: 'Nomes (separe com vírgula)',
            namesplaceholder: 'Maria,José,Isabelle,Paulo,Sofia',
            times: 'Tempos (separe com vírgula)',
            timesplaceholder:
              '1 minuto atrás,3 minutos atrás,16 segundos atrás,Nesse instante,1 hora atrás,1 dia atrás',
            event: 'Evento',
            eventplaceholder: 'Ex: comprou!',
            quantity: 'Quantidade a ser mostrada',
            delay: 'Tempo entre as notificações',
            theme: 'Tema',
            themelight: 'Claro',
            themedark: 'Escuro',
          },
        },
      ],
    },

    /*
     * Text content for Integration Modal
     * @option {String}
     */
    modalIntegration: {
      title: 'INTEGRAÇÃO DE EMAIL MARKETING',
      service: {
        label: 'Provedor',
        helper:
          '*Aqui estão as opções de provedores que você integrou no seu painel. Caso não tenha realizado a integração ainda,',
        cta: 'aperte aqui.',
      },
      action: {
        label: 'Ação',
      },
      redirectURL: {
        label: 'Para onde redirecionar após o envio do formulário?',
        placeholder: 'URL Destino',
      },
      lead: {
        label: 'Disparar evento de Lead no envio do formulário.',
        disclaimer:
          'Se adicionado token ao pixel, enviaremos para o Meta, via API, também as informações capturadas do lead.',
      },
    },

    /*
     * Text content for Popup setup
     */
    modalPopup: {
      title: 'Popup',
      confirmation: 'Salvar Alterações',
      button: 'Popup',
      tabs: {
        general: {
          tabName: 'Configuração',
          config: {
            status: {
              label: 'Inserir Popup',
              description:
                'É necessário ativar essa opção para que o popup seja inserido na página.',
              footer:
                'Lembre-se, você pode configurar para que os botões dessa página também mostrem este modal.',
              value: true,
            },
            trigger: {
              label: 'Eventos adicionais de abertura:',
              description: '',
              options: {
                loadPage: {
                  value: 'load-page',
                  label: 'Mostrar após carregar',
                  description:
                    'Quando a página for carregada, o Popup será mostrado.',
                },
                closePage: {
                  value: 'close-page',
                  label: 'Mostrar ao sair',
                  description:
                    'Quando o visitante tentar sair da página, o Popup será mostrado.',
                },
              },
            },
          },
        },
        texts: {
          tabName: 'Conteúdo',
          config: {
            title: {
              label: 'Título',
              value: 'Algum título aqui',
            },
            body: {
              label: 'Conteúdo',
              value: 'Algum texto aqui',
            },
            button: {
              label: 'Texto do botão',
              value: 'Inscreva-se',
            },
            form: {
              title: 'Formulário',
              inputs: {
                email: {
                  placeholder: 'Digite seu email',
                },
                phone: {
                  placeholder: 'Digite seu celular',
                },
                name: {
                  placeholder: 'Digite seu celular',
                },
              },
            },
          },
        },
        styles: {
          tabName: 'Estilo',
          labels: {
            backgroundColor: 'Cor de Fundo',
            title: 'Título',
            content: 'Conteúdo',
            button: 'Botão',
          },
          config: {
            form: {
              inputs: {
                size: {
                  label: 'Tamanho',
                  mobile: {
                    label: 'Tamanho Celular',
                  },
                },
                font: {
                  label: 'Fonte',
                },
                color: {
                  text: 'Cor do texto',
                  background: 'Cor do fundo',
                },
              },
            },
          },
        },
      },
      close: 'Fechar',
    },
  },

  globalStyle: {
    background: {
      style: '',
      color: '#fff',
      // gradient: {
      //   style: '',
      //   init: {
      //     color: 'rgba(0,0,0,0)',
      //     value: 0,
      //   },
      //   final: {
      //     color: 'rgba(0,0,0,0)',
      //     value: 100,
      //   },
      //   angle: 180,
      // },
      img: {
        src: '',
        repeat: 'no-repeat',
        position: '50% 0%',
        size: 'auto',
      },
    },
    font: {
      default: 'primary',
      style: '',
      primary: '#222',
      secondary: '#222',
      none: '#222',
      family: '',
    },
  },

  /*
   * Current device Width
   * @option {Number or Null}
   */
  currentDeviceWidth: null,

  /*
   * Width of mobile device
   * @option {Number}
   */
  widthMobile: 420,

  /*
   * Width of tablet device
   * @option {Number}
   */
  widthTablet: 820,

  /*
   * Width of laptop device
   * @option {Number}
   */
  widthLaptop: '100%',

  /*
   * Min width of desktop device
   * @option {Number}
   */
  minWidthDesktop: 1250,

  /*
   * Default component type of component. If type of component does not exist in KEditor.components, will be used `defaultComponentType` as type of this component. If is function, argument is component
   * @option {String|Function}
   * @param {jQuery} component
   */
  defaultComponentType: 'blank',

  /*
   * Url to snippets file
   * @option {String}
   */
  snippetsUrl: 'bundle/snippets.html',

  snippetsContent: '',

  snippetsClasses: [
    '.divisor',
    '.text-container',
    '.list-content',
    '.progress',
    '.button-style',
    '.countdown-container',
    '.photo-panel',
    '.form-content',
    '.icon-wrapper .icon',
    '.card',
    '.card-testimonial',
    '.youtube-wrapper',
    '.quiz-container',
    '.carousel-container',
  ],

  // apiUrl: "https://api.makefunnels.com.br/",
  apiUrl: process.env.MAKEFUNNELS_API_URL,

  redirectUrl: `${process.env.MAKEFUNNELS_APP_URL}/funnel-detail/${localStorage.getItem(
    'funnelId'
  )}`,
  // "https://app.makefunnels.com.br/funnel-detail/" +
  // localStorage.getItem("funnelId"),

  apiSaveContent: `${
    process.env.MAKEFUNNELS_API_URL
  }/pages/${localStorage.getItem('pageId')}/html`,

  accessToken: `bearer ${localStorage.getItem('token')}`,

  previewURL: '',
  /**
   * Array de objetos que guarda os src das imagens
   */
  gallery: [],

  /**
   * Componentes que devem ser unicos na pag
   */
  contentUnique: {
    quiz: false,
  },

  /*
   *  Holds HTML Components Code
   */
  contentHTMLs: {
    htmls: [
      // {dataid:"",code:""},
    ],
  },

  /*
   *  Holds Script Codes
   */
  contentScripts: {
    header: {
      position: 'header',
      script: '',
    },
    footer: {
      position: 'footer',
      script: '',
    },
    footer_system: {
      position: 'footer_system',
      content: {
        /**
         * Array que guarda as ids dos countDowns da página.
         */
        countdownArr: [],
        /**
         * Array que guarda as ids e valores de timeDelay dos Componentes e containters.
         */
        timeDelayArr: {},
        /**
         * Array que guarda as ids das tags html para sticky.
         */
        stickyArr: [],
        /**
         * Array que guarda as ids dos elementos para animar quando aparecerem.
         */
        animateArr: [],
      },
      script: '',
    },
    header_system: {
      position: 'header_system',
      content: {
        timeDelayStyle: '<style>.delayed{display:none !important;}</style>',
        // background: {}, //backgroundBody - inherited from globalStyle
        pixelLeadOnSubmit: {
          enabled: false,
          value: '<script>window.mf_meta_sendlead = true;</script>',
        },
      },
      script: '<style>.delayed{display:none !important;}</style>',
    },
  },

  /*
   *  Holds Tracking Codes
   */
  contentTracking: {
    tracking: [
      {
        type: 'fb',
        enabled: false,
        value: '',
        extraEvents: '',
      },
      {
        type: 'ga',
        enabled: false,
        value: '',
      },
      {
        type: 'gtm',
        enabled: false,
        value: '',
      },
    ],
  },

  /*
   *  Holds CSS Codes
   */
  contentCss: {
    script: '',
  },

  /*
   *  Holds SEO Codes
   */
  contentSeo: {
    title: '',
    description: '',
    keywords: '',
  },

  /*
   *  Holds Convertions Codes
   */
  contentConversions: {
    conversions: [
      {
        type: 'backredirect',
        enabled: false,
        config: {
          url: '',
        },
      },
      {
        type: 'fakenotifications',
        enabled: false,
        config: {
          names: '',
          times: '',
          event: '',
          quantity: '5',
          delay: '6',
          theme: 'light',
        },
      },
    ],
  },

  /*
   *  Holds Popup Codes
   */
  contentPopup: {
    status: true,
    trigger: ['action-button'],
    title: 'Espera!',
    body: 'Fusce ac dui et dolor aliquet vulputate at nec mi. Sed tempus facilisis mi. Nunc mattis magna semper dui iaculis, fringilla gravida metus placerat. Nunc malesuada turpis felis, lacinia feugiat urna mollis sit amet.',
    form: {
      email: {
        status: false,
        placeholder: 'Digite seu email',
      },
      phone: {
        status: false,
        placeholder: 'Digite seu celular',
      },
      name: {
        status: false,
        placeholder: 'Digite seu nome',
      },
    },
    cta: {
      text: 'Digite aqui o texto do botão',
      backgroundColor: 'green',
      color: 'white',
      action: 'close-modal',
      href: null,
    },
    styles: {
      wrapper: {
        backgroundColor: '#ffffff',
        // backgroundColor: {
        //   r: 255,
        //   g: 255,
        //   b: 255,
        //   a: 100,
        // },
      },
      title: {
        color: '#333',
        size: '30',
        font: 'Nunito Sans',
        mobile: {
          size: '12',
        },
        style: ['bold'],
      },
      body: {
        color: '#333',
        size: '20',
        font: 'Nunito Sans',
        mobile: {
          size: '12',
        },
        style: ['bold'],
      },
      cta: {
        background: '#F5A623',
        color: '#FFFFFF',
        size: '14',
        font: 'Nunito Sans',
        mobile: {
          size: '18',
        },
        style: ['bold'],
      },
    },
  },

  /*
   *  Integrations state
   */
  integrations: {
    redirect_url: '',
    dataId: [],
    data: [
      // {
      //   type: 'email_marketing',
      //   service: '',
      //   action: '',
      //   resources: [],
      // },
    ],
  },
  integrationForm: null,

  /*
   * The separator character between each categories
   * @option {String}
   */
  snippetsCategoriesSeparator: ';',

  /*
   * Content styles for iframe mode. Beside of this option, KEditor will add all elements which have 'data-type=keditor-style' for iframe stylesheet. These elements can be 'link', 'style' or any tags. If these elements have 'href' attribute, will create link tag with href. If these elements do not have 'href' attribute, will create style tag with css rule is html code inside element
   * @option {Array<Object>}
   * @example:
   * [
   *     {
   *         href: '/path/to/style/file'
   *     }, {
   *         content: 'a { color: red; }'
   *     }
   * ]
   */
  contentStyles: [],

  /*
   * Selector of content areas. If is null or selector does not match any elements, will create default content area and wrap all content inside it.
   * @option {String}
   */
  contentAreasSelector: null,

  /*
   * The wrapper element for all contents inside iframe or new div which will contains content of textarea. It's just for displaying purpose. If you want all contents inside iframe are appended into body tag, just leave it as blank
   * @option {String}
   */
  contentAreasWrapper: `<div class="${CSS_CLASS.UI} ${CSS_CLASS.CONTENT_AREAS_WRAPPER}"></div>`,

  /*
   * Enable setting panel for container
   * @option {Boolean}
   */
  containerSettingEnabled: false,

  /*
   * Method will be called when initializing setting panel for container
   * @option {Function}
   * @param {jQuery} container
   * @param {KEditor} keditorInstance
   */
  containerSettingInitFunction: null,

  /*
   * Method will be called when setting panel for container is showed
   * @option {Function}
   * @param {jQuery} settingForm
   * @param {jQuery} container
   * @param {KEditor} keditorInstance
   */
  containerSettingShowFunction: null,

  /*
   * Method will be called when setting panel for container is hidden
   * @option {Function}
   * @param {jQuery} settingForm
   * @param {KEditor} keditorInstance
   */
  containerSettingHideFunction: null,

  /**
   * Bootstrap settings
   */
  bootstrap: {
    /**
     * Enable column resize by drag and drop or not. Require: jQuery UI Resizable
     * @option {Boolean}
     */
    columnResizeEnabled: true,

    /**
     * List of device class for responsive grid system
     * @option {Object}
     */
    deviceClass: {
      MOBILE: 'xs',
      TABLET: 'sm',
      LAPTOP: 'md',
      DESKTOP: 'lg',
    },

    /**
     * Grid system of bootstrap with `width` in percentage and `col` number
     * @option {Array<Object>}
     */
    gridSystem: [
      {
        width: 8.33333333,
        col: 1,
      },
      {
        width: 16.66666667,
        col: 2,
      },
      {
        width: 25,
        col: 3,
      },
      {
        width: 33.33333333,
        col: 4,
      },
      {
        width: 41.66666667,
        col: 5,
      },
      {
        width: 50,
        col: 6,
      },
      {
        width: 58.33333333,
        col: 7,
      },
      {
        width: 66.66666667,
        col: 8,
      },
      {
        width: 75,
        col: 9,
      },
      {
        width: 83.33333333,
        col: 10,
      },
      {
        width: 91.66666667,
        col: 11,
      },
      {
        width: 100,
        col: 12,
      },
      {
        width: 10000,
        col: 10000,
      },
    ],
  },

  clickComponentToShowSetting: false,

  elementSelected: null,

  /** */

  initCSSStyleSheet() {
    const style = document.createElement('style')
    style.setAttribute('id', 'makefunnels-style')
    document.head.appendChild(style)
  },

  getCSSStyleSheet() {
    const style = document.getElementById('makefunnels-style')
    // const style = document.styleSheets["makefunnels-style"];

    return style.innerHTML
  },

  insertCSSRule(componentId, rule) {
    const style = document.styleSheets['makefunnels-style']
    style.insertRule(`${componentId}${rule}`)
  },

  editCSSRule(componentId, style, value) {
    const styleSheetRef = document.styleSheets['makefunnels-style']
    const findElementStyle = [...styleSheetRef.rules].findIndex(
      (el) => el.selectorText === componentId
    )
    if (findElementStyle) {
      Object.assign(styleSheetRef.rules[findElementStyle])
    } else {
      this.insertCSSRule(componentId, `{${style}: ${value}}`)
    }
  },

  /*
   * Callback will be called after keditor instance is ready
   * @option {Function}
   */
  onReady() {
    // Remove a tela de loading (splash screen)
    $('.splash').addClass('display-none')
  },

  /*
   * Callback will be called after clicking on `Save` button in topbar
   * @option {Function}
   * @param {String} content
   */
  onSave(content) {},

  /*
   * Callback will be called after clicking on `Insert Pixel` button in Pixels Modal
   * @option {Function}
   */
  onPixelInsert() {
    $('.unsaved').show()
  },

  /*
   * Callback will be called after clicking on `Insert Info` button in SEO Modal
   * @option {Function}
   */
  onSeoInsert() {
    $('.unsaved').show()
  },

  /**
   * Callback will be called after clicking on `Insert Code` button in CSS Modal
   * @option {Function}
   * @param {jQuery} codeEditorArea
   */
  onCSSInsert(codeEditorArea) {
    $('.unsaved').show()

    const aceCodeEditor = loadEditor(codeEditorArea.attr('id'), 'css')

    this.options.contentCss.script = aceCodeEditor.getValue()
  },

  /**
   * Callback will be called after CSS Modal be insert on DOM
   * @option {Function}
   * @param {jQuery} codeEditorArea
   */
  onCSSCodeEditorInit(codeEditorArea) {},

  /**
   * Callback will be called when CSS Modal show method called
   * @option {Function}
   * @param {jQuery} codeEditorArea
   */
  onCSSCodeEditorShow(codeEditorArea) {
    const aceCodeEditor = loadEditor(codeEditorArea.attr('id'), 'css')

    aceCodeEditor.setValue(this.options.contentCss.script)
  },

  /**
   * Callback will be called after clicking on `Insert Code` button in Custom Code Modal
   * @option {Function}
   * @param {jQuery} headerCodeEditorArea
   * @param {jQuery} footerCodeEditorArea
   */
  onCustomCodeInsert(headerCodeEditorArea, footerCodeEditorArea) {
    $('.unsaved').show()

    const headerAceCodeEditor = loadEditor(
      headerCodeEditorArea.attr('id'),
      'html'
    )
    const footerAceCodeEditor = loadEditor(
      footerCodeEditorArea.attr('id'),
      'html'
    )

    this.options.contentScripts.header.script = headerAceCodeEditor.getValue()
    this.options.contentScripts.footer.script = footerAceCodeEditor.getValue()
  },

  /**
   * Callback will be called after Custom Code  Modal be insert on DOM
   * @param {jQuery} codeEditorArea
   */
  onCustomCodeCodeEditorInit(headerCodeEditorArea, footerCodeEditorArea) {},

  /**
   * Callback will be called when Custom Code  Modal show method called
   * @param {jQuery} codeEditorArea
   */
  onCustomCodeCodeEditorShow(headerCodeEditorArea, footerCodeEditorArea) {
    const headerAceCodeEditor = loadEditor(
      headerCodeEditorArea.attr('id'),
      'html'
    )
    const footerAceCodeEditor = loadEditor(
      footerCodeEditorArea.attr('id'),
      'html'
    )

    headerAceCodeEditor.setValue(this.options.contentScripts.header.script)
    footerAceCodeEditor.setValue(this.options.contentScripts.footer.script)
  },

  /*
   * Callback will be called after snippets are loaded. If you want to modify snippets content, please return modified
   * @option {Function}
   * @param {String} resp
   * @return {String}
   */
  onSnippetsLoaded(resp) {
    this.options.snippetsContent = resp
  },

  /*
   * Callback will be called when error in loading snippets
   * @option {Function}
   * @param {jqXHR} jqXHR
   */
  onSnippetsError(jqXHR) {},

  /*
   * Callback will be called after iframe and content areas wrapper inside it are created
   * @option {Function}
   * @param {jQuery} iframe
   * @param {jQuery} iframeHead
   * @param {jQuery} iframeBody
   */
  async onInitIframe(iframe, iframeHead, iframeBody) {},

  /*
   * Callback will be called when content is changed. Includes add, delete, duplicate container or component. Or content of a component is changed
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} contentArea
   */
  onContentChanged(event, contentArea) {
    $('.unsaved').show()
    initContentAreaState(contentArea)
    toggleEmptyContainers(contentArea)
  },

  /*
   * Callback will be called before initializing container
   * @option {Function}
   * @param {jQuery} contentArea
   */
  onBeforeInitContentArea(contentArea) {},

  /*
   * Callback will be called when initializing content area. Need to return jQuery objects which will be initialized as container
   * @option {Function}
   * @param {jQuery} contentArea
   * @return {jQuery}
   */
  onInitContentArea(contentArea) {
    const contentAreaInner = contentArea
      .children(`.${CSS_CLASS.CONTENT_AREA_INNER}`)
      .children()

    const scope = () => {
      this.options.onUniqueElementAction.call(
        this,
        !!contentArea.find('[data-type="component-quiz"]').length,
        true
      )

      /**
       * footer_system.content cleaning
       * Remove possíveis elementos nos Arrays referentes à componentes que não existem na página
       */
      const { timeDelayArr } = this.options.contentScripts.footer_system.content
      const { countdownArr } = this.options.contentScripts.footer_system.content
      const { stickyArr } = this.options.contentScripts.footer_system.content
      const { animateArr } = this.options.contentScripts.footer_system.content
      Object.keys(timeDelayArr).map((el) => {
        if (!contentArea.find(`#${el}`).length) delete timeDelayArr[el]
      })
      this.options.contentScripts.footer_system.content.countdownArr =
        countdownArr.filter((el) => contentArea.find(`#${el}`).length)
      this.options.contentScripts.footer_system.content.stickyArr =
        stickyArr.filter((el) => contentArea.find(`#${el}`).length)
      this.options.contentScripts.footer_system.content.animateArr =
        animateArr.filter((el) => contentArea.find(`#${el}`).length)
    }

    if (contentAreaInner.length) {
      scope()
    }

    initContentAreaState(contentArea)
    return contentAreaInner
  },

  /*
   * Callback will be called before initializing container
   * @option {Function}
   * @param {jQuery} container
   * @param {jQuery} contentArea
   */
  onBeforeInitContainer(container, contentArea) {},

  /*
   * Callback will be called when initializing container. It can return array of jQuery objects which will be initialized as editable components in container content (NOTE: these objects MUST be under elements which have attribute `data-type="container-content"`). By default, all first level sections under container content will be initialized
   * @option {Function}
   * @param {jQuery} container
   * @param {jQuery} contentArea
   */
  onInitContainer(container, contentArea) {},

  /*
   * Callback will be called before container is deleted
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedContainer
   * @param {jQuery} contentArea
   */
  onBeforeContainerDeleted(event, selectedContainer, contentArea) {
    /**
     * Deleta a configuração de timeDelay do container, se existir.
     */
    delete this.options.contentScripts.footer_system.content.timeDelayArr[
      selectedContainer.attr('id')
    ]
    /**
     * Deleta a configuração de Animation do container, se existir.
     */
    delete this.options.contentScripts.footer_system.content.animateArr[
      selectedContainer.attr('id')
    ]
  },

  /*
   * Callback will be called after container and its components are already deleted
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedContainer
   * @param {jQuery} contentArea
   */
  onContainerDeleted(event, selectedContainer, contentArea) {
    $('.unsaved').show()
    closeSidebar.call(this)
  },

  /*
   * Callback will be called when content of container is changed. It can be when container received new component from snippet or from other container. Or content of any components are changed or any components are deleted or duplicated
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} changedContainer
   * @param {jQuery} contentArea
   */
  onContainerChanged(event, changedContainer, contentArea) {
    $('.unsaved').show()
  },

  /*
   * Callback will be called when a container is duplicated
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} originalContainer
   * @param {jQuery} newContainer
   * @param {jQuery} contentArea
   */
  onContainerDuplicated(event, originalContainer, newContainer, contentArea) {
    $('.unsaved').show()
  },

  /*
   * Callback will be called when a container is selected
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedContainer
   * @param {jQuery} contentArea
   */
  onContainerSelected(event, selectedContainer, contentArea) {},

  /*
   * Callback will be called when a container snippet is added in a content area
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} newContainer
   * @param {jQuery} selectedSnippet
   * @param {jQuery} contentArea
   */
  onContainerSnippetAdded(event, newContainer, selectedSnippet, contentArea) {
    toggleEmptyContainers(contentArea)
  },

  /*
   * Callback will be called after component is initialized. This callback is available or not is depend on component type handler.
   * @option {Function}
   * @param {jQuery} component
   */
  onComponentReady(component) {},

  /*
   * Callback will be called before initializing component
   * @option {Function}
   * @param {jQuery} component
   * @param {jQuery} contentArea
   */
  onBeforeInitComponent(component, contentArea) {},

  /*
   * Callback will be called when initializing component
   * @option {Function}
   * @param {jQuery} component
   * @param {jQuery} contentArea
   * @param {string} action | can come with 'paste' state
   */
  onInitComponent(component, contentArea, action = '') {
    this.options.snippetsClasses.forEach(function (el) {
      if (component.find(`${el}`).length) {
        const elRef = component.find(`${el}`)
        const marginDefault = elRef.data('margin_default')
        const marginMobile = elRef.data('margin_mobile')

        if (!marginDefault && !marginMobile) {
          const marginTop = elRef.css('marginTop').replace('px', '')
          elRef.attr({
            'data-margin_default': marginTop,
            'data-margin_mobile': marginTop,
            'data-spacing-block': true,
          })
        }
      }
    })
    // If is a duplicaiton, remove timeDelay class since new id is not included at timeDelayArr
    if (action == 'paste') {
      component.children().first().children().first().removeClass('delayed')
    }
  },

  /*
   * Callback will be called before a component is deleted
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedComponent
   * @param {jQuery} contentArea
   */
  onBeforeComponentDeleted(event, selectedComponent, contentArea) {
    const types = Object.keys(this.options.contentUnique).join(',')
    this.options.onUniqueElementAction.call(
      this,
      selectedComponent.data('type').indexOf(types) !== -1
    )

    /**
     * Remove o componente do array de referencia para os form com integração
     */
    const integrations = this.options?.integrations

    if (!integrations) return

    const componentId = selectedComponent.attr('id')
    const index = componentId ? integrations.dataId.indexOf(componentId) : -1

    if (index > -1) {
      integrations.dataId.splice(index, 1)
    }
    /**
     * Deleta a configuração de timeDelay do component, se existir.
     */
    delete this.options.contentScripts.footer_system.content.timeDelayArr[
      componentId
    ]
    /**
     * Deleta a configuração de Animation do component, se existir.
     */
    delete this.options.contentScripts.footer_system.content.animateArr[
      componentId
    ]
  },

  /*
   * Callback will be called after a component is deleted
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedComponent
   * @param {jQuery} contentArea
   */
  onComponentDeleted(event, selectedComponent, contentArea) {
    $('.unsaved').show()
  },

  /*
   * Callback will be called when content of a component is changed
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} changedComponent
   * @param {jQuery} contentArea
   */
  onComponentChanged(event, changedComponent, contentArea) {
    $('.unsaved').show()
  },

  /*
   * Callback will be called when a component is duplicated
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} originalComponent
   * @param {jQuery} newComponent
   * @param {jQuery} contentArea
   */
  onComponentDuplicated(event, originalComponent, newComponent, contentArea) {
    $('.unsaved').show()
  },

  /*
   * Callback will be called when a component is selected
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} selectedComponent
   * @param {jQuery} contentArea
   */
  onComponentSelected(event, selectedComponent, contentArea) {},

  /*
   * Callback will be called after a component snippet is added in a container
   * @option {Function}
   * @param {Event} event
   * @param {jQuery} newComponent
   * @param {jQuery} selectedSnippet
   * @param {jQuery} contentArea
   */
  onComponentSnippetAdded(event, newComponent, selectedSnippet, contentArea) {
    toggleEmptyContainers(contentArea)
    const types = Object.keys(this.options.contentUnique).join(',')
    this.options.onUniqueElementAction.call(
      this,
      selectedSnippet.data('type').indexOf(types) !== -1
    )
  },

  /*
   * Callback will be called before loading dynamic content
   * @option {Function}
   * @param {jQuery} dynamicElement
   * @param {jQuery} component
   * @param {jQuery} contentArea Can be null if preview is ON
   */
  onBeforeDynamicContentLoad(dynamicElement, component, contentArea) {},

  /*
   * Callback will be called after dynamic content is loaded
   * @option {Function}
   * @param {jQuery} dynamicElement
   * @param {jqXHR} , jqXHR
   * @param {jQuery} contentArea Can be null if preview is ON
   */
  onDynamicContentLoaded(dynamicElement, jqXHR, contentArea) {},

  /*
   * Callback will be called if loading dynamic content is error, abort or timeout
   * @option {Function}
   * @param {jQuery} dynamicElement
   * @param {jqXHR} , jqXHR
   * @param {jQuery} contentArea Can be null if preview is ON
   */
  onDynamicContentError(dynamicElement, jqXHR, contentArea) {},

  onInitEmptyContainers(contentArea) {
    toggleEmptyContainers(contentArea)
  },

  onUniqueElementAction(isAlready, isFirstLoad) {
    const targets = ['quiz']
    const sidebarBody = this.sidebarLeftBody
    if (!sidebarBody) return
    for (let i = 0; i < targets.length; i++) {
      const target = sidebarBody.find(`[data-type="component-${targets[i]}"]`)

      if (isAlready) {
        target.toggle()
        if (isFirstLoad) target.hide()
      }
    }
    this.options.contentUnique.quiz = isAlready
  },

  elementsRef: {
    elements: {},

    setElement(key, value) {
      this.elements = { ...this.elements, [key]: value }
    },

    getElement(key) {
      return this.elements[key] || undefined
    },
  },
}
