import CSS_CLASS from "./constants/cssClass";
import initContentArea from "./contentArea/initContentArea";
import initContentHover from "./contentArea/initContentHover";
import error from "./utils/error";

export default function (content, contentArea, dontInitToolbar = true) {
  let self = this;
  let contentAreasWrapper = self.contentAreasWrapper;
  let target;

  if (!contentArea) {
    target = contentAreasWrapper.children(`.${CSS_CLASS.CONTENT_AREA}`);
  } else {
    if (!contentArea.jquery) {
      target = contentAreasWrapper.find(contentArea);
    }
  }

  if (target.length === 0) {
    error("Content area does not exist!");
  }

  target.html(content);

  initContentArea.call(self, target, dontInitToolbar);
  initContentHover.call(self, target)
  if (typeof self.options.onInitEmptyContainers === "function") {
    self.options.onInitEmptyContainers.call(self, self.contentAreasWrapper);
  }
}
