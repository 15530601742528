export const BTN_INTEGRATION_MODAL_REF = 'btn_integration_modal'
export const INTEGRATIONS_TYPE = {
  WEBHOOK: 'webhook',
  EMAIL_MARKETING: 'email_marketing',
}
export const INTEGRATIONS_PANEL_VIEW = {
  LIST: 'list',
  ADD: 'add',
  EDIT: 'edit',
}
