import {
  INTEGRATIONS_TYPE,
  STATE_TOOLBAR_SHOWED,
  IN_FOCUS,
} from '../../constants.js'
import { MODAL_ID as POPUP_ID } from '../../ReactComponents/Popup/shared/constants.js'
import defaults from '../constants/defaults.js'

export function loadEditor(target, mode) {
  const editor = ace.edit(target)
  editor.setTheme('ace/theme/dracula')
  editor.getSession().setMode(`ace/mode/${mode}`)

  return editor
}

// export function save(alert, page, header = "", footer = "", css = "") {
export function save(alert, hasContent, modalId = '') {
  const alertSave = $('.save-content')
  let alertClass = 'alert-danger'
  let alertMessage = 'Não foi possivel salvar o conteúdo'
  if (hasContent) {
    alertClass = 'alert-warning'
    alertMessage =
      'Configuração inserida com sucesso. <br /><b>Atenção:</b> Ainda é necessário salvar a página!'
  }

  alertSave.fadeIn(1000)
  alertSave.removeClass('alert-success alert-danger alert-warning')
  alertSave.addClass(alertClass)
  alertSave.html(alertMessage)
  setTimeout(() => alertSave.hide(), 2000)
  // alertSave.fadeOut(1800);
  // alertSave.hide();

  $(`#${modalId}`).modal('hide')
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

/**
 * "Wrapa" footer_system com tag script, coloca dentro do evento window.onload e,
 * se passado, transforma o arrays em chamadas para função na página final.
 * @param {Array} countdownArr
 * @param {Array} timeDelayArr
 * @param {Array} stickyArr
 * @param {Array} AnimateArr
 */
export function footerSystemMount(
  countdownArr = [],
  timeDelayArr = [],
  stickyArr = [],
  AnimateArr = [],
  fknotificationConfig = ''
) {
  // prepend open tag script
  let script = '<script>window.onload = function() {'
  // monta o timeDelay array em string no FooterSystem
  for (const tdKey in timeDelayArr) {
    script += `timeDelay("${tdKey}",${timeDelayArr[tdKey]});`
  }
  // monta o countdown array em string no FooterSystem
  for (const cdKey in countdownArr) {
    script += `countdown("${countdownArr[cdKey]}");`
  }
  // monta o Sticky array em string no FooterSystem
  for (const stKey in stickyArr) {
    script += `sticky("${stickyArr[stKey]}");`
  }
  // monta o Animate array em string no FooterSystem
  for (const anKey in AnimateArr) {
    script += `animateWA("${AnimateArr[anKey]}");`
  }

  if (typeof fknotificationConfig === 'object' && fknotificationConfig) {
    let fknNamesArrStr = '['
    fknotificationConfig.names.split(',').map((name) => {
      fknNamesArrStr += `'${name.trim()}',`
    })
    fknNamesArrStr += ']'
    let fknTimesArrStr = '['
    fknotificationConfig.times.split(',').map((time) => {
      fknTimesArrStr += `'${time.trim()}',`
    })
    fknTimesArrStr += ']'

    script += `mfNotifications(${fknNamesArrStr},${fknTimesArrStr},${fknotificationConfig.quantity},${fknotificationConfig.delay},'${fknotificationConfig.event}','${fknotificationConfig.theme}');`
  }

  // append close tag script
  script += '}</script>'

  return script
}

export function headerSystemMount(
  timeDelayStyle = '',
  backgroundBody = {},
  pixelLeadEventOnSubmitForm = {}
) {
  let script = ''

  // timeDelayStyle already includes <style> tag
  script += timeDelayStyle

  // mount background style
  let bodyStyleStr = `<style>body { `

  bodyStyleStr += backgroundBody.color
    ? `background-color: ${backgroundBody.color};`
    : ''
  bodyStyleStr +=
    backgroundBody.img && backgroundBody.img.src
      ? `background-image: url(${backgroundBody.img.src});`
      : ''
  bodyStyleStr +=
    backgroundBody.img && backgroundBody.img.position
      ? ` background-position: ${backgroundBody.img.position};`
      : ''
  bodyStyleStr +=
    backgroundBody.img && backgroundBody.img.repeat
      ? `background-repeat: ${backgroundBody.img.repeat};`
      : ''
  bodyStyleStr +=
    backgroundBody.img && backgroundBody.img.size
      ? `background-size: ${backgroundBody.img.size};`
      : ''

  bodyStyleStr += `}</style>`

  script += bodyStyleStr

  // mount meta pixel lead event on submit form
  if (pixelLeadEventOnSubmitForm.enabled) {
    script += pixelLeadEventOnSubmitForm.value
  }

  return script
}

/**
 * Remove tag script e evento window.onload do footer_system e
 * transforma as chamadas de função timeDelay da string em array.
 * @param {String} script | script string of footer_sytem
 */
export function footerSystemTimeDelayUnmount(script) {
  const timeDelayArr = {}
  let occurrencesArr

  // script = script.slice(36, -10); //remove o "<script>window.onload = function() {" e "}</script>"

  const regex = new RegExp(`timeDelay[(]"(.+?)",(.+?)[)];`, 'g')
  occurrencesArr = script.match(regex)

  if (occurrencesArr) {
    occurrencesArr.forEach((element) => {
      const td = element.slice(10, -2).split(',') // remove o "timeDelay(" do começo da string e ");" do final, em seguida splita na ,
      timeDelayArr[td[0].slice(1, -1)] = td[1] // retira as aspas da id do componente e atribui o valor do tempo à posição do array
    })
  }

  return timeDelayArr // antes de retornar, remove os timeDelay's do script, eles já estão no timeDelayArr
}

export function footerSystemAnimateUnmount(script) {
  const tempAnimateArr = []

  const regex = new RegExp(`animateWA[(]"(.+?)"[)];`, 'g')
  const occurrencesArr = script.match(regex)
  if (occurrencesArr) {
    occurrencesArr.forEach((element) => {
      tempAnimateArr.push(element.slice(11, -3)) // remove o 'animateWA("' do começo da string e '");' do final, em seguida adiciona no array
    })
  }
  return tempAnimateArr
}

/**
 * Inject template inside node DOM
 * @param {String} template | template string to be render
 * @param {Node} node | node DOM where to be insert template
 */
export const Render = (template, node) => {
  if (!node) return
  node.html(template)
}

/**
 * Inject template inside node DOM
 * @param {Array} arrIntegrations | list of integrations
 */
export const hasIntegrations = (self, dataSend) => {
  const { integrations } = self.options
  const formsInPage = self.iframeBody.find('form')

  if (!integrations) return false

  integrations.dataId = [POPUP_ID]

  if (formsInPage.length) {
    for (let index = 0; index < formsInPage.length; index += 1) {
      const elementId = $(formsInPage[index])
        .closest('.keditor-component')
        .attr('id')
      if (!integrations.dataId.includes(elementId)) {
        integrations.dataId.push(elementId)
      }
    }
  }

  // eslint-disable-next-line no-param-reassign
  dataSend.integrations = JSON.stringify(self.options.integrations)
}

/**
 * Remove all undefined integration reference of page HTML
 * @param {String} html | Reference to html of body
 */
export const removeIntegrationUndefined = (html) => {
  const page = $(html)
  let response = ''

  page.find(`.integration-undefined`).each(function (index) {
    $(this).remove()
  })

  page.each(function () {
    response += this.outerHTML
  })

  return response
}

/**
 *
 * @param {DOM} contentArea | Reference to contentAreasWrapper
 */
export const removeShowedElements = (contentArea) => {
  const selected = contentArea.find(`.${STATE_TOOLBAR_SHOWED}`)

  selected.each(function () {
    $(this).removeClass(STATE_TOOLBAR_SHOWED)
  })
}

export const clearBtnFocus = (contentArea) => {
  const focused = contentArea.find(`.${IN_FOCUS}`)

  focused.each(function () {
    $(this).removeClass(IN_FOCUS)
  })
}
