export const mountResourceList = (resource) => {
  const move = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0])
  }
  const recursiveFunction = (data, index) => {
    let arr = [...data]

    if (arr[index].requires.length > 0) {
      // arr.push(arr[index].requires)
      const aux = [...arr[index].requires]
      arr[index].requires = arr[index].requires.map((el) => el.name)
      arr = [...arr, ...aux]
    }
    if (arr.length - 1 === index) return arr

    return recursiveFunction(arr, index + 1)
  }
  const resourcesParsed = recursiveFunction(resource, 0)
  const orderResources = [...resourcesParsed]

  // reorder list send resource if dependeces to end of list
  resourcesParsed.forEach((element, index) => {
    if (element.requires.length)
      return move(orderResources, index, orderResources.length - 1)
  })
  return orderResources
}
