import React from 'react';
import { EDITOR_MODAL_ID } from '../../shared/constants';

export const Button = ({label}) => (
    <>
      <div className="dropdown-divider"></div>
      <a
        className="dropdown-item d-flex justify-content-between align-items-center my-1"
        href="#"
        data-toggle="modal"
        data-target={`#${EDITOR_MODAL_ID}`}
        data-type="integration"
      >
        {typeof label === 'string' && label.toUpperCase()}
        <i className="fa fa-exclamation ml-1"></i>
      </a>
    </>
)