import React from 'react'
import { useAsync } from '../../utils'
import { getAction } from '../../../services/integrations'
import { mountResourceList } from '../utils'

export const useGetActionWithResource = (service, action) => {
  const { status, error, run } = useAsync()
  const [resourceList, setResourceList] = React.useState([])

  React.useEffect(() => {
    if (service && action) {
      run(
        getAction(service, action).then((response) => {
          const actionWithResource = response.data.data
          if (
            !actionWithResource.length &&
            !actionWithResource[0].resources.length
          )
            return

          return setResourceList(
            mountResourceList(actionWithResource[0].resources)
          )
        })
      )
    } else {
      setResourceList([])
    }
  }, [service, action])

  return { resourceList, status, error, setResourceList }
}

export const useOutsideClick = (ref, callback) => {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}
