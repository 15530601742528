import { FORM_BG_COLOR } from './color.js'
import { FORM_BG_IMAGE } from './image.js'

const TEMPLATE_BG_COLOR = /*html*/ `
<h6 class="mt-2 mb-3">
  Configuração de Fundo da página (body)
</h6>
${FORM_BG_COLOR}
`
const TEMPLATE_BG_IMAGE = /*html*/ `
<h6 class="mt-2 mb-3">
  Configuração de Fundo da página (body)
</h6>
${FORM_BG_IMAGE}
`

export { TEMPLATE_BG_COLOR, TEMPLATE_BG_IMAGE }
