const COLOR_PICKER = ".color-picker-component";
const COLOR_PICKER_ID = "#color-picker";
const ON_SELECTOR = "change.spectrum";

export default class ColorPicker {
  // Methods
  init(form, call, id = COLOR_PICKER_ID) {
    const el = form.find(`[data-id="${id}"]`);
    return el.on(ON_SELECTOR, function (e) {
      call(e.target.value);
    });
  }

  show(form, val, id = COLOR_PICKER_ID) {
    const el = form.find(`[data-id="${id}"]`);
    return el.spectrum("set", val);
  }
}
