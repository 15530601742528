import React, {forwardRef} from 'react';
import CSS_CLASS from '../../../../keditor/constants/cssClass';
import { usePopup } from '../../context/popupContext';
import { EDITOR_MODAL_ID } from '../../shared/constants';
import './styles.css';



export const Modal = forwardRef(({ children, handleConfirmation }, ref) => {
  const { state: {modalPopup} } = usePopup()
  // const modalRef = React.useRef(null)

  return (
    <React.Fragment>
      <div
        className={`${CSS_CLASS.MODAL} fade`}
        id={EDITOR_MODAL_ID}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modal"
        aria-hidden="true"
        ref={ref}
      >
        <div className={`${CSS_CLASS.MODAL_DIALOG} overwriteMaxWidthModal`}
        >
          <div className="modal-content content-popup overwrite" id="modal-root" >
            <div className="modal-header overwrite">
              <h5 className="modal-title">
                {modalPopup.title}
                <i className="fa fa-exclamation ml-2"></i>
              </h5>
              <button type="button" className="close cols-m-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body overwrite">
                {children}
            </div>
            <div className="modal-footer overwrite">
              <div className="buttons">
                <button type="button" className="btn cancel" data-dismiss="modal">
                  {modalPopup.close}
                </button>
                <button type="button" className="btn save" id="save-button" onClick={handleConfirmation}>
                  {modalPopup.confirmation}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})