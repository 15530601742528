import React from 'react'
import ReactDOM from 'react-dom'
import { Integrations } from './Integrations'
import { Button } from './components/Button/Button'
import { IntegrationProvider } from './context/integrationContext'

export const IntegrationsContainer = (self, buttonRef) => {
  const integrationRef = 'integrations-board'
  const { integrations } = self.options
  self.wrapper.append(`<div id="${integrationRef}"></div>`)

  const buttonContainer = document.getElementById(buttonRef)
  const container = document.getElementById(integrationRef)

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<Button />, buttonContainer)
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <IntegrationProvider keditor={self} integrations={integrations}>
      <Integrations />
    </IntegrationProvider>,
    container
  )
}
