/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import React, { useState } from 'react'
import { INTEGRATIONS_PANEL_VIEW } from '../Integrations/constants'
import { useIntegration } from '../Integrations/context/integrationContext'
import { removeIntegrationUndefined } from '../utils'
import './styles.css'
import { integrationParse } from './utils'

const STEPS = {
  DEFINE_METHOD: 1,
  FILL_FORM: 2,
}

const INITIAL_FORM_STATE = {
  type: 'webhook',
  service: 'webhook',
  action: 'send_webhook',
  resources: {
    destination_url: {
      value: '',
    },
    method: {
      value: 'POST', //  POST || GET
    },
    forward_query_string_args: {
      value: true, // constant
    },
    name: {
      argName: 'name', // override
    },
    email: {
      argName: 'email', // override
    },
    phone: {
      argName: 'phone', // default value: phone / name / email
    },
  },
}

const Wrapper = ({
  children,
  handleCancel,
  succesButtonText = 'Continuar',
  handleContinue,
  withModalBodyWithScroll,
}) => (
  <>
    <div
      className={`modal-body modal-body-fixed-height ${withModalBodyWithScroll ? 'modalBodyWithScroll' : ''}`}
    >
      <div className="container">
        Webhook
        <div>{children}</div>
      </div>
    </div>
    <div className="modal-footer">
      <div className="buttons">
        <button type="button" className="btn cancel" onClick={handleCancel}>
          Cancelar
        </button>
        <button
          type="button"
          className="btn save"
          id="save-button"
          onClick={handleContinue}
        >
          {succesButtonText}
        </button>
      </div>
    </div>
  </>
)

const InlineForm = ({ children, isCustomField }) => (
  <div
    className="mb-3 w-50"
    style={{
      display: 'grid',
      gridTemplateColumns: isCustomField ? '3fr 1fr 3fr' : '1fr 1fr 3fr',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
)

export const Webhook = ({ integrationPosition }) => {
  const {
    setPanelView,
    addIntegration,
    editIntegration,
    keditor,
    integrations,
  } = useIntegration()
  const isEditMode =
    integrationPosition !== undefined && integrationPosition !== null
  const integrationToEdit = integrationParse(
    integrations?.data[integrationPosition]
  )

  const [[error, errorType], setFormError] = useState([false, ''])
  const [step, setStep] = useState(STEPS.DEFINE_METHOD)
  const [form, setForm] = useState(
    isEditMode ? integrationToEdit : INITIAL_FORM_STATE
  )
  const {
    destination_url,
    method,
    forward_query_string_args,
    name,
    email,
    phone,
    ...rest
  } = form.resources
  const [customResources, setCustomResources] = useState(() => {
    const res = Object.keys(rest).map((key) => ({
      name: key,
      ...rest[key],
    }))
    return res
  })

  const validationForm = (payload) =>
    Object.values(payload).every((element) => {
      const val = element?.value || element?.argName
      return !!val
    })

  const validateURL = (value) => {
    // Simple regex for URL validation
    const regex = /^(https?:\/\/)?([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+.*)$/i
    return regex.test(value)
  }

  const handleCancel = () => {
    setPanelView(INTEGRATIONS_PANEL_VIEW.LIST)
  }

  const handleChange = (e, keyValue = 'value') => {
    const { name, value } = e.target
    setForm((prevState) => ({
      ...prevState,
      resources: {
        ...prevState.resources,
        [name]: { ...prevState[name], [keyValue]: value },
      },
    }))
    setFormError([false, ''])
  }

  const handleChangeCustomField = (e, index) => {
    const { value, name } = e.target
    const newCustomResources = [...customResources]
    newCustomResources[index] = {
      ...newCustomResources[index],
      [name]: value,
    }

    setCustomResources(newCustomResources)
  }

  const addCustomField = () => {
    setCustomResources((prevState) => [
      ...prevState,
      {
        name: 'personalizado',
        value: '',
      },
    ])
  }

  const removeCustomField = (index) => {
    const newCustomResources = [...customResources]
    newCustomResources.splice(index, 1)

    setCustomResources(newCustomResources)
  }

  const handleSubmit = () => {
    const resources = {
      destination_url,
      method,
      forward_query_string_args,
      name,
      email,
      phone,
    }
    const normalizeResources = Object.keys(resources).map((key) => ({
      name: key,
      ...form.resources[key],
    }))
    const normalizedForm = {
      ...form,
      resources: [...normalizeResources, ...customResources],
    }
    const action = isEditMode ? editIntegration : addIntegration

    action({
      integrationPosition,
      integration: normalizedForm,
    })
    setPanelView(INTEGRATIONS_PANEL_VIEW.LIST)
    removeIntegrationUndefined(keditor)
  }

  switch (step) {
    case STEPS.DEFINE_METHOD:
      return (
        <Wrapper
          handleCancel={handleCancel}
          handleContinue={() => {
            const hasValues = validationForm({
              destination_url,
              method,
            })
            const isUrlValid = hasValues && validateURL(destination_url.value)

            if (hasValues && isUrlValid) {
              setFormError([false, ''])
              setStep(STEPS.FILL_FORM)
            } else if (!hasValues) {
              setFormError([true, 'empty'])
            } else {
              setFormError([true, 'invalidUrl'])
            }
          }}
        >
          <div className="form-group">
            <label htmlFor="integrationURL">Url da integração</label>
            <input
              type="text"
              className={`form-control ${error ? 'is-invalid' : ''}`}
              aria-describedby="integrationURL"
              name="destination_url"
              value={destination_url.value}
              onChange={handleChange}
            />
            {error && errorType === 'empty' && !destination_url.value && (
              <div className="invalid-feedback">Campo não preenchido</div>
            )}
            {error && errorType === 'invalidUrl' && (
              <div className="invalid-feedback">URL invalida</div>
            )}
          </div>
          <div className="form-group">
            <label
              htmlFor="integrationURL"
              className="col-form-label"
              style={{ fontWeight: 'bold' }}
            >
              Método de Envio:
            </label>
            <div className="form-check mb-3">
              <input
                className="correct-answer-radio mr-2"
                type="radio"
                name="method"
                value="POST"
                checked={method.value === 'POST'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="exampleRadios1">
                POST
              </label>
            </div>
            <div className="form-check">
              <input
                className="correct-answer-radio mr-2"
                type="radio"
                name="method"
                value="GET"
                checked={method.value === 'GET'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="exampleRadios2">
                GET
              </label>
            </div>
          </div>
        </Wrapper>
      )
    case STEPS.FILL_FORM:
      return (
        <Wrapper
          handleCancel={handleCancel}
          handleContinue={handleSubmit}
          succesButtonText="Salvar"
          withModalBodyWithScroll
        >
          <h5 className="mb-3 mt-2">Editar nome dos campos do formulário:</h5>
          <InlineForm>
            <label htmlFor="integrationURL" className="mb-0">
              Nome{' '}
            </label>
            <i
              className="fa fa-arrow-right mx-5"
              style={{ justifySelf: 'center' }}
              aria-hidden="true"
            ></i>
            <input
              name="name"
              type="text"
              className="form-control flex-grow-1"
              data-lpignore="true"
              value={name.argName}
              onChange={(event) => handleChange(event, 'argName')}
            />
          </InlineForm>
          <InlineForm>
            <label htmlFor="integrationURL" className="mb-0">
              Email{' '}
            </label>
            <i
              className="fa fa-arrow-right mx-5"
              style={{ justifySelf: 'center' }}
              aria-hidden="true"
            ></i>
            <input
              name="email"
              type="text"
              className="form-control flex-grow-1"
              data-lpignore="true"
              value={email.argName}
              onChange={(event) => handleChange(event, 'argName')}
            />
          </InlineForm>
          <InlineForm>
            <label htmlFor="integrationURL" className="mb-0">
              Celular{' '}
            </label>
            <i
              className="fa fa-arrow-right mx-5"
              style={{ justifySelf: 'center' }}
              aria-hidden="true"
            ></i>
            <input
              name="phone"
              type="text"
              className="form-control flex-grow-1"
              data-lpignore="true"
              value={phone.argName}
              onChange={(event) => handleChange(event, 'argName')}
            />
          </InlineForm>
          <h5 className="mt-5 mb-3">Campos personalizados:</h5>
          <div className="mb-3">
            <button
              type="button"
              className="btn outline-button"
              onClick={addCustomField}
              style={{
                background: '#4A4A4A',
                border: '1px solid rgba(122, 122, 122, 0.6)',
                borderRadius: '6px',
                height: '40px',
              }}
            >
              Adicionar campo personalizado
            </button>
          </div>
          {!!customResources.length &&
            customResources.map((resource, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    gap: '1.5rem',
                  }}
                >
                  <InlineForm isCustomField>
                    <input
                      type="text"
                      name="name"
                      className="form-control flex-grow-1"
                      data-lpignore="true"
                      value={resource.name}
                      onChange={(event) =>
                        handleChangeCustomField(event, index)
                      }
                    />
                    <i
                      className="fa fa-arrow-right mx-5"
                      style={{ justifySelf: 'center' }}
                      aria-hidden="true"
                    ></i>
                    <input
                      type="text"
                      name="value"
                      className="form-control flex-grow-1"
                      data-lpignore="true"
                      value={resource.value}
                      onChange={(event) =>
                        handleChangeCustomField(event, index)
                      }
                    />
                  </InlineForm>
                  <div>
                    <button
                      type="button"
                      className="btn circle-outline-button"
                      onClick={() => removeCustomField(index)}
                    >
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              )
            })}
        </Wrapper>
      )
    default:
      return null
  }
}
