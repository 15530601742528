export default function (width, self, style) {
  if (!self) return
  function setFontSize(el, device) {
    const value = $(el).attr('data-' + style.replace(/-/g, '_') + `_${device}`)

    $(el).css(style, value ? value + 'px' : '')
  }

  if (width !== '100%') {
    const elMobile = self.iframeBody.find(`[data-${style.replace(/-/g, '_')}_mobile]`)
    elMobile.each(function () {
      setFontSize(this, 'mobile')
    })
  } else {
    const elDesktop = self.iframeBody.find(`[data-${style.replace(/-/g, '_')}_default]`)

    elDesktop.each(function () {
      setFontSize(this, 'default')
    })
  }
}
