import CSS_CLASS from '../../keditor/constants/cssClass'
import spacingEventListener from '../helpers/spacingEventListener'

const useSpacing = ({ form, keditor, customFormRef, isFirst, customComponentName, elementRef }) => {
  const formRef = customFormRef || `spacing-top`
  const maxLength = 200
  const componentName = customComponentName || 'spacing'
  const defaultSpacing = `${formRef}-default`
  const mobileSpacing = `${formRef}-mobile`
  const blockConfig = `${mobileSpacing}-block`
  const dataBlock = 'data-spacing-block'

  const size = (device) => `data-margin_${device}`
  const method = (type, target, callback) => form.on(type, target, callback)
  const element = (config) => form.find(`#${config}`)

  const init = () => {
    method('change', `#${mobileSpacing}`, function (e) {
      const { value } = e.target
      let newVal = value
      if (value > maxLength || value < -maxLength) {
        newVal = value > maxLength ? maxLength : -maxLength
        $(this).val(newVal)
      }
      elementRef().attr(size('mobile'), newVal)

      const currentWidth = keditor.options.currentDeviceWidth
      spacingEventListener(currentWidth, keditor)
    })

    method('change', `#${defaultSpacing}`, function (e) {
      const { value } = e.target

      let newVal = value
      if (value > maxLength || value < -maxLength) {
        newVal = value > maxLength ? maxLength : -maxLength
        $(this).val(newVal)
      }

      elementRef().attr(size('default'), newVal)

      if (elementRef().attr(dataBlock) == 'true') {
        element(mobileSpacing).val(newVal)
        elementRef().attr(size('mobile'), newVal)
      }

      const currentWidth = keditor.options.currentDeviceWidth
      spacingEventListener(currentWidth, keditor)
    })

    method('click', `#${blockConfig}`, function (e) {
      const icon = $(this).find('i')

      if (icon.hasClass('fa-lock')) {
        elementRef().attr(dataBlock, false)
        element(mobileSpacing).attr('disabled', false)
      } else {
        const value = element(defaultSpacing).val()
        element(mobileSpacing).attr('disabled', true)
        elementRef().attr(dataBlock, true)
        element(mobileSpacing).val(value)
        elementRef().attr(size('mobile'), value)
      }

      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')

      const currentWidth = keditor.options.currentDeviceWidth
      spacingEventListener(currentWidth, keditor)
    })
  }

  const show = () => {
    if (!elementRef().attr(size('default')) || !elementRef().attr(size('mobile'))) {
      const val = elementRef().parent().hasClass(`${CSS_CLASS.CONTAINER_INNER}`) ? 0 : 16
      elementRef().attr(size('default'), val)
      elementRef().attr(size('mobile'), val)
    }

    const isBlocked = elementRef().attr(dataBlock) == 'false'

    const dataMarginDefault = elementRef().attr(size('default')) || elementRef().css('marginTop').replace('px', '') || 0
    const dataMarginMobile = elementRef().attr(size('mobile')) || elementRef().css('marginTop').replace('px', '') || 0

    element(mobileSpacing).val(dataMarginMobile)
    element(defaultSpacing).val(dataMarginDefault)

    const icon = element(blockConfig).find('i')

    switch (!isBlocked) {
      case true:
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')
        element(mobileSpacing).attr('disabled', true)
        element(mobileSpacing).val(element(defaultSpacing).val())
        elementRef().attr(size('mobile'), element(defaultSpacing).val())
        elementRef().attr(dataBlock, true)
        break
      case false:
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')
        element(mobileSpacing).attr('disabled', false)
        elementRef().attr(dataBlock, false)
        break
    }
  }

  const templateNumber = /*html*/ `
  <div class="col-sm-6 ${!isFirst && 'mt-3'}">
    <label>Computador</label>
    <div class="row">
      <div class="col">
        <div class=" d-inline">
          <div class="">
            <div class="input-group input-group-sm">
              <input id="${defaultSpacing}" type="number" class="form-control" min="-${maxLength}" max="${maxLength}" data-device="default" />
              <div class="input-group-append">
                <span class="input-group-text">px</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   
  <div class="col-sm-6 ${!isFirst && 'mt-3'}">
    <div class="d-flex align-items-center">
      <span
        id="${blockConfig}"
        class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
      >
        <i class="fa fa-unlock" aria-hidden="true"></i>
      </span>
      <label>Celular</label>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-inline">
          <div class="">
            <div class="input-group input-group-sm">
              <input id="${mobileSpacing}" type="number" class="form-control" min="-${maxLength}" max="${maxLength}" data-device="default" />
              <div class="input-group-append">
                <span class="input-group-text">px</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   
  `

  const template = /*html*/ `
  <div class="d-flex flex-wrap">
    ${templateNumber}
  </div>
  `

  return {
    componentName,
    init,
    show,
    template,
  }
}

export default useSpacing
