import defaults from '../constants/defaults'
import { componentSave, containerSave, renderImagesArray, selectImage, showFileErrorAlert } from './gallery/methods'
import { TEMPLATE, MODAL_EXTERNAL_URL } from './gallery/template'
import { ModalBase } from './template/modalBase'

export default async function () {
  let selectedImage = ''
  const self = this

  await $.ajax({
    url: `${defaults.apiUrl}/media`,
    type: 'GET',
    dataType: 'json',
    headers: {
      authorization: self.options.accessToken,
    },
    contentType: 'application/json; charset=utf-8',
    success: function (result) {
      const { data } = result

      data.map((el) => {
        //self.options.gallery.push(`${defaults.apiUrl}/storage/${el.file_name}`)
        self.options.gallery.push(el.file_url)
      })
    },
  })

  const footer = /*html*/ `
  <div style="width: 100%">
    <div class="d-flex justify-content-center w-100">
      <div id="pagination-container"></div>
    </div>
    <div class="gallery-buttons" >
      <button
        type="button"
        id="btn-modal-url"
        class="btn outlined"
        data-toggle="modal"
        data-target="#modal-img-url"
      >
        Adicionar imagem via URL
      </button>
      <button type="button" class="btn save" data-dismiss="modal">
        Inserir imagem
      </button>
    </div>
  </div>
  `

  const modal = $(
    ModalBase({
      id: 'modal-image-gallery',
      title: this.options.locale.modalImage.title,
      body: TEMPLATE,
      footer: footer,
      size: 'large',
    })
  )

  let result = modal.appendTo(self.wrapper)

  const modalExternalUrl = $(MODAL_EXTERNAL_URL)
  modalExternalUrl.appendTo(self.wrapper)

  // REDERING
  modal.find('#pagination-container').pagination({
    dataSource: self.options.gallery,
    pageSize: 20,
    className: '',
    callback: function (data, pagination) {
      modal.find('.gallery').find('.danger').remove()
      modal.find('.save').attr('disabled', true)

      var html = renderImagesArray(data, self.options.apiUrl)

      modal.find('#data-container').html(html)
      modal.find('.image-item').removeClass('active')
      modal
        .find('.gallery')
        .find('.image-item')
        .on('click', function (e) {
          selectedImage = selectImage.call(this, modal)
        })
    },
  })

  modal.find('#drag-and-drop-zone').dmUploader({
    url: `${this.options.apiUrl}/media`,
    headers: {
      authorization: this.options.accessToken,
    },
    // maxFileSize: 2000000,
    extFilter: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'],
    onDragEnter: function () {
      this.addClass('active')
    },
    onDragLeave: function () {
      this.removeClass('active')
    },
    onInit: function () {},

    onComplete: function () {},

    onNewFile: function (id, file) {
      if (file.name.length > 150) {
        return showFileErrorAlert('Tamanho máximo do nome da imagem é de 150 caracteres!')
      }
      if (file.size > 2000000) {
        return showFileErrorAlert('Não foi possível realizar o upload. A imagem ultrapassa o limite máximo de 2mb.')
      }
      if (typeof FileReader !== 'undefined') {
        var reader = new FileReader()
        const image = /*html*/ `
        <div
          class="preview-img d-flex justify-content-center align-items-center"
          id="img-${id}"
          data-name="${file.name}"
        >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <img />
          </div>
          <div class="upload-status">
            <div class="upload-progress" id="progress-${id}"></div>
          </div>
        </div>`
        var img = $(image)

        reader.onload = function (e) {
          img.find('img').attr('src', e.target.result)
        }
        /* ToDo: do something with the img! */
        reader.readAsDataURL(file)
        if (modal.find('.gallery').find(`.image-item`).length) modal.find('.gallery').prepend(img)
        else modal.find('.gallery').html(img)
      }
    },

    onBeforeUpload: function (id) {
      ui_multi_update_file_status(id, 'uploading')
      ui_multi_update_file_progress({ id, active: true, type: 'before uploading' })
    },

    onUploadCanceled: function (id) {
      ui_multi_update_file_status(id, 'warning')
      ui_multi_update_file_progress({ id, active: false })
    },

    onUploadProgress: function (id, percent) {
      ui_multi_update_file_progress({ id, active: true, type: 'uploading' })
    },

    onUploadSuccess: function (id, data) {
      // const img = data.data
      const img = data

      const gallery = modal.find('.gallery')

      gallery.find(`#img-${id}`).removeClass('preview-img')
      gallery.find(`#img-${id}`).addClass('image-item')
      gallery.find(`#img-${id}`).find('.upload-status').remove()
      gallery
        .find(`#img-${id}`)
        .find('img')
        //.attr({ src: `${self.options.apiUrl}/storage/${img.file_name}` })
        .attr({ src: img.file_url })

      gallery.find(`#img-${id}`).attr('data-name', img.file_name.split('media/u1/').join(''))

      // self.options.gallery.unshift(`${self.options.apiUrl}/storage/${img.file_name}`)
      self.options.gallery.unshift(img.file_url)

      gallery.find('.image-item').off('click')
      gallery.find('.image-item').on('click', function (e) {
        selectedImage = selectImage.call(this, modal)
      })
      ui_multi_update_file_progress({ id, active: false, type: 'upload success' })
    },

    onUploadError: function (id, xhr, status, message) {
      console.log('error')
      ui_multi_update_file_status(id, 'danger')
      ui_multi_update_file_progress({ id, active: false, type: 'error' })
    },

    onFallbackMode: function () {},

    onFileSizeError: function (file) {
      return showFileErrorAlert('Não foi possível realizar o upload. A imagem ultrapassa o limite máximo de 2mb.')
    },

    onFileTypeError: function (file) {
      return showFileErrorAlert('Extensão do arquivo não permitido.')
    },
  })

  // METHODS
  modal.on('shown.bs.modal', function () {
    modal.find('#data-container').find('.image-item.active').removeClass('active')
    modal.find('.save').attr('disabled', true)
  })

  modal.find('.save').on('click', function (e) {
    const src = modal.find('#data-container').find('.image-item.active').find('img').attr('src')
    const { isContainer, isIframe, target, form, isGeneric, callback } = self.options.locale.modalImage

    if (!src) return

    if (isContainer) {
      containerSave(target, form, src)
    } else if (isIframe) {
      const constant = self.options.globalStyle.background
      constant.img.src = src
      form.find('.change-image-button').css({ backgroundImage: `url(${src})` })

      form.find('.upload-button').hide()
      form.find('.change-image-button').show()
    } else if (isGeneric) {
      form.find('.change-image-button').css({ backgroundImage: `url(${src})` })
      form.find('.upload-button').hide()
      form.find('.change-image-button').show()
      if (typeof callback === 'function') { 
        callback(target, form, src) 
      }
    }  else {
      componentSave(target, form, src, self)
    }

    $('.unsaved').show()
  })

  // METHODS FOR EXTERNAL URL
  modalExternalUrl.on('shown.bs.modal', function () {
    const { isContainer, isIframe, target } = self.options.locale.modalImage
    console.log(target)
    let url = ''

    if (isContainer) {
      const data = JSON.parse(target.attr('data-background'))
      url = data.img.src
    } else if (isIframe) {
      const constant = self.options.globalStyle.background
      url = constant.img.src
    } else {
      url = target.find('.img-product').attr('src') || ''
    }

    modalExternalUrl.find('#image-url').val(url)
  })

  let externalUrl = ''
  modalExternalUrl.find('#image-url').on('change', function (event) {
    externalUrl = event.target.value
  })

  modalExternalUrl.find('#btn-add-url').on('click', function () {
    $('#image-url').val('')
    const { isContainer, isIframe, target, form, isGeneric, callback } = self.options.locale.modalImage
    if (isContainer) {
      containerSave(target, form, externalUrl)
    } else if (isIframe) {
      const constant = self.options.globalStyle.background
      constant.img.src = externalUrl
      form.find('.change-image-button').css({ backgroundImage: `url(${externalUrl})` })

      form.find('.upload-button').hide()
      form.find('.change-image-button').show()
    } else if (isGeneric) {
      form.find('.change-image-button').css({ backgroundImage: `url(${src})` })
      form.find('.upload-button').hide()
      form.find('.change-image-button').show()
      if (typeof callback === 'function') { callback() }
    } else {
      componentSave(target, form, externalUrl, self)
    }

    $(this).attr('data-dismiss', 'modal')

    $(`#modal-img-url`).modal('hide')
    $('.modal-backdrop').modal('hide')
    $('.unsaved').show()

    modal.modal('hide')
    $('.modal-backdrop').modal('hide')
    $('.unsaved').show()
  })

  return result
}
