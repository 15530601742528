import initPreviewAction from "./initPreviewAction";
import initFullscreenAction from "./initFullscreenAction";
import initSaveAction from "./initSaveAction";
import initSettingsPage from "./initSettingsPage";
import initPageVisit from "./initPageVisit";

export default function () {
  let self = this;
  let options = self.options;

  initSettingsPage.apply(self);
  initPreviewAction.apply(self);
  initPageVisit.apply(self);
  // initFullscreenAction.apply(self);
  typeof options.onSave === "function" && initSaveAction.apply(self);

  $(".unsaved").hide();
}
